import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { Box } from '@hausgold/designsystem';
import Navigation from './Navigation';

const Header = ({
  template,
  heroImage,
  title,
  subTitle,
  size,
  className,
  hideGradientOverlay,
  children,
  breadcrumb,
  headerStyles = { marginTop: 7 },
  breadcrumbStyles,
}) => {
  const heroSrc = getSrc(heroImage);
  const navi = (
    <StaticQuery
      query={graphql`
        query NavigationQuery {
          ratgeber: contentfulNavigation(slug: { eq: "ratgeber" }) {
            entries {
              ... on ContentfulCategory {
                internal {
                  type
                }
                id
                title
                slug
              }
            }
          }
          navigation: contentfulNavigation(slug: { eq: "hauptnavigation" }) {
            entries {
              ... on ContentfulCategory {
                internal {
                  type
                }
                id
                title
                slug
              }
              ... on ContentfulNavigation {
                internal {
                  type
                }
                id
                title
                slug
                entries {
                  ... on ContentfulCategory {
                    internal {
                      type
                    }
                    id
                    title
                    slug
                  }
                  ... on ContentfulPage {
                    internal {
                      type
                    }
                    id
                    slug
                    title
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              ... on ContentfulPage {
                internal {
                  type
                }
                id
                title
                slug
              }
            }
          }
          maklerPages: allContentfulPage(
            filter: { template: { eq: "Makler" } }
          ) {
            edges {
              node {
                id
                title
                slug
              }
            }
          }
        }
      `}
      render={(data) => (
        <Navigation
          template={template}
          entries={data}
          className={!heroSrc && 'scrolled'}
        />
      )}
    />
  );

  const background =
    hideGradientOverlay && heroSrc
      ? `url(${heroSrc})`
      : `linear-gradient( rgba(22,148,244,.5), rgba(22,148,244,.5) ), url(${heroSrc})`;

  return heroSrc ? (
    <header>
      <div
        style={{
          backgroundImage: background,
        }}
        className={`header text-center d-flex justify-content-center align-items-center header-${
          size || 'md'
        } ${className}`}
      >
        {navi}
        {size !== 'lg' && (
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs="12" lg="8">
                {title && (
                  <h1 className="display-2 text-break text-center text-white text-shadow">
                    {title}
                    <br />
                    <small className="font-weight-semibold d-block mt-4">
                      {subTitle}
                    </small>
                  </h1>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
      {size === 'lg' && (
        <Container className="wizardheader-margin">
          <Row>
            <Col className="text-center">
              {title && (
                <h1
                  className="display-2 text-break text-center text-white text-shadow mb-6 mx-auto"
                  style={{ maxWidth: '728px' }}
                >
                  {title}
                  <br />
                  <small className="font-weight-semibold d-block mt-4">
                    {subTitle}
                  </small>
                </h1>
              )}
            </Col>
          </Row>
          {children}
        </Container>
      )}
    </header>
  ) : (
    <header className={`header container mt-${headerStyles.marginTop}`}>
      {navi}
      {breadcrumb ? (
        <Box my={{ base: 0, md: 4 }} {...breadcrumbStyles}>
          {breadcrumb}
        </Box>
      ) : null}
      {title && (
        <h1 className="display-2">
          {title}
          <br />
          <small className="font-weight-semibold d-block mt-4">
            {subTitle}
          </small>
        </h1>
      )}
    </header>
  );
};

export default Header;
