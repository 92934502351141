import React, { Fragment } from 'react';
import { Box } from '@hausgold/designsystem';
import CookieConsent from './CookieConsent';
// eslint-disable-next-line no-unused-vars
import base from '../scss/app.scss';
import Footer from './Footer';

const Template = ({
  children,
  header,
  footer,
  cookieConsent,
  showTrustpilotInFooter = true,
  contentStyles,
}) => (
  <>
    {header}
    <Box mb={10} bg="white" {...contentStyles}>
      {children}
    </Box>
    {footer || <Footer showTrustpilot={showTrustpilotInFooter} />}
    {cookieConsent && <CookieConsent />}
  </>
);

export default Template;
