import {
  Accordion,
  AccordionArrow,
  AccordionButton,
  AccordionHeading,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@hausgold/designsystem';
import get from 'lodash/get';
import React from 'react';

/**
 *
 * Accordion that is displayed as a FAQ List.
 *
 * @param entry  {Objext}
 * @returns {JSX.Element}
 */
const ContentfulFaqList = ({ entry }) => {
  if (!entry.faqItems) {
    return null;
  }

  return (
    <div>
      {entry.faqItems.map((item) => (
        <Accordion colorScheme="blue" mb={2} key={item.id}>
          <AccordionItem bg="whiteAlpha.100">
            <AccordionButton
              bg="whiteAlpha.100"
              border="0 none"
              outline="none"
              p="6"
            >
              <AccordionHeading hyphens="auto" p="0" mb="0" mr="6">
                {item.question}
              </AccordionHeading>
              <AccordionArrow />
            </AccordionButton>

            <AccordionPanel>
              <Text
                fontSize="lg"
                color="gray.400"
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: get(item, 'answer.childMarkdownRemark.html'),
                }}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};

export default ContentfulFaqList;
