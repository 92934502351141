import Appsignal from '@appsignal/javascript';

/*
 * Make sure AppSignal is deactivated locally (and server-side), even when using
 * different app or API configurations. If not, use the usual config.
 */
const isBrowser = typeof window !== 'undefined';
const isLocal =
  global?.location?.href?.includes('.local') ||
  global?.location?.href?.includes('localhost');
const IS_ACTIVE = isBrowser && !isLocal;

const appsignal = IS_ACTIVE
  ? new Appsignal({
      key: process.env.GATSBY_APPSIGNAL_API_KEY,
    })
  : null;

/**
 * Returns an Appsignal instance for hausgold.de. If Appsignal is not active
 * (i.e. by being in development mode), this will return null.
 *
 * @return {Appsignal, null}
 */
const useAppsignal = () => appsignal;

export default useAppsignal;
