import React from 'react';
import { Row, Col } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';

const ContentfulTextWithImage = ({ entry }) => (
  <div
    className={cn('', {
      'bg-primary text-white': entry.template === 'on Background',
    })}
  >
    <Row>
      <Col
        xs={12}
        md={6}
        className={cn({ 'order-md-2': entry.imageAlignment === 'left' })}
      >
        <div
          className={cn({
            'px-5 pl-md-0 pr-md-6':
              entry.template === 'on Background' &&
              entry.imageAlignment === 'left',
            'px-5 pl-md-6 pr-md-0':
              entry.template === 'on Background' &&
              entry.imageAlignment === 'right',
          })}
        >
          {entry.title && <h2>{entry.title}</h2>}
          {entry.body && (
            <div
              className={cn('body', {
                'pb-5': entry.template === 'on Background',
              })}
              dangerouslySetInnerHTML={{
                __html: entry.body.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </Col>

      <Col xs={12} md={6}>
        {entry.image && (
          <GatsbyImage
            style={{ height: '100%' }}
            image={getImage(entry.image)}
            alt={entry.image.title}
          />
        )}
      </Col>
    </Row>
  </div>
);

export default ContentfulTextWithImage;
