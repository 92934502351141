import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Button } from '@hausgold/designsystem';
import { openConsentManager } from '@segment/consent-manager';
import Logo from './Logo';

/*
 * FooterSimple is used as a small version of the main Footer with a little curved line – only for landingpages.
 */
const FooterSimple = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="text-light bg-dark text-center text-md-left pb-3">
      <div className="swoosh swoosh-white d-flex align-items-end">
        <Container className="d-none d-md-block mb-5">
          <Row className="mb-6 mb-md-0 mb-lg-5 d-flex justify-content-center justify-content-md-start">
            <Col md="12">
              <Logo width="150" className="logo" />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="d-flex d-md-none my-6 justify-content-center">
          <Col>
            <Logo width="150" className="logo" />
          </Col>
        </Row>

        <Row className="mb-6">
          <Col xs={{ size: '12', order: '2' }} md={{ size: '6', order: '1' }}>
            <strong className="text-muted">Kostenlose Telefonberatung</strong>
            <p className="display-4 mt-5 mb-6 mb-md-0">
              <a className="text-white" href="tel:+494022616140">
                040 - 22 61 61 40
              </a>
            </p>
          </Col>
        </Row>

        <div className="d-block mb-6">
          <Button size="sm" variant="unstyled" onClick={openConsentManager}>
            Cookie Einstellungen
          </Button>
        </div>

        <small className="text-muted" id="press-release-dates">
          <sup>1</sup>Die Welt: 05.2015, Klassik Radio: 09.2021, FAZ: 05.2020,
          ZDF: 02.2020
        </small>
        <hr />
        <div className="text-muted">
          Copyright {year} - HAUSGOLD | talocasa GmbH | An der Alster 45 | 20099
          Hamburg
          <div className="float-lg-right">
            <a className="text-muted" href="https://www.hausgold.de/impressum/">
              Impressum
            </a>{' '}
            |
            <a
              className="text-muted"
              href="https://www.hausgold.de/datenschutz/"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default FooterSimple;
