/* eslint-disable react/no-array-index-key */

import React, { useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet/es/Helmet';
import { getScriptParts } from '../utilities';

/**
 * Renders a text as it is directly into html.
 * This way formatting can be handled by its source - Contentful.
 * It also provides handling of script tags as scripts are not executed by react itself for security reasons.
 * @param entry
 */
const ContentfulText = ({ entry }) => {
  if (!entry.text) {
    return null;
  }

  /*
   * Checks if the text contain a script or link tag which needs to be handled in a
   * different way than just passing it into the 'dangerouslySetInnerHTML' to ensure it will be executed.
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasScriptTag = useMemo(
    () =>
      entry.text.childMarkdownRemark.html.includes('<script') ||
      entry.text.childMarkdownRemark.html.includes('<link'),
    [entry.text.childMarkdownRemark.html]
  );

  /*
   * Save the loading state of the scripts which needs to be loaded before the content is rendered.
   * If no script is given it is directly true.
   * If multiple scripts are given this flag will change to true as soon as
   * the very first script is loaded, no matter if more scripts needs to load as well.
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [preScriptsLoaded, setPreScriptsLoaded] = useState(
    typeof window !== 'undefined' && !hasScriptTag
  );

  /*
   * The parsed content.
   * As the content is set within an `useEffect()` it also ensures that the code is running on client-side.
   * Otherwise we would run into rehydration issues.
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [htmlPartsClient, setHtmlPartsClient] = useState({
    preContentScripts: [],
    postContentScripts: [],
    textWithoutScripts: null,
  });

  /*
   * If pre scripts are available which contains the 'src' attribute we need to
   * keep track that every one of them is loaded.
   * This will count the amount of scripts which are not loaded yet before the content can be shown.
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [unloadedScripts, setUnloadedScripts] = useState(0);

  /*
   * Sum up all script loading states to a finale one which indicates if it is safe to load the rest of the content.
   */
  const preScriptsReady = preScriptsLoaded && unloadedScripts === 0;

  /*
   * Destruct the given html into plain content and scripts which needs to be pre-loaded and post-loaded.
   * Note: SSR hates this trick, so we need to make sure that the DOMParser is only executed on client-side
   *   by putting this into useEffect().
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (hasScriptTag) {
      /*
       * Parse raw html and split it into
       * - tags which needs to be loaded first (because html rely on it like js code etc.)
       * - html which needs to be rendered normally
       * - tags which needs to be loaded after (because they rely on html content)
       * Note: DOMParser() automatically sets scripts which are before e.g. div content into the head
       *   and scripts which comes after into the body.
       */
      const parsedHtml = new DOMParser().parseFromString(
        entry.text.childMarkdownRemark.html,
        'text/html'
      );
      const scriptsInHead = parsedHtml.head.querySelectorAll('script,link');
      const scriptsInBody = parsedHtml.body.querySelectorAll('script, link');
      /*
       * Remove the found nodes (scripts) from parsed html content.
       */
      parsedHtml.head
        .querySelectorAll('script, link')
        .forEach((el) => el.remove());
      parsedHtml.body
        .querySelectorAll('script, link')
        .forEach((el) => el.remove());

      /*
       * The XMLSerializer will transform DOMParser objects back into plain html.
       */
      const serializer = new XMLSerializer();

      /*
       * Format parsed html back into plain html and remove outer html content which is added by 'serializeToString'
       * to make it a valid standalone html page.
       * Note: We transform the whole parsed html content to ensure other parts from <head> don't get lost (like <styles>).
       *   Transforming the <body> part only would save the additional replacement logic but also drops the remaining <head> content.
       */
      const remainingHtml = serializer
        .serializeToString(parsedHtml)
        .replace('<html xmlns="http://www.w3.org/1999/xhtml">', '')
        .replace('</html>', '')
        .replace('<head>', '')
        .replace('</head>', '')
        .replace('<body>', '')
        .replace('</body>', '');

      /*
       * Serialize the head (pre-content) scripts.
       * We assume that they only contain attributes like 'src' and no content (innerHtml) as this
       * probably would break. See next comment for more details.
       */
      const preContentScripts = Array.from(scriptsInHead).map((script) =>
        getScriptParts(serializer.serializeToString(script))
      );
      /*
       * Serialize the body (post-content) scripts.
       * We use '.innerText' instead of '.serializeToString' as last one transform
       * all special chars like `&` to its unicode characters which breaks the js code...
       * This way the attributes like 'src' gets lost but those scripts should be
       * part of the header anyway.
       */
      const postContentScripts = Array.from(scriptsInBody).map((script) =>
        getScriptParts(script.innerText)
      );

      /*
       * Get list of preScript `src`/`href` data and check
       * if they already exist in the list of scripts in the DOM.
       */
      const preContentScriptSources = preContentScripts
        .map((element) => element.src || element.href)
        .filter((element) => element);
      const alreadyExistingPreScripts = Array.from(
        document.querySelectorAll('script'),
        (element) => element.getAttribute('src') || element.getAttribute('href')
      ).filter(
        (element) => element && preContentScriptSources.includes(element)
      );

      /*
       * If scripts are extracted and no preContentScripts exists or some of them already are present
       * the loading flag for those can change to done immediately.
       */
      if (!preContentScripts.length || alreadyExistingPreScripts.length) {
        setPreScriptsLoaded(true);
      }

      /*
       * If there are pre-scripts, we need to know how many have to really be loaded first
       * (contain a 'src' or 'href' instead of direct code and are not already present).
       */
      if (preContentScripts.length) {
        setUnloadedScripts(
          preContentScripts.filter(({ src, href }) => src || href).length -
            alreadyExistingPreScripts.length
        );
      }

      setHtmlPartsClient({
        preContentScripts,
        postContentScripts,
        textWithoutScripts: remainingHtml,
      });
    } else {
      setHtmlPartsClient({
        preContentScripts: [],
        postContentScripts: [],
        textWithoutScripts: entry.text.childMarkdownRemark.html,
      });
    }
  }, [entry.text.childMarkdownRemark.html]);

  /*
   * Killswitch to enforce remaining content will be rendered after some time is over
   * as fucking script events sometimes do not recognize that they are ready or already
   * started as ready but don't tell anybody!!!
   * Should not be relevant in 99,9% of all cases.
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setTimeout(() => {
      if (!preScriptsLoaded) setPreScriptsLoaded(true);
      if (unloadedScripts) setUnloadedScripts(0);
    }, 4000);
  }, []);

  const { preContentScripts, postContentScripts, textWithoutScripts } =
    htmlPartsClient;

  /*
   * Listen to script is loaded event, so we can block the usage of the script until it is present.
   * See https://github.com/nfl/react-helmet/issues/146#issuecomment-513793628
   * Note: The original was extended to also support `<link>` as well as multiple scripts.
   */
  const handleChangeClientState = (newState, addedTags) => {
    /*
     * Only do something if new tag was added.
     */
    if (addedTags?.scriptTags || addedTags?.linkTags) {
      /*
       * Extract all src/href from pre-content scripts to match them later on.
       */
      const scriptSrcs = preContentScripts
        .filter(({ src, href }) => src || href)
        .map(({ src, href }) => src || href);

      /*
       * Get relevant scripts by matching pre-content scripts with new added tags.
       */
      const foundScripts = [
        ...(addedTags?.scriptTags || [{}]),
        ...(addedTags?.linkTags || [{}]),
      ].filter(
        ({ src, href }) =>
          scriptSrcs.indexOf(src) >= 0 || scriptSrcs.indexOf(href) >= 0
      );

      /*
       * Add an 'onload' event to every matched tag, so we can react on them as soon as they are loaded.
       */
      if (foundScripts.length) {
        foundScripts.forEach((foundScript) => {
          // eslint-disable-next-line no-param-reassign
          foundScript.onload = () => {
            /*
             * Reduce the amount of still loading scripts by one till it becomes 0.
             * For what ever edge case may come we ensure the number will not drop
             * below 0, so this will not become a blocker somehow for post-scripts.
             */
            setUnloadedScripts((value) => value - 1 || 0);
            /*
             * Set the initial loading flag (the initial render blocker) to true,
             * so only the unloaded scripts amount remain relevant.
             */
            setPreScriptsLoaded(true);
          };
        });
      }
    }
  };

  return (
    <React.Fragment
      key={textWithoutScripts ? 'clientContent' : 'serverContent'}
    >
      {!!preContentScripts.length && (
        <Helmet onChangeClientState={handleChangeClientState}>
          {preContentScripts.map(
            ({ scriptType, content, ...scriptAttributes }, index) => {
              if (scriptType === 'script') {
                return (
                  <script
                    id={`prescript_${index}`}
                    key={`prescript_${index}`}
                    {...scriptAttributes}
                  >
                    {content}
                  </script>
                );
              }

              if (scriptType === 'link') {
                return (
                  <link
                    id={`prescript_${index}`}
                    key={`prescript_${index}`}
                    {...scriptAttributes}
                  />
                );
              }

              return null;
            }
          )}
        </Helmet>
      )}

      {
        /* Render parsed html or plain html if no pre-scripts exists */
        (!!textWithoutScripts || !hasScriptTag) && (
          <div
            className="body markdown"
            dangerouslySetInnerHTML={{
              /*
               * Either render parsed html (only client side) to ensure scripts are loaded correctly
               * and take caution to rehydration or raw html if it does not contain any scripts
               * to render html as fast as possible.
               *
               */
              __html:
                textWithoutScripts ||
                (!hasScriptTag && entry.text.childMarkdownRemark.html),
            }}
          />
        )
      }

      {!!postContentScripts.length && preScriptsReady && (
        <Helmet>
          {postContentScripts.map(
            ({ scriptType, content, ...scriptAttributes }, index) => {
              if (scriptType === 'script') {
                return (
                  <script
                    id={`postscript_${index}`}
                    key={`postscript_${index}`}
                    {...scriptAttributes}
                  >
                    {content}
                  </script>
                );
              }

              if (scriptType === 'link') {
                return (
                  <link
                    id={`postscript_${index}`}
                    key={`postscript_${index}`}
                    {...scriptAttributes}
                  />
                );
              }

              return null;
            }
          )}
        </Helmet>
      )}
    </React.Fragment>
  );
};

export default ContentfulText;
