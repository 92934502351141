import React from 'react';

const KnownFromLegacy = () => (
  <div className="col-12 col-md-4">
    <strong className="border-bottom border-white d-block">
      Bekannt aus den Medien
      <a href="#press-release-dates" className="text-white">
        <sup>1</sup>
      </a>
    </strong>
    <div className="medien pt-3">
      <div className="transparent rounded mb-1 p-2 position-center text-center">
        <img
          src="/legacy/assets/wert-ermitteln/die-welt.svg"
          alt="Die Welt"
          width="120px"
        />
      </div>
      <div className="transparent rounded mb-1 p-2 position-center text-center">
        <img
          src="/legacy/assets/wert-ermitteln/Tagesspiegel-Logo.svg"
          alt="Der Tagesspiegel"
          width="120px"
        />
      </div>
      <div className="transparent rounded mb-1 p-2 position-center text-center">
        <img
          src="/legacy/assets/wert-ermitteln/klassik-radio.svg"
          alt="Focus"
          width="120px"
        />
      </div>
      <div className="transparent rounded mb-1 p-2 position-center text-center">
        <img
          src="/legacy/assets/wert-ermitteln/logo-immobilienzeitung.svg"
          alt="Immobilien Zeitung"
          width="120px"
        />
      </div>
      <div className="transparent rounded mb-1 p-2 position-center text-center">
        <img
          src="/legacy/assets/wert-ermitteln/Hamburger-abendblatt-schriftzug.svg"
          alt="Hamburger Abendblatt"
          width="140px"
        />
      </div>
    </div>
  </div>
);

export default KnownFromLegacy;
