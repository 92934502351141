import React from 'react';
import get from 'lodash/get';
import IconListItem from './IconListItem';
import IconList from './IconList';
import Icon from './Icon';
import CardList from './CardList';

const ContentfulList = ({ entry, inverted, listClassname }) => {
  const iconColor = inverted ? 'primary' : 'white';
  const iconBackground = inverted ? 'bg-white' : 'bg-primary';

  return entry.template === 'cards' ? (
    <CardList
      listItems={entry.listItems}
      iconColor={iconColor}
      iconBackground={iconBackground}
    />
  ) : (
    <IconList className={listClassname}>
      {entry.listItems.map((item) => {
        const icon = (
          <Icon
            name={item.icon}
            size={50}
            className={`rounded-circle ${iconBackground}`}
            color={iconColor}
            style={{ width: '50px', height: '50px', padding: '10px' }}
          />
        );
        const text = get(item, 'text.childMarkdownRemark.html');
        return (
          <IconListItem
            key={item.id}
            title={item.title}
            link={item.link}
            template={item.template}
            htmlText={text}
            icon={icon}
            type={item.type}
          />
        );
      })}
    </IconList>
  );
};

export default ContentfulList;
