import React from 'react';
import { Container } from 'reactstrap';
import { Text, Image, Button } from '@hausgold/designsystem';
import { ChevronRight } from '@hausgold/designsystem/icons';
import Section from '../../Section';
import SectionHeading from '../../SectionHeading';
import TeaserImage from './image.png';
import Link from '../../../Link';

const BenefitTeaser = () => (
  <Section mb={0}>
    <Container>
      <SectionHeading>
        Entdecken Sie die Vorteile unseres Maklerportals
      </SectionHeading>
      <Text size="lg">
        Steigern Sie Ihre Effizienz in der Immobilienakquise mit unserem
        exklusiven Maklerportal. Nehmen Sie Objekte schnell und problemlos auf
        jedem Gerät an und profitieren Sie von einem präzisen Wissensvorsprung.
        Unsere Plattform liefert Ihnen detaillierte Informationen zu den
        Objekten und den individuellen Kundenwünschen, die Sie für eine
        maßgeschneiderte Akquise benötigen. Darüber hinaus bieten wir Ihnen
        professionelle Immobilienbewertungen, um fundierte Entscheidungen zu
        treffen.
      </Text>
      <Button
        as={Link}
        to="/koop-anfrage"
        mb={10}
        width={{ base: '100%', md: 'auto' }}
        leftIcon={<ChevronRight />}
        textTransform="uppercase"
      >
        Jetzt Anmelden
      </Button>
      <Image
        w="100%"
        maxW="100%"
        display="block"
        margin="0 auto"
        src={TeaserImage}
        alt="HAUSGOLD Maklerportal"
      />
    </Container>
  </Section>
);

export default BenefitTeaser;
