import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getSrc } from 'gatsby-plugin-image';
import Header from '../components/Header';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const PageNotFound = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = getSrc(get(page, 'heroImage'));

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        description={page.metaDescription}
        indexable={page.indexable}
      />
      <Container>
        <Row className="container">
          <Col>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: page.body.childMarkdownRemark.html,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PageNotFound;

export const pageQuery = graphql`
  query PageNotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: "404" }) {
      id
      title
      headline
      metaTitle
      subTitle
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
