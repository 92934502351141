import TrustpilotReviews from '@pittica/gatsby-plugin-trustpilot-widget';
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@hausgold/designsystem';

/**
 * This is a wrapper around the 'TrustpilotReviews' from Gatsby Plugins
 * as the regular Trustpilot widget do not show any information since when
 * the information are gathered which is necessary at actual law.
 *
 * Note: Unfortunately the plugin is not able to show two or more
 *   instances of itself at the same time on the same page which results
 *   in flickering and request spamming from all but the last one.
 *   If you need to add multiple Trustpilot Widgets on the same page
 *   you are f***ed up.
 * @param language
 * @param culture
 * @param theme
 * @param height
 * @param width
 * @param withDateFrom
 */
const Trustpilot = ({
  language,
  culture,
  theme,
  height,
  width,
  withDateFrom,
  textAlign = 'left',
}) => (
  <>
    <TrustpilotReviews
      language={language}
      culture={culture}
      theme={theme}
      width={width}
      height={height}
    />
    {withDateFrom && (
      <Box textAlign={textAlign} fontSize="11px" lineHeight="13px">
        Seit Juni 2019
      </Box>
    )}
  </>
);

export default Trustpilot;

Trustpilot.propTypes = {
  language: PropTypes.string,
  culture: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  height: PropTypes.string,
  width: PropTypes.string,
  withDateFrom: PropTypes.bool,
};

Trustpilot.defaultProps = {
  language: 'de',
  culture: 'DE',
  theme: 'light',
  height: '83px',
  width: '135px',
  withDateFrom: true,
};
