import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  CardDeck,
  Card,
} from 'reactstrap';
import Layout from '../components/Layout';
import CategoryPreview from '../components/CategoryPreview';
import Header from '../components/Header';
import SEO from '../components/SEO';

const BlogIndex = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const categories = get(data, 'allContentfulCategory.edges');
  const heroImage = get(page, 'heroImage');
  const site = get(data, 'site');

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container className="mt-6">
        <Breadcrumb>
          <BreadcrumbItem active>Ratgeber</BreadcrumbItem>
        </Breadcrumb>
      </Container>

      <Container>
        <Row>
          <Col>
            <h2 className="p-0">Kategorien Übersicht</h2>
            {page.body && (
              <div
                className="body"
                dangerouslySetInnerHTML={{
                  __html: page.body.childMarkdownRemark.html,
                }}
              />
            )}
            <CardDeck tag="ul" className="p-0">
              {categories.map(({ node }, index) => (
                <Fragment key={node.id}>
                  <Card tag="li" className="mb-4">
                    <CategoryPreview category={node} />
                  </Card>
                  {(index + 1) % 2 === 0 && (
                    <div className="w-100 d-md-block d-lg-none" />
                  )}
                  {(index + 1) % 3 === 0 && (
                    <div className="w-100 d-none d-lg-block" />
                  )}
                </Fragment>
              ))}
            </CardDeck>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "ratgeber" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }

    allContentfulCategory {
      edges {
        node {
          id
          slug
          subTitle
          title
          heroImage {
            gatsbyImageData(layout: FIXED, width: 545, height: 545)
            title
          }
        }
      }
    }
  }
`;
