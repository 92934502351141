import React, { useState } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import * as Yup from 'yup';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
} from 'reactstrap';
import { Formik, Form as FormikForm, Field } from 'formik';
import axios from 'axios';
import queryString from 'query-string';
import Header from '../components/Header';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Link from '../components/Link';

const CooperationSchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie Ihren Vor- und Nachnamen an'),
  phone: Yup.string()
    .min(3, 'Bitte geben Sie eine gültige Telefonnummer an.')
    .required('Bitte geben Sie Ihre Telefonnummer an'),
});

const BrokerColdCall = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const site = get(data, 'site');
  const queryParams = queryString.parse(location.search);
  const [formState, setFormState] = useState({
    isSubmitted: false,
    isLoading: false,
  });

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md={{ size: 8 }} className="mt-6 mb-5">
            {formState.isSubmitted ? (
              <Alert color="success">
                Vielen Dank für Ihr Interesse. Der nächste freie Mitarbeiter
                wird sich in Kürze bei Ihnen melden.
              </Alert>
            ) : null}

            {!formState.isSubmitted && (
              <>
                <div
                  className="body markdown"
                  dangerouslySetInnerHTML={{
                    __html: get(page, 'body.childMarkdownRemark.html'),
                  }}
                />

                <Formik
                  initialValues={{
                    name: queryParams?.name || '',
                    phone: '',
                  }}
                  validationSchema={CooperationSchema}
                  onSubmit={async (values, { resetForm, setStatus }) => {
                    // Setting loading before form submit
                    setFormState({ ...formState, isLoading: true });

                    axios
                      .post(
                        'https://hooks.zapier.com/hooks/catch/2548295/3o8gltw/',
                        JSON.stringify(values),
                        {
                          transformRequest: (response, headers) => {
                            if (headers.common?.['Content-Type']) {
                              // eslint-disable-next-line no-param-reassign
                              delete headers.common['Content-Type'];
                            }

                            return response;
                          },
                        }
                      )
                      .then(() => {
                        resetForm();
                        setStatus({ success: true });
                        setFormState({
                          ...formState,
                          isSubmitted: true,
                          isLoading: false,
                        });
                      })
                      .catch(() => {
                        setStatus({ success: false });
                        setFormState({
                          ...formState,
                          isSubmitted: false,
                          isLoading: false,
                        });
                      });
                  }}
                >
                  {({ errors, touched, isSubmitting, status }) => (
                    <Form tag={FormikForm}>
                      {status && status.success && (
                        <Alert color="success">Danke für Ihre Nachricht.</Alert>
                      )}
                      {status && !status.success && (
                        <Alert color="danger">
                          Leider ist ein Fehler aufgetreten. Bitte senden Sie
                          für Ihre Anfrage eine E-Mail an{' '}
                          <a href="mailto:accountmanagement@hausgold.de">
                            accountmanagement@hausgold.de
                          </a>
                          .
                        </Alert>
                      )}
                      <FormGroup>
                        <Label className="mb-2" for="name">
                          Ihr Vor- und Nachname:
                        </Label>
                        <Input
                          invalid={!!errors.name && touched.name}
                          tag={Field}
                          type="text"
                          name="name"
                          id="name"
                        />
                        <FormFeedback>{errors.name}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label className="mb-2" for="phone">
                          Ihre Telefonnummer:
                        </Label>
                        <Input
                          invalid={!!errors.phone && touched.phone}
                          tag={Field}
                          type="number"
                          name="phone"
                          id="phone"
                        />
                        <FormFeedback>{errors.phone}</FormFeedback>
                      </FormGroup>
                      <FormGroup check row>
                        <Col className="text-right">
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            Jetzt Rückruf anfordern
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            <Col xs="12" md="8" className="mt-5">
              Mit Klick auf &ldquo;Jetzt Rückruf anfordern&rdquo; akzeptieren
              Sie unsere <Link to="/agb/">AGB</Link>
              &nbsp;und&nbsp;
              <Link to="/datenschutz/">Datenschutzbestimmungen</Link>
            </Col>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BrokerColdCall;

export const pageQuery = graphql`
  query KooperationQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "makler-akquise-rueckruf" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
