import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col } from 'reactstrap';
import cn from 'classnames';
import Layout from '../components/Layout';
import HeaderText from '../components/HeaderText';
import HeaderSimple from '../components/HeaderSimple';
import FooterSimple from '../components/FooterSimple';
import WizardCard from '../components/WizardCard';
import Formwizard from '../components/Formwizard';
import SEO from '../components/SEO';
import ContentBlock from '../components/ContentBlock';
import KnownFrom from '../components/KnownFrom';
import ExitIntent from '../components/ExitIntent';
import ExitIntentModal from '../components/ExitIntentModal';
import { collectFaqs } from '../utilities';

// Landingpage is used to create and build dedicated LPs depending on user intent in Contentful
const LandingpageTemplate = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const formwizard = get(page, 'formwizard');
  const exitIntent = get(page, 'exitIntent');
  const showPressLogos = get(page, 'showPressLogos', true);
  const formwizardPosition = get(formwizard, 'position');
  const formwizardUrl =
    get(formwizard, 'url') || 'https://formwizard.hausgold.de/formular/content';
  const contentBlocks = get(page, 'contentBlocks');
  const [showPopup, setShowPopup] = useState(false);
  const Header = heroImage ? HeaderSimple : HeaderText;
  const body = get(page, 'body.childMarkdownRemark.html');

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 10,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowPopup(true);
      },
    });
    return () => {
      removeExitIntent();
    };
  });

  const faqItems = collectFaqs({
    contentBlocks,
  });

  return (
    <Layout
      location={location}
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          titleColor={page.headlineColor}
          subTitle={page.subTitle}
          size={formwizardPosition === 'header' ? 'lg' : 'md'}
        >
          {formwizard && (
            <WizardCard>
              <Formwizard
                position={formwizardPosition}
                location={location}
                url={formwizardUrl}
              />
            </WizardCard>
          )}
        </Header>
      }
      footer={<FooterSimple />}
    >
      <Helmet>
        <meta name="content-entity" content="page[landingpage]" />
        <meta name="content-id" content={page.contentful_id} />
      </Helmet>

      <SEO
        entity={page}
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type="landingpage"
        description={page.metaDescription}
        indexable={page.indexable}
        faqItems={faqItems}
      />

      {(body || formwizardPosition) && (
        <Container className="mt-6 mb-5">
          <Row className="justify-content-between">
            <Col
              xs="12"
              lg={formwizardPosition === 'right' ? 7 : 12}
              className={cn('order-2', {
                'order-md-1': formwizardPosition === 'right',
                'order-md-2': formwizardPosition !== 'right',
              })}
            >
              <div
                className="body markdown"
                dangerouslySetInnerHTML={{
                  __html: get(page, 'body.childMarkdownRemark.html'),
                }}
              />
            </Col>
            {(formwizardPosition === 'right' ||
              formwizardPosition === 'top') && (
              <Col
                xs="12"
                lg={formwizardPosition === 'right' ? 5 : 12}
                xl={formwizardPosition === 'right' ? 4 : 12}
                className={cn('order-1', {
                  'order-md-2': formwizardPosition === 'right',
                  'order-md-1': formwizardPosition !== 'right',
                })}
              >
                {formwizardPosition && (
                  <Formwizard
                    position={formwizardPosition}
                    location={location}
                    url={formwizardUrl}
                  />
                )}
              </Col>
            )}
          </Row>
        </Container>
      )}

      {showPressLogos && <KnownFrom />}

      {contentBlocks &&
        contentBlocks.map((block) => (
          <ContentBlock
            key={block.id}
            block={block}
            narrow={formwizardPosition !== 'right'}
            location={location}
          />
        ))}

      {exitIntent && (
        <ExitIntentModal
          show={showPopup}
          image={exitIntent.image}
          text={exitIntent.text.childMarkdownRemark.html}
          button={exitIntent.button}
          setShowPopup={setShowPopup}
        />
      )}
    </Layout>
  );
};

export default LandingpageTemplate;

export const pageQuery = graphql`
  query LandingpageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      contentful_id
      id
      title
      headline
      headlineColor
      subTitle
      indexable
      metaTitle
      metaDescription
      showPressLogos
      formwizard {
        position
        url
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      exitIntent {
        image {
          gatsbyImageData(layout: FIXED, width: 170)
          title
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        button {
          title
          url
          color
          size
          shadow
        }
      }
      contentBlocks {
        title
        id
        entries {
          ... on ContentfulDownloadItem {
            internal {
              type
            }
            id
            title
            file {
              file {
                url
              }
              title
            }
            link
          }
          ... on ContentfulDownloadList {
            internal {
              type
            }
            id
            title
            downloadItems {
              internal {
                type
              }
              title
              file {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulList {
            internal {
              type
            }
            id
            listItems {
              internal {
                type
              }
              children {
                id
              }
              title
              id
              icon
              image {
                gatsbyImageData(layout: FIXED, width: 170)
                title
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            title
            template
          }
          ... on ContentfulListItem {
            internal {
              type
            }
            children {
              id
            }
            image {
              gatsbyImageData(layout: FIXED, width: 170)
              title
            }
            title
            id
            icon
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulText {
            internal {
              type
            }
            id
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulTextWithImage {
            internal {
              type
            }
            id
            title
            template
            imageAlignment
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 800)
              title
            }
          }
          ... on ContentfulFaqList {
            internal {
              type
            }
            id
            faqItems {
              internal {
                type
              }
              id
              answer {
                childMarkdownRemark {
                  html
                }
              }
              question
            }
          }
          ... on ContentfulButton {
            ...ContentfulButtonFragment
          }
          ... on ContentfulFormwizard {
            internal {
              type
            }
            id
            position
            title
            url
            variant
          }
        }
      }
      heroImage {
        gatsbyImageData(layout: FIXED, width: 1920, height: 690)
        title
      }
    }
  }
`;
