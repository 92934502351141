import { Box, Container, useBreakpoint } from '@hausgold/designsystem';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import Helmet from 'react-helmet';
import ContentBlock from '../../components/ContentBlock';
import FooterStraightLine from '../../components/FooterStraightLine';
import Formwizard from '../../components/Formwizard';
import HeaderFormwizardImage from '../../components/HeaderFormwizardImage';
import HeaderText from '../../components/HeaderText';
import PressLogos from '../../components/PressLogos';
import SEO from '../../components/SEO';
import WizardCard from '../../components/WizardCard';
import ApothekenUmschauLogoDesktop from '../../images/apotheken-umschau-desktop.svg';
import ApothekenUmschauLogoMobile from '../../images/apotheken-umschau-mobile.svg';
import DeutscheBankLogo from '../../images/deutsche-bank-partnerschaft.svg';

/*
 * This custom landing page exists because of a print advertisment in Apotheken Umschau - lp/verkauf
 */
const ApothekenUmschau = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const Header = heroImage ? HeaderFormwizardImage : HeaderText;
  const formwizard = get(page, 'formwizard');
  const formwizardPosition = get(formwizard, 'position');
  const formwizardUrl =
    get(formwizard, 'url') ||
    'https://formwizard.hausgold.de/v/cta/formular/apotheken-umschau';
  const showPressLogos = get(page, 'showPressLogos', true);
  const contentBlocks = get(page, 'contentBlocks');

  const isSmallerThanSM = useBreakpoint('sm', '<');

  return (
    <>
      <Helmet>
        <meta name="content-entity" content="page[landingpage]" />
        <meta name="content-id" content={page.contentful_id} />
      </Helmet>
      <SEO
        entity={page}
        title={page.metaTitle || page.title}
        canonical={page.canonical}
        type="page"
        description={page.metaDescription}
        indexable={page.indexable}
      />
      <Box
        sx={{
          '& > header': {
            position: 'relative',
          },
        }}
      >
        <Header
          // There is a CoupleImage above the heroImage in <HeaderFormwizardImage /> - which is custom for this LP and important for the wizard position.
          heroImage={heroImage}
          title={page.headline}
          titleColor={page.headlineColor}
          subTitle={page.subTitle}
          headerBrandingImage={
            isSmallerThanSM
              ? ApothekenUmschauLogoMobile
              : ApothekenUmschauLogoDesktop
          }
          phoneNumber="040 - 22 61 61 414"
          hideGradientOverlay
        >
          <WizardCard
            responsiveFooter={false}
            maxWidth="400px"
            footerLogo={DeutscheBankLogo}
            noCenter
          >
            <Formwizard
              location={location}
              url={formwizardUrl}
              boxShadow="none"
              margin={0}
            />
          </WizardCard>
        </Header>
      </Box>

      <Box mt="10" mb="8">
        {showPressLogos && (
          <Container px="2">
            <Box
              display={{ base: 'block', md: 'inline-block' }}
              my={{ base: 5, md: 0 }}
              mt={{ base: 4, md: 0 }}
              textAlign={{ base: 'center', md: 'left' }}
              textColor="gray.400"
            >
              Bekannt aus
              <sup>
                <a href="#press-release-dates">1</a>
              </sup>
              :
            </Box>

            <PressLogos />
          </Container>
        )}
      </Box>

      {contentBlocks &&
        contentBlocks.map((block) => (
          <ContentBlock
            key={block.id}
            block={block}
            narrow={formwizardPosition !== 'right'}
            location={location}
          />
        ))}
      <FooterStraightLine phoneNumber="040 - 22 61 61 414" />
    </>
  );
};

export default ApothekenUmschau;

export const pageQuery = graphql`
  query verkaufQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: "verkauf" }, template: { eq: "Landingpage" }) {
      contentful_id
      id
      title
      headline
      headlineColor
      subTitle
      indexable
      metaTitle
      metaDescription
      showPressLogos
      heroImage {
        gatsbyImageData(layout: FIXED, width: 1920, height: 860)
        title
      }
      formwizard {
        position
        url
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      contentBlocks {
        title
        id
        entries {
          ... on ContentfulDownloadItem {
            internal {
              type
            }
            id
            title
            file {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulDownloadList {
            internal {
              type
            }
            id
            title
            downloadItems {
              internal {
                type
              }
              title
              file {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulList {
            internal {
              type
            }
            id
            listItems {
              internal {
                type
              }
              children {
                id
              }
              title
              id
              icon
              image {
                gatsbyImageData(layout: FIXED, width: 170)
                title
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            title
            template
          }
          ... on ContentfulListItem {
            internal {
              type
            }
            children {
              id
            }
            image {
              gatsbyImageData(layout: FIXED, width: 170)
              title
            }
            title
            id
            icon
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulText {
            internal {
              type
            }
            id
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulTextWithImage {
            internal {
              type
            }
            id
            title
            template
            imageAlignment
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 800)
              title
            }
          }
          ... on ContentfulFaqList {
            internal {
              type
            }
            id
            faqItems {
              internal {
                type
              }
              id
              answer {
                childMarkdownRemark {
                  html
                }
              }
              question
            }
          }
          ... on ContentfulButton {
            ...ContentfulButtonFragment
          }
          ... on ContentfulFormwizard {
            internal {
              type
            }
            id
            position
            title
            url
            variant
          }
        }
      }
      heroImage {
        gatsbyImageData(layout: FIXED, width: 1920, height: 860)
        title
      }
    }
  }
`;
