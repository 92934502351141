import React from 'react';
import { graphql } from 'gatsby';
import * as Yup from 'yup';
import get from 'lodash/get';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
} from 'reactstrap';
import { Formik, Form as FormikForm, Field } from 'formik';
import axios from 'axios';
import SEO from '../../components/SEO';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Link from '../../components/Link';

const KooperationBaufinanzierung = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');

  const validationSchema = Yup.object().shape({
    company: Yup.string().required('Bitte geben Sie Ihren Firmennamen an.'),
    name: Yup.string().required('Bitte geben Sie ihren Namen an.'),
    email: Yup.string()
      .email('Bitte geben Sie eine richtige E-Mail-Adresse an.')
      .required('Bitte geben Sie Ihre E-Mail-Adresse an.'),
    phone: Yup.string().required('Bitte geben Sie Ihre Telefonnumer an.'),
  });

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md={{ size: 8 }} className="mt-6 mb-5">
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
            <Formik
              initialValues={{
                company: '',
                name: '',
                email: '',
                phone: '',
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm, setStatus }) => {
                axios
                  .post(
                    'https://getform.io/f/8c906239-21c6-484e-bcf1-6be3d9b0288e',
                    values,
                    { headers: { Accept: 'application/json' } }
                  )
                  .then(() => {
                    resetForm();
                    setStatus({ success: true });
                    // ResetForm()
                  })
                  .catch(() => {
                    setStatus({ success: false });
                  });
              }}
            >
              {({ errors, touched, isSubmitting, status }) => (
                <Form tag={FormikForm}>
                  {status && status.success && (
                    <Alert color="success">Danke für Ihre Nachricht.</Alert>
                  )}
                  {status && !status.success && (
                    <Alert color="danger">
                      Leider ist ein Fehler aufgetreten. Bitte senden Sie für
                      Ihre Anfrage eine E-Mail an
                      <a href="mailto:accountmanagement@hausgold.de">
                        accountmanagement@hausgold.de
                      </a>{' '}
                      .
                    </Alert>
                  )}

                  <FormGroup>
                    <Label for="company">Firma*:</Label>
                    <Input
                      invalid={!!errors.company && touched.company}
                      tag={Field}
                      type="text"
                      name="company"
                      id="company"
                    />
                    <FormFeedback>{errors.company}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="name">Name*:</Label>
                    <Input
                      invalid={!!errors.name && touched.name}
                      tag={Field}
                      type="text"
                      name="name"
                      id="name"
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="email">E-Mail-Adresse*:</Label>
                    <Input
                      invalid={!!errors.email && touched.email}
                      tag={Field}
                      type="email"
                      name="email"
                      id="email"
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="phone">Telefonnummer*:</Label>
                    <Input
                      invalid={!!errors.phone && touched.phone}
                      tag={Field}
                      type="text"
                      name="phone"
                      id="phone"
                    />
                    <FormFeedback>{errors.phone}</FormFeedback>
                  </FormGroup>
                  <FormGroup check row>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Senden
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              )}
            </Formik>
            <Col xs="12" md="8" className="mt-5">
              Mit Klick auf „Senden“ akzeptieren Sie unsere{' '}
              <Link to="/agb/">AGB </Link>
              und
              <Link to="/datenschutz/"> Datenschutzbestimmungen</Link>.
            </Col>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default KooperationBaufinanzierung;

export const pageQuery = graphql`
  query KooperationBaufinanzierungQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: "kooperation-baufinanzierung" }) {
      id
      title
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
