import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/**
 * This is a list component which is used in the WizardCard footer.
 *
 * @param responsiveFooter {Boolean}
 */
const WizardCardFooterList = ({ responsiveFooter }) => (
  <>
    <ul
      className={cn(
        'list-inline icon-list m-0 justify-content-between text-left',
        {
          'd-none d-md-flex': responsiveFooter,
          'd-none': !responsiveFooter,
        }
      )}
    >
      <li className="list-inline-item" style={{ width: '25%' }}>
        <svg
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <path
            d="M9,16.17L4.83,12l-1.42,1.41L9,19 21,7l-1.41,-1.41z"
            style={{ fill: '#19dc9b' }}
          />
        </svg>
        <small className="text-muted">
          Schnell Angaben zur Immobilie machen
        </small>
      </li>
      <li className="list-inline-item" style={{ width: '30%' }}>
        <svg
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <path
            d="M9,16.17L4.83,12l-1.42,1.41L9,19 21,7l-1.41,-1.41z"
            style={{ fill: '#19dc9b' }}
          />
        </svg>
        <small className="text-muted">
          Einfach und unverbindlich Formular ausfüllen
        </small>
      </li>
      <li className="list-inline-item" style={{ width: '22%' }}>
        <svg
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <path
            d="M9,16.17L4.83,12l-1.42,1.41L9,19 21,7l-1.41,-1.41z"
            style={{ fill: '#19dc9b' }}
          />
        </svg>
        <small className="text-muted">
          Kostenlose Experten-
          <br /> bewertung vor Ort
        </small>
      </li>
    </ul>

    <ul
      className={cn('icon-list m-0 p-0 text-left', {
        'd-block d-md-none': responsiveFooter,
        'd-block': !responsiveFooter,
      })}
    >
      <li className="list-inline-item">
        <svg
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <path
            d="M9,16.17L4.83,12l-1.42,1.41L9,19 21,7l-1.41,-1.41z"
            style={{ fill: '#19dc9b' }}
          />
        </svg>
        <small className="text-muted">
          Schnell Angaben zur Immobilie machen
        </small>
      </li>
      <li className="list-inline-item">
        <svg
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <path
            d="M9,16.17L4.83,12l-1.42,1.41L9,19 21,7l-1.41,-1.41z"
            style={{ fill: '#19dc9b' }}
          />
        </svg>
        <small className="text-muted">
          Einfach und unverbindlich Formular ausfüllen
        </small>
      </li>
      <li className="list-inline-item">
        <svg
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <path
            d="M9,16.17L4.83,12l-1.42,1.41L9,19 21,7l-1.41,-1.41z"
            style={{ fill: '#19dc9b' }}
          />
        </svg>
        <small className="text-muted">
          Kostenlose Expertenbewertung vor Ort
        </small>
      </li>
    </ul>
  </>
);

WizardCardFooterList.defaultProps = {
  responsiveFooter: true,
};

WizardCardFooterList.propTypes = {
  responsiveFooter: PropTypes.bool,
};

export default WizardCardFooterList;
