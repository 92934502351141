import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Link from './Link';

// This component shows the expert preview

const ExpertPreview = ({ expert }) => {
  const src = getImage(expert.image);

  return (
    <Row xs="1" md="2" className="mb-6">
      <Col>
        {expert.image?.gatsbyImageData && (
          <GatsbyImage
            /*
             * Alternative: This is the solution works if you want to control height of the image
             * imgStyle={{
             *   objectPosition: 'top',
             * }}
             * style={{
             *   maxHeight: '300px',
             * }}
             */
            image={{
              ...src,
              layout: 'fullWidth',
              objectFit: 'cover',
              objectPosition: 'top',
            }}
            alt={expert.image.title}
            className="w-100 h-100"
          />
        )}
      </Col>
      <Col>
        <h3 className="text-break">
          <Link to={`/experten/${expert.slug}/`}>{expert.title}</Link>
        </h3>
        <h4>{expert.company}</h4>
        {expert.freeText && (
          <div
            className="body pb-5"
            dangerouslySetInnerHTML={{
              __html: expert.freeText.childMarkdownRemark.html,
            }}
          />
        )}
        <Button
          className="mt-3"
          tag={Link}
          to={`/experten/${expert.slug}/`}
          color="primary"
        >
          Profil ansehen
        </Button>
      </Col>
    </Row>
  );
};

export default ExpertPreview;
