import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../components/Layout';
import ContentBlock from '../components/ContentBlock';
import Header from '../components/Header';
import SEO from '../components/SEO';

const Pressedownloads = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const contentBlocks = get(page, 'contentBlocks');
  const site = get(data, 'site');

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  const downloads =
    contentBlocks &&
    contentBlocks.filter(
      (block) =>
        block.entries &&
        block.entries.some(
          (entry) => entry.internal.type === 'ContentfulDownloadList'
        )
    );

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container className="mb-5 mt-6">
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <a className="nav-link active" href="/presse/">
              Presse
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pressemitteilungen/">
              Pressemitteilungen
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pressedownloads/">
              Pressedownloads
            </a>
          </li>
        </ul>
      </Container>

      <Container>
        <Row>
          <Col>
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            {downloads &&
              downloads.map((download) => (
                <ContentBlock
                  key={download.id}
                  centerContent
                  block={download}
                />
              ))}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Pressedownloads;

export const pageQuery = graphql`
  query pressedownloadQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "pressedownloads" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
      contentBlocks {
        title
        id
        entries {
          ... on ContentfulDownloadList {
            id
            internal {
              type
            }
            downloadItems {
              id
              title
              file {
                file {
                  url
                }
                gatsbyImageData(layout: FULL_WIDTH, width: 500)
              }
            }
          }
        }
      }
    }
  }
`;
