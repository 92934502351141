import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import * as Yup from 'yup';
import queryString from 'query-string';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
} from 'reactstrap';
import { Formik, Form as FormikForm, Field } from 'formik';
import axios from 'axios';
import Header from '../../components/Header';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

const Tippgeber = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const queryParams = queryString.parse(location.search);

  const DeutschebankSchema = Yup.object().shape({
    id: Yup.string().required('Bitte geben Sie Ihre Partner-ID an.'),
    name: Yup.string().required('Bitte geben Sie Ihren Namen an.'),
    organization: Yup.string().required('Bitte geben Sie den Firmennamen an.'),
    costumername: Yup.string().required(
      'Bitte geben Sie Ihren Kundennamen an.'
    ),
    costumerlastname: Yup.string().required(
      'Bitte geben Sie Ihren Kunden Nachnamen an.'
    ),
    costumerphone: Yup.number()
      .min(3, 'Bitte geben seine eine gültige Telefonnummer an.')
      .required('Bitte geben Sie Ihre Telefonnumer an.'),

    costumermail: Yup.string()
      .email('Bitte geben Sie eine richtige E-Mail-Adresse an.')
      .required('Bitte geben Sie Ihre E-Mail-Adresse an.'),

    immobilienzip: Yup.number()
      .min(1067, 'Bitte geben seine eine gültige Postleitzahl an.')
      .max(99999, 'Bitte geben seine eine gültige Postleitzahl an.')
      .required('Bitte geben Sie eine Postleitzahl an.'),

    immobilienort: Yup.string().required(
      'Bitte geben Sie den Ort der Immobilie an.'
    ),
  });

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="10" className="mt-6 mb-5">
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
            <Formik
              initialValues={{
                id: queryParams.tipster_id,
                name: '',
                organization: '',
                costumername: '',
                costumerlastname: '',
                costumerphone: '',
                costumermail: '',
                immobilienzip: '',
                immobilienort: '',
                terminwunsch: '',
                time: '',
                kontaktieren: '',
              }}
              validationSchema={DeutschebankSchema}
              onSubmit={async (values, { resetForm, setStatus }) => {
                axios
                  .post(
                    'https://getform.io/f/4454d65d-57ad-446a-a225-77339103c4e7',
                    values,
                    { headers: { Accept: 'application/json' } }
                  )
                  .then(() => {
                    resetForm();
                    setStatus({ success: true });
                    // ResetForm()
                  })
                  .catch(() => {
                    setStatus({ success: false });
                  });
              }}
            >
              {({ errors, touched, isSubmitting, status }) => (
                <Form tag={FormikForm}>
                  {status && status.success && (
                    <Alert color="success">Danke für Ihre Nachricht.</Alert>
                  )}
                  {status && !status.success && (
                    <Alert color="danger">
                      Leider ist ein Fehler aufgetreten. Bitte senden Sie für
                      Ihre Anfrage eine E-Mail an
                      <a href="mailto:falk.oettel@hausgold.de">
                        falk.oettel@hausgold.de
                      </a>{' '}
                      .
                    </Alert>
                  )}
                  <Row className="justify-content-between mt-6">
                    <Col xs="12" md="6">
                      <p className="text-muted font-italic">* Pflichtfelder</p>

                      <h5>1. Ihre Kontakdaten:</h5>
                      <FormGroup>
                        <Label for="id">Partner-Id:*</Label>
                        <Input
                          invalid={!!errors.id && touched.id}
                          tag={Field}
                          type="text"
                          name="id"
                          id="id"
                        />
                        <FormFeedback>{errors.id}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="name">Name:*</Label>
                        <Input
                          invalid={!!errors.name && touched.name}
                          tag={Field}
                          type="text"
                          name="name"
                          id="name"
                        />
                        <FormFeedback>{errors.name}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="organization">Unternehmen:*</Label>
                        <Input
                          invalid={
                            !!errors.organization && touched.organization
                          }
                          tag={Field}
                          type="text"
                          name="organization"
                          id="organization"
                        />
                        <FormFeedback>{errors.organization}</FormFeedback>
                      </FormGroup>

                      <h5 className="mt-6">2. Vor- und Nachname des Kunden</h5>

                      <FormGroup>
                        <Label for="costumername">Vorname:*</Label>
                        <Input
                          invalid={
                            !!errors.costumername && touched.costumername
                          }
                          tag={Field}
                          type="text"
                          name="costumername"
                          id="costumername"
                        />
                        <FormFeedback>{errors.costumername}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="costumerlastname">Nachname:*</Label>
                        <Input
                          invalid={
                            !!errors.costumerlastname &&
                            touched.costumerlastname
                          }
                          tag={Field}
                          type="text"
                          name="costumerlastname"
                          id="costumerlastname"
                        />
                        <FormFeedback>{errors.costumerlastname}</FormFeedback>
                      </FormGroup>

                      <h5 className="mt-6">
                        3. Kontaktmöglichkeiten des Kunden
                      </h5>

                      <FormGroup>
                        <Label for="costumerphone">Telefonnumer:*</Label>
                        <Input
                          invalid={
                            !!errors.costumerphone && touched.costumerphone
                          }
                          tag={Field}
                          type="text"
                          name="costumerphone"
                          id="costumerphone"
                        />
                        <FormFeedback>{errors.costumerphone}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="costumermail">E-Mail-Adresse:*</Label>
                        <Input
                          invalid={
                            !!errors.costumermail && touched.costumermail
                          }
                          tag={Field}
                          type="text"
                          name="costumermail"
                          id="costumermail"
                        />
                        <FormFeedback>{errors.costumermail}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col xs="12" md="5">
                      <h5 className="mt-6">
                        4. Wo befindet sich die Immobilie?
                      </h5>

                      <FormGroup>
                        <Label for="immobilienzip">Postleitzahl:*</Label>
                        <Input
                          invalid={
                            !!errors.immobilienzip && touched.immobilienzip
                          }
                          tag={Field}
                          type="text"
                          name="immobilienzip"
                          id="immobilienzip"
                        />
                        <FormFeedback>{errors.immobilienzip}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="immobilienort">Ort:*</Label>
                        <Input
                          invalid={
                            !!errors.immobilienort && touched.immobilienort
                          }
                          tag={Field}
                          type="text"
                          name="immobilienort"
                          id="immobilienort"
                        />
                        <FormFeedback>{errors.immobilienort}</FormFeedback>
                      </FormGroup>

                      <h5 className="mt-6">
                        5. Hat Ihr Kunde einen Terminwunsch? (optional)
                      </h5>
                      <FormGroup>
                        <Label for="terminwunsch">Datum/Uhrzeit:</Label>
                        <Input
                          invalid={
                            !!errors.terminwunsch && touched.terminwunsch
                          }
                          tag={Field}
                          type="date"
                          name="terminwunsch"
                          id="terminwunsch"
                        />
                        <FormFeedback>{errors.terminwunsch}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="time">Datum/Uhrzeit:</Label>
                        <Input
                          invalid={!!errors.time && touched.time}
                          tag={Field}
                          type="time"
                          name="time"
                          id="time"
                        />
                        <FormFeedback>{errors.time}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <h5 id="kontaktieren" className="mt-6">
                          6. Dürfen wir Ihre/n Kunden/in kontaktieren?
                          (Einwilligung liegt Ihnen vor)*
                        </h5>
                        <div
                          role="group"
                          aria-labelledby="kontaktieren"
                          className="custom-control custom-radio custom-control-inline"
                        >
                          <Input
                            type="radio"
                            value="yes"
                            name="kontaktieren"
                            className="custom-control-input"
                            id="customRadioInline1"
                          />
                          <Label
                            className="custom-control-label"
                            for="customRadioInline1"
                          >
                            Ja
                          </Label>
                        </div>
                        <div
                          role="group"
                          aria-labelledby="kontaktieren"
                          className="custom-control custom-radio custom-control-inline"
                        >
                          <Input
                            type="radio"
                            value="no"
                            name="kontaktieren"
                            className="custom-control-input"
                            id="customRadioInline2"
                          />
                          <Label
                            className="custom-control-label"
                            for="customRadioInline2"
                          >
                            Nein
                          </Label>
                        </div>
                        <FormFeedback>{errors.kontaktieren}</FormFeedback>
                      </FormGroup>

                      <FormGroup check row>
                        <Col className="text-right">
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            Senden
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Tippgeber;

export const pageQuery = graphql`
  query TippgeberQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: "tippgeber" }) {
      id
      title
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
