import React from 'react';
import PropTypes from 'prop-types';
import { PATHS } from './paths';

// TODO Use brand colors instead of hex see reactstrap
const Icon = (props) => {
  const { color, size, name, className, viewBox, ...rest } = props;

  const colors = {
    primary: '#30A4EC',
    green: '#19dc9b',
    white: '#fff',
    'gray-2': '#87868F',
    'gray-3': '#bfbec8',
  };

  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      flex: '0 0 auto',
    },
    path: {
      fill: colors[color] || color,
    },
  };

  return (
    <svg
      style={styles.svg}
      className={`${name} ${className}`}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={viewBox}
      {...rest}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="3.061617e-15%"
          id="gray-grad"
        >
          <stop stopColor="#87868F" offset="0%" />
          <stop stopColor="#AEADB8" offset="100%" />
        </linearGradient>
        <linearGradient id="hg-blue">
          <stop stopColor="#1694F4" />
        </linearGradient>
      </defs>

      <path style={styles.path} d={PATHS[name]} />
    </svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
};

Icon.defaultProps = {
  size: 18,
  color: 'primary',
  className: null,
  viewBox: '0 0 24 24',
};

export default Icon;
