import { Box } from '@hausgold/designsystem';
import React from 'react';

/**
 * A section component.
 * @param {import('@hausgold/designsystem').BoxProps} props
 * @param {string} props.header
 */
const Section = (props) => (
  // We use `margin` here so it collapses with each other.
  <Box as="section" my={12} {...props} />
);

export default Section;
