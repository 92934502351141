import React from 'react';
import {
  Box,
  Image,
  Heading,
  Text,
  Link,
  HStack,
} from '@hausgold/designsystem';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import NewspaperIcon from './Icon/NewspaperIcon';

const ArticlePreview = ({ article }) => (
  <Link as={GatsbyLink} to={`/${article.category.slug}/${article.slug}/`}>
    <Box
      maxW="350px"
      m="auto"
      boxShadow="sm"
      borderRadius="lg"
      overflow="hidden"
      my={4}
    >
      <Box aspectRatio={4 / 3} overflow="hidden">
        {article.heroImage && (
          <Image
            as={GatsbyImage}
            image={getImage(article.heroImage)}
            alt={article.heroImage.title}
            fit="cover"
          />
        )}
      </Box>
      <Box p={4}>
        <Heading
          as="div"
          fontSize="lg"
          fontWeight="600"
          lineHeight="5"
          mb="2"
          noOfLines={2}
          h="3rem"
          textColor="blue.400"
        >
          {article.title}
        </Heading>
        <Text
          fontSize="md"
          fontWeight="400"
          lineHeight="6"
          noOfLines={5}
          textColor="black"
        >
          {article.description.childMarkdownRemark.excerpt}
        </Text>
        <HStack alignItems="center" mt={-4}>
          <NewspaperIcon color="blue.400" boxSize={4} />
          <Text
            m={0}
            fontSize="sm"
            fontWeight="400"
            lineHeight="6"
            display="block"
            textColor="blue.400"
          >
            weiterlesen
          </Text>
        </HStack>
      </Box>
    </Box>
  </Link>
);

export default ArticlePreview;
