/**
 * ATTENTION
 * This is just dirty prototype code for the new partner-werden page. Supposed to be removed after MVP phase.
 * ATTENTION
 */

import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ExitIntent from '../../components/ExitIntent';
import ExitIntentModal from '../../components/ExitIntentModal';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Footer from '../../components/partner-werden-prototype/Footer';
import Teaser from '../../components/partner-werden-prototype/content/Teaser/Teaser';
import BenefitCards from '../../components/partner-werden-prototype/content/BenefitCards';
import BenefitTeaser from '../../components/partner-werden-prototype/content/BenefitTeaser';
import Testimonial from '../../components/partner-werden-prototype/content/Testimonial';
import Jenna from '../../components/partner-werden-prototype/content/Jenna';
import Header from '../../components/Header';

const Content = [
  <BenefitCards />,
  <BenefitTeaser />,
  <Testimonial />,
  <Jenna />,
];

const PartnerWerdenPrototype = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const site = get(data, 'site');
  const heroImage = get(page, 'heroImage');
  const exitIntent = get(page, 'exitIntent');

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 10,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowPopup(true);
      },
    });
    return () => {
      removeExitIntent();
    };
  });

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/fuer-makler/${page.slug}/`,
    },
  ];

  // Copied from components/Header.jsx.....
  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <>
          <Header template="Makler" headerStyles={{ marginTop: 0 }} />
          <Teaser />
        </>
      }
      footer={<Footer />}
    >
      <Helmet>
        <meta name="content-entity" content="page" />
        <meta name="content-id" content={page.contentful_id} />
      </Helmet>
      <SEO
        entity={{ ...page, imageSrc: getSrc(heroImage) }}
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type="page"
        description={page.metaDescription}
        indexable={page.indexable}
        breadcrumbs={breadcrumbs}
      />

      {Content.map((component) => component)}

      {exitIntent && (
        <ExitIntentModal
          show={showPopup}
          image={exitIntent.image}
          text={exitIntent.text.childMarkdownRemark.html}
          button={exitIntent.button}
          setShowPopup={setShowPopup}
        />
      )}
    </Layout>
  );
};

export default PartnerWerdenPrototype;

export const pageQuery = graphql`
  query B2BPrototype {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "partner-werden" }) {
      useArticleSchema
      contentful_id
      id
      title
      headline
      headlineColor
      subTitle
      indexable
      metaTitle
      metaDescription
      slug
      updatedAt
      createdAt
      childPages {
        id
        slug
        title
      }
      page {
        id
        slug
        title
        childPages {
          id
          slug
          title
        }
      }
      formwizard {
        position
        url
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      exitIntent {
        image {
          gatsbyImageData(layout: FIXED, width: 170)
          title
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        button {
          title
          url
          color
          size
          shadow
        }
      }
      relatedPages {
        ... on ContentfulPage {
          id
          slug
          title
          metaDescription
          relatedPagesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          page {
            id
            slug
            title
          }
        }

        ... on ContentfulArticle {
          id
          slug
          title
          metaDescription
          relatedArticlesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              height: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          category {
            slug
          }
          description {
            description
            id
            childMarkdownRemark {
              id
              html
            }
          }
        }
      }
      faqList {
        faqItems {
          id
          answer {
            childMarkdownRemark {
              html
            }
          }
          question
        }
      }
      contentBlocks {
        title
        id
        tocPosition
        entries {
          ... on ContentfulDownloadItem {
            internal {
              type
            }
            id
            title
            file {
              file {
                url
              }
              title
            }
            link
          }
          ... on ContentfulDownloadList {
            internal {
              type
            }
            id
            title
            downloadItems {
              title
              file {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulList {
            internal {
              type
            }
            id
            listItems {
              internal {
                type
              }
              children {
                id
              }
              title
              id
              icon
              image {
                gatsbyImageData(layout: FIXED, width: 170)
                title
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            title
            template
          }
          ... on ContentfulListItem {
            internal {
              type
            }
            children {
              id
            }
            title
            id
            icon
            image {
              gatsbyImageData(layout: FIXED, width: 170)
              title
            }
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulText {
            internal {
              type
            }
            id
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulTextWithImage {
            internal {
              type
            }
            id
            title
            template
            imageAlignment
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 800)
              title
            }
          }
          ... on ContentfulFaqList {
            internal {
              type
            }
            id
            faqItems {
              id
              answer {
                childMarkdownRemark {
                  html
                }
              }
              question
            }
          }
          ... on ContentfulButton {
            internal {
              type
            }
            id
            color
            shadow
            size
            title
            url
          }
          ... on ContentfulFormwizard {
            internal {
              type
            }
            id
            position
            title
            url
            variant
          }
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }

    # Dirtily copied from Header.jsx
    ratgeber: contentfulNavigation(slug: { eq: "ratgeber" }) {
      entries {
        ... on ContentfulCategory {
          internal {
            type
          }
          id
          title
          slug
        }
      }
    }

    navigation: contentfulNavigation(slug: { eq: "hauptnavigation" }) {
      entries {
        ... on ContentfulCategory {
          internal {
            type
          }
          id
          title
          slug
        }
        ... on ContentfulNavigation {
          internal {
            type
          }
          id
          title
          slug
          entries {
            ... on ContentfulCategory {
              internal {
                type
              }
              id
              title
              slug
            }
            ... on ContentfulPage {
              internal {
                type
              }
              id
              slug
              title
              page {
                slug
                title
              }
            }
          }
        }
        ... on ContentfulPage {
          internal {
            type
          }
          id
          title
          slug
        }
      }
    }
  }
`;
