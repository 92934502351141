import React from 'react';
import { Container } from 'reactstrap';
import { SimpleGrid } from '@hausgold/designsystem';
import Benefit from './Benefit';
import { FaceAgentIcon, HandshakeIcon, HomeIcon, StarterIcon } from './Icons';
import Section from '../../Section';
import SectionHeading from '../../SectionHeading';

const BenefitCards = () => (
  <Section>
    <Container>
      <SectionHeading>
        Mit HAUSGOLD arbeiten und profitieren - Ihre Vorteile
      </SectionHeading>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
        {[
          {
            title: 'Gezielte Kundenakquise',
            icon: <HomeIcon />,
            text: 'Wir übernehmen die Generierung neuer Eigentümerkontakte und qualifizieren passende Kunden für Sie.',
          },
          {
            title: 'Flexible Abnahme',
            icon: <HandshakeIcon />,
            text: 'Sie entscheiden selbst, ob Sie ein Objekt annehmen - ohne jegliche Mindestabnahme.',
          },
          {
            title: 'Individuelles Preismodell',
            icon: <StarterIcon />,
            text: 'Wir bieten Ihnen Gemeinschaftsgeschäfte mit Provisionsbeteiligung sowie Eigentümerkontakte zum Festpreis an.',
          },
          {
            title: 'Direkter Support',
            icon: <FaceAgentIcon />,
            text: 'Unser engagiertes Team steht Ihnen jederzeit telefonisch oder per E-Mail zur Verfügung.',
          },
        ].map((benefit) => (
          <Benefit {...benefit} />
        ))}
      </SimpleGrid>
    </Container>
  </Section>
);

export default BenefitCards;
