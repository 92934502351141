import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  Circle,
  Wrap,
  Button,
  IconButton,
  useBreakpointValue,
} from '@hausgold/designsystem';
import { Link } from 'gatsby';
import { TfiWorld } from 'react-icons/tfi';
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaXing,
} from 'react-icons/fa';

const AuthorBox = ({
  title,
  name,
  imageUrl,
  lastUpdate,
  bio,
  profileLink,
  socialMediaLinks,
  style,
}) => {
  const isScreenMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box my="4" p={style.p} shadow={style.shadow}>
      <Stack spacing={2}>
        {/* Profile section with (title, name, image) */}
        <HStack align="center" w="full" py={2}>
          <Avatar size="lg" name={name} src={imageUrl} />
          <Stack spacing={0}>
            {name && (
              <Text size="lg" fontWeight="semibold" m={0}>
                {name}
              </Text>
            )}
            <Wrap
              direction={{ base: 'column', md: 'row' }}
              alignItems="flex-start"
              spacing={isScreenMobile ? 1 : 2}
            >
              {title && (
                <Text size="lg" my={0}>
                  {title}
                </Text>
              )}
              {lastUpdate && (
                <HStack align="baseline" h="max">
                  {!isScreenMobile ? (
                    <Circle size="4px" alignSelf="center" bg="blackAlpha.600" />
                  ) : null}
                  <HStack spacing={1}>
                    <Text my="0" size="lg">
                      Aktualisiert am
                    </Text>
                    <Text size="lg" my={0} as="time" dateTime={lastUpdate}>
                      {new Intl.DateTimeFormat('de', {
                        year: 'numeric',
                        month: '2-digit', // Use "2-digit" to format the month as 09, rather than "numeric" which would format it as 9 (which may be unexpected or odd to some users).
                        day: '2-digit',
                      }).format(new Date(lastUpdate))}
                    </Text>
                  </HStack>
                </HStack>
              )}
            </Wrap>
          </Stack>
        </HStack>

        {/* Bio */}
        {bio && (
          <Box
            fontSize="lg"
            dangerouslySetInnerHTML={{
              __html: bio,
            }}
          />
        )}

        {/* Social profile links */}
        {socialMediaLinks && (
          <Wrap spacingX={6}>
            {profileLink && (
              <Button as={Link} to={profileLink}>
                Zum Profil
              </Button>
            )}

            <Flex gap={4} alignItems="center" wrap="wrap">
              {socialMediaLinks?.website && (
                <IconButton
                  as="a"
                  target="_blank"
                  href={socialMediaLinks?.website}
                  boxSize={12}
                  rounded="full"
                  fontSize={26}
                  icon={<TfiWorld color="gray" />}
                  colorScheme="whiteAlpha"
                />
              )}
              {socialMediaLinks?.facebook && (
                <IconButton
                  as="a"
                  target="_blank"
                  href={socialMediaLinks?.facebook}
                  boxSize={12}
                  rounded="full"
                  fontSize={26}
                  icon={<FaFacebook color="gray" />}
                  colorScheme="whiteAlpha"
                />
              )}
              {socialMediaLinks?.instagram && (
                <IconButton
                  as="a"
                  target="_blank"
                  href={socialMediaLinks?.instagram}
                  boxSize={12}
                  rounded="full"
                  fontSize={26}
                  icon={<FaInstagram color="gray" />}
                  colorScheme="whiteAlpha"
                />
              )}
              {socialMediaLinks?.twitter && (
                <IconButton
                  as="a"
                  target="_blank"
                  href={socialMediaLinks?.twitter}
                  boxSize={12}
                  rounded="full"
                  fontSize={26}
                  icon={<FaTwitter color="gray" />}
                  colorScheme="whiteAlpha"
                />
              )}
              {socialMediaLinks?.linkedin && (
                <IconButton
                  as="a"
                  target="_blank"
                  href={socialMediaLinks?.linkedin}
                  boxSize={12}
                  rounded="full"
                  fontSize={26}
                  icon={<FaLinkedin color="gray" />}
                  colorScheme="whiteAlpha"
                />
              )}
              {socialMediaLinks?.xing && (
                <IconButton
                  as="a"
                  target="_blank"
                  href={socialMediaLinks?.xing}
                  boxSize={12}
                  rounded="full"
                  fontSize={26}
                  icon={<FaXing color="gray" />}
                  colorScheme="whiteAlpha"
                />
              )}
            </Flex>
          </Wrap>
        )}
      </Stack>
    </Box>
  );
};

AuthorBox.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  lastUpdate: PropTypes.string,
  bio: PropTypes.string,
  profileLink: PropTypes.string,
  socialMediaLinks: PropTypes.object,
  style: PropTypes.object,
};

AuthorBox.defaultProps = {
  imageUrl: null,
  lastUpdate: null,
  bio: null,
  profileLink: null,
  socialMediaLinks: null,
  style: {
    shadow: 'none',
    p: 0,
  },
};
export default AuthorBox;
