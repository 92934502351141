import React from 'react';
import { Button, Box } from '@hausgold/designsystem';
import { ConsentManager, openConsentManager } from '@segment/consent-manager';
import { Helmet } from 'react-helmet';
import inEU from '@segment/in-eu';
import { Link } from 'gatsby';

const CookieConsent = () => {
  const agree = 'Cookies zulassen';
  const disagree = 'Cookies ablehnen';
  const configure = 'Cookies konfigurieren';

  const bannerContent = (
    <Box ml="5" bg="blue-200" width="85%" className="text-left w-70">
      <h4>Diese Webseite verwendet Cookies</h4>
      <p>
        Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren,
        Funktionen für soziale Medien anbieten zu können und die Zugriffe auf
        unsere Website zu analysieren. Auch greifen wir auf Informationen auf
        Ihren Endgeräten zu bzw. rufen diese ab. Die so gesammelten Daten geben
        wir teilweise auch an unsere Partner für soziale Medien, Werbung und
        Analysen weiter, die ihren Sitz ggf. außerhalb der Europäischen Union,
        etwa in den USA, haben können (siehe „Details zeigen“). Dabei kann nicht
        ausgeschlossen werden, dass Ihre Daten mit anderen, bereits
        gespeicherten Daten von Ihnen verknüpft werden. Mit dem Klick auf den
        Button „{agree}“ erklären Sie sich mit der Nutzung Ihrer Daten
        einverstanden. Über „{disagree}“ können Sie die Nutzung Ihrer Daten
        verweigern. Um Ihre Einwilligung jederzeit auch für die Zukunft zu
        widerrufen, Ihre Einstellungen zu ändern oder für weitere Informationen
        klicken Sie auf „{configure}“.
      </p>
      <Link to="/impressum">Impressum</Link> |{' '}
      <Link to="/datenschutz">Datenschutz</Link>
    </Box>
  );
  const preferencesDialogTitle = 'Cookie Einstellungen';
  const preferencesDialogContent =
    'Hier können Sie Ihre Cookie-Einstellungen anpassen. Weitere ' +
    'Informationen zu den von uns verwendeten Cookies können sie unseren ' +
    'Datenschutzbestimmungen entnehmen: https://www.hausgold.de/datenschutz/#übersicht-über-verwendete-cookies';
  const cancelDialogTitle = 'Sind Sie sicher, dass sie abbrechen wollen?';
  const cancelDialogContent =
    'Ihre Einstellungen wurden noch nicht gespeichert. Wenn Sie unsere Website weiterhin nutzen, stimmen Sie der Verwendung von Cookies zu';
  const segmentWriteKey = `6c5M4qbHqN03jYrnd8q0IBuBwdRMjrqt`;
  const preferencesDialogTemplate = {
    headings: {
      allowValue: 'Erlauben',
      categoryValue: 'Kategorie',
      purposeValue: 'Beschreibung',
      toolsValue: 'Tools',
    },
    checkboxes: {
      noValue: 'Nein',
      yesValue: 'Ja',
    },
    actionButtons: {
      cancelValue: 'Abbrechen',
      saveValue: 'Speichern',
    },
    cancelDialogButtons: {
      cancelValue: 'Ja, abbrechen',
      backValue: 'Zurück',
    },
    categories: [
      {
        key: 'functional',
        name: 'Funktional',
        description:
          'Präferenz-Cookies ermöglichen einer Webseite sich an Informationen zu erinnern, die die Art beeinflussen, wie sich eine Webseite verhält oder aussieht, wie z. B. Ihre bevorzugte Sprache oder die Region in der Sie sich befinden. ',
        example: '',
      },
      {
        key: 'marketing',
        name: 'Webanalyse',
        description:
          'Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden. ',
        example: '',
      },
      {
        key: 'advertising',
        name: 'Interessenbasierte Werbung',
        description:
          'Marketing-Cookies werden verwendet, um Besuchern auf Webseiten zu folgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind. ',
        example: '',
      },
      {
        key: 'essential',
        name: 'Notwendig',
        description:
          'Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren. ',
        example: '',
      },
    ],
  };
  const bannerActionsBlock = ({ acceptAll, denyAll }) => (
    <Box className="text-left" mr="5">
      <Button mb={2} width={200} size="sm" type="button" onClick={acceptAll}>
        {agree}
      </Button>
      <Button
        width={200}
        size="sm"
        mb={2}
        variant="outline"
        color="black"
        _hover={{
          backgroundColor: 'black',
          color: 'white',
        }}
        borderColor="black"
        borderWidth="2px"
        onClick={openConsentManager}
      >
        {configure}
      </Button>
      <Button
        width={200}
        size="sm"
        variant="outline"
        color="black"
        _hover={{
          backgroundColor: 'black',
          color: 'white',
        }}
        borderColor="black"
        borderWidth="2px"
        onClick={denyAll}
      >
        {disagree}
      </Button>
    </Box>
  );

  return (
    <div className="fixed-bottom" style={{ zIndex: 1000 }}>
      <Helmet>
        <script>
          {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://s-assets.hausgold.de/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};;analytics.SNIPPET_VERSION="4.13.2";
          analytics.page();
          }}();
          `}
        </script>
      </Helmet>
      <ConsentManager
        writeKey={segmentWriteKey}
        shouldRequireConsent={inEU}
        bannerContent={bannerContent}
        bannerTextColor="#3F3E4B"
        bannerBackgroundColor="#ffffff"
        bannerSubContent=""
        bannerHideCloseButton
        bannerActionsBlock={bannerActionsBlock}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        preferencesDialogTemplate={preferencesDialogTemplate}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        cdnHost="s-assets.hausgold.de"
      />
    </div>
  );
};

export default CookieConsent;
