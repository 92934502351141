import { Box, Card, CardBody, CardHeader } from '@hausgold/designsystem';
import React from 'react';

const Benefit = ({ title, icon, text }) => {
  const Icon = React.cloneElement(icon, {
    width: 50,
    height: 50,
    color: 'blue.400',
    display: 'inline-block',
  });
  return (
    <Card textAlign="center">
      <CardHeader border="none">
        <Box margin="0 auto">
          {Icon}
          <br />
          <span>{title}</span>
        </Box>
      </CardHeader>
      <CardBody>
        <p>{text}</p>
      </CardBody>
    </Card>
  );
};

export default Benefit;
