import React from 'react';
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  Text,
  LinkOverlay,
  LinkBox,
} from '@hausgold/designsystem';
import { DownloadFilled } from '@hausgold/designsystem/icons';

const ContentfulDownloadItem = ({ entry }) => {
  const { link, file: fileProp } = entry;
  const file = fileProp?.file.url;

  // Prioritize `link` over `file` when deciding on a URL.
  const url = link || file;

  // Do not render anything if there is no URL.
  if (!url) return null;

  // Set useful props for the link.
  const linkProps = {
    href: url,
    target: '_blank',
    // Make sure to not set `download` if both are set but `link` is set to override the URL.
    ...(file && !link && { download: true }),
  };

  return (
    <Card as={LinkBox}>
      <CardHeader
        display="block"
        border={0}
        _hover={{
          color: 'gray.500',
        }}
      >
        {entry.date && (
          <Text mb={1} fontWeight="lighter">
            {entry.date} Uhr
          </Text>
        )}
        {entry.title}
      </CardHeader>
      <CardBody>
        <Button
          as={LinkOverlay}
          {...linkProps}
          leftIcon={<DownloadFilled />}
          // `Button` overwrites the position, but it's necessary for `as={LinkOverlay}` to work.
          sx={{ position: 'static' }}
        >
          Download
        </Button>
      </CardBody>
    </Card>
  );
};

export default ContentfulDownloadItem;
