import React from 'react';
import Link from './Link';

const IconListItem = ({ icon, title, htmlText, link, template }) => {
  if (!link && template && template === 'icon-link') {
    return null;
  }

  return (
    <li className="pb-3 d-flex justify-content-center flex-column">
      {link && template === 'icon-link' ? (
        <Link to={link} target="_blank">
          {icon}
        </Link>
      ) : (
        icon
      )}

      {template !== 'icon-link' && (
        <>
          {title && <h3 className="h5">{title}</h3>}

          <div
            dangerouslySetInnerHTML={{
              __html: htmlText,
            }}
          />
        </>
      )}
    </li>
  );
};

export default IconListItem;
