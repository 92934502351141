import React from 'react';
import { Col } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Person = ({ image, firstName, lastName, title }) => (
  <Col xs="6" md="2" className="text-center">
    {image && getImage(image) ? (
      <GatsbyImage
        image={getImage(image)}
        alt={image.title}
        className="rounded-circle mx-auto"
        style={{ maxWidth: '120px', maxHeight: '120px' }}
      />
    ) : (
      <div
        className="rounded-circle mx-auto bg-light"
        style={{ width: '120px', height: '120px' }}
      />
    )}
    <p className="text-center pt-2">
      <b>
        {firstName} {lastName}
      </b>{' '}
      <br />
      {title}
    </p>
  </Col>
);

export default Person;
