import {
  Box,
  Text,
  Button,
  FormControl,
  Input,
  Label,
  Feedback,
  Textarea,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@hausgold/designsystem';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container } from 'reactstrap';
import ContentBlock from '../../components/ContentBlock';
import FooterStraightLine from '../../components/FooterStraightLine';
import HeaderSimple from '../../components/HeaderSimple';
import HeaderText from '../../components/HeaderText';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

/**
 * Form validation scheme.
 */
const schema = zod.object({
  notary: zod
    .string()
    .min(1, { message: 'Bitte füllen Sie das Pflichtfeld aus.' }),
  street: zod
    .string()
    .min(1, { message: 'Bitte füllen Sie das Pflichtfeld aus.' }),
  postcode: zod
    .string()
    .min(1, { message: 'Bitte füllen Sie das Pflichtfeld aus.' }),
  city: zod
    .string()
    .min(1, { message: 'Bitte füllen Sie das Pflichtfeld aus.' }),
  notes: zod.string().optional(),
  foreName: zod
    .string()
    .min(1, { message: 'Bitte füllen Sie das Pflichtfeld aus.' }),
  lastName: zod
    .string()
    .min(1, { message: 'Bitte füllen Sie das Pflichtfeld aus.' }),
  email: zod
    .string()
    .min(1, { message: 'Bitte füllen Sie das Pflichtfeld aus.' })
    /*
     * Checks the mail for '@' and '.' between other chars.
     * This is only a very rough check up.
     * Example: foo@bar.baz
     */
    .regex(/^.+@.+\..+$/, {
      message: 'Bitte geben Sie eine gültige E-Mail an.',
    }),
  telephone: zod.string().optional(),
});

/**
 * This is the lp/verkauf-glueckwunsch landingpage, which includes a form and a modal.
 *
 * @param data
 * @param location
 */
const VerkaufGlueckwunsch = ({ data, location }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const Header = heroImage ? HeaderSimple : HeaderText;
  const contentBlocks = get(page, 'contentBlocks');

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    setIsError(false);

    fetch('https://getform.io/f/004ee806-d9bc-4702-98a8-a9c53c351287', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(() => {
        setIsSend(true);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsSubmitting(false);
        setShowModal(true);
      });
  };

  return (
    <Layout
      location={location}
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          titleColor={page.headlineColor}
          subTitle={page.subTitle}
        />
      }
      footer={<FooterStraightLine />}
    >
      <Helmet>
        <meta name="content-entity" content="page[landingpage]" />
        <meta name="content-id" content={page.contentful_id} />
      </Helmet>

      <SEO
        entity={page}
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type="landingpage"
        description={page.metaDescription}
        indexable={page.indexable}
      />
      <Box mt="6">
        {contentBlocks &&
          contentBlocks.map((block) => (
            <ContentBlock key={block.id} block={block} location={location} />
          ))}
      </Box>

      <Container className="container-narrow">
        <Modal
          isOpen={showModal}
          onClose={handleCloseModal}
          closeButtonProps={{ bg: 'white', border: 'none' }}
        >
          <ModalHeader pr={8}>
            {isError && 'Ein Fehler ist aufgetreten'}
            {!isError && 'Wir haben Ihre Nachricht erhalten'}
          </ModalHeader>
          <ModalBody>
            {isError &&
              'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'}
            {!isError && (
              <>
                <Text>Vielen Dank.</Text>
                <Text>
                  Bei Rückfragen wird sich unser Kundenservice in Kürze
                  persönlich bei Ihnen melden.
                </Text>
                <Text>Ihr HAUSGOLD-Team</Text>
              </>
            )}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button onClick={handleCloseModal}>Schließen</Button>
          </ModalFooter>
        </Modal>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormControl
            id="notary"
            isInvalid={errors.notary}
            isDisabled={isSend}
          >
            <Label isRequired>
              Bitte tragen Sie hier Ihren Notartermin bzw. Verkaufstermin ein
            </Label>
            <Input type="text" placeholder="Datum" {...register('notary')} />
            <Feedback>{errors.notary?.message}</Feedback>
          </FormControl>
          <Label isRequired>
            An welche Anschrift dürfen wir unser Dankeschön senden?
          </Label>
          <FormControl
            id="street"
            isInvalid={errors.street}
            isDisabled={isSend}
          >
            <Input
              type="text"
              placeholder="Straße und Hausnummer"
              {...register('street')}
            />
            <Feedback>{errors.street?.message}</Feedback>
          </FormControl>
          <FormControl
            id="postcode"
            isInvalid={errors.postcode}
            isDisabled={isSend}
          >
            <Input
              type="number"
              inputemode="numeric"
              placeholder="Postleitzahl"
              {...register('postcode')}
            />
            <Feedback>{errors.postcode?.message}</Feedback>
          </FormControl>
          <FormControl id="city" isInvalid={errors.city} isDisabled={isSend}>
            <Input type="text" placeholder="Stadt" {...register('city')} />
            <Feedback>{errors.city?.message}</Feedback>
          </FormControl>
          <FormControl id="notes" isDisabled={isSend}>
            <Label>Möchten Sie uns noch etwas mitteilen?</Label>
            <Textarea
              type="text"
              placeholder="Mitteilung"
              {...register('notes')}
            />
          </FormControl>
          <FormControl
            id="foreName"
            isInvalid={errors.foreName}
            isDisabled={isSend}
          >
            <Label isRequired>Vorname</Label>
            <Input
              type="text"
              placeholder="Vorname"
              {...register('foreName')}
            />
            <Feedback>{errors.foreName?.message}</Feedback>
          </FormControl>
          <FormControl
            id="lastName"
            isInvalid={errors.lastName}
            isDisabled={isSend}
          >
            <Label isRequired>Nachname</Label>
            <Input
              type="text"
              placeholder="Nachname"
              {...register('lastName')}
            />
            <Feedback>{errors.lastName?.message}</Feedback>
          </FormControl>
          <FormControl id="email" isInvalid={errors.email} isDisabled={isSend}>
            <Label isRequired>E-Mail</Label>
            <Input type="email" placeholder="E-Mail" {...register('email')} />
            <Feedback>{errors.email?.message}</Feedback>
          </FormControl>
          <FormControl
            id="telephone"
            isInvalid={errors.telephone}
            isDisabled={isSend}
          >
            <Label>Telefonnummer</Label>
            <Input
              type="tel"
              placeholder="Telefonnummer"
              {...register('telephone')}
            />
            <Feedback>{errors.telephone?.message}</Feedback>
          </FormControl>
          <Label mb="4">* Pflichtfelder</Label>
          <Button type="submit" isLoading={isSubmitting} isDisabled={isSend}>
            Senden
          </Button>
        </form>
      </Container>
    </Layout>
  );
};

export default VerkaufGlueckwunsch;

export const pageQuery = graphql`
  query VerkaufGlueckwunschQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: "verkauf-glueckwunsch" }) {
      contentful_id
      id
      title
      headline
      headlineColor
      subTitle
      indexable
      metaTitle
      metaDescription
      showPressLogos
      formwizard {
        position
        url
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      exitIntent {
        image {
          gatsbyImageData(layout: FIXED, width: 170)
          title
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        button {
          title
          url
          color
          size
          shadow
        }
      }
      contentBlocks {
        title
        id
        entries {
          ... on ContentfulDownloadItem {
            internal {
              type
            }
            id
            title
            file {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulDownloadList {
            internal {
              type
            }
            id
            title
            downloadItems {
              internal {
                type
              }
              title
              file {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulList {
            internal {
              type
            }
            id
            listItems {
              internal {
                type
              }
              children {
                id
              }
              title
              id
              icon
              image {
                gatsbyImageData(layout: FIXED, width: 170)
                title
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            title
            template
          }
          ... on ContentfulListItem {
            internal {
              type
            }
            children {
              id
            }
            image {
              gatsbyImageData(layout: FIXED, width: 170)
              title
            }
            title
            id
            icon
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulText {
            internal {
              type
            }
            id
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulTextWithImage {
            internal {
              type
            }
            id
            title
            template
            imageAlignment
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 800)
              title
            }
          }
          ... on ContentfulFaqList {
            internal {
              type
            }
            id
            faqItems {
              internal {
                type
              }
              id
              answer {
                childMarkdownRemark {
                  html
                }
              }
              question
            }
          }
          ... on ContentfulButton {
            ...ContentfulButtonFragment
          }
          ... on ContentfulFormwizard {
            internal {
              type
            }
            id
            position
            title
            url
            variant
          }
        }
      }
      heroImage {
        gatsbyImageData(layout: FIXED, width: 1920, height: 690)
        title
      }
    }
  }
`;
