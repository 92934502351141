import { Box, Image } from '@hausgold/designsystem';
import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import WizardCardFooterList from './WizardCardFooterList';

/**
 * This is a card component which is used as a wrapper for our formwizard.
 *
 * @param children {ReactNode}
 * @param maxWidth {String}
 * @param footerLogo {String}
 * @param noCenter {Boolean}
 *
 */
const WizardCard = ({ children, maxWidth, footerLogo, noCenter }) => (
  <Row>
    <Col className="text-center">
      <Box
        id="wizard"
        mx={noCenter ? 0 : 'auto'}
        className="card shadow"
        style={{ maxWidth: maxWidth || '728px' }}
      >
        <Box p="0" className="card-body">
          {children}
        </Box>
        <Box className="card-footer">
          {footerLogo ? <Image src={footerLogo} /> : <WizardCardFooterList />}
        </Box>
      </Box>
    </Col>
  </Row>
);

WizardCard.defaultProps = {
  noCenter: false,
  footerLogo: null,
  maxWidth: null,
};

WizardCard.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  footerLogo: PropTypes.string,
  noCenter: PropTypes.bool,
};

export default WizardCard;
