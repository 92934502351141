import React from 'react';
import { CardDeck, Card, CardTitle, CardBody } from 'reactstrap';
import get from 'lodash/get';
import Icon from './Icon';

// CardList creates cards with icon, headline, text to demonstrate for example flow/process steps
const CardList = ({ listItems, iconColor, iconBackground }) => (
  <CardDeck>
    {listItems.map((item) => (
      <Card key={item.id} className="card shadow mb-3 text-center mw-200">
        <CardBody>
          <Icon
            name={item.icon}
            size={50}
            className={`rounded-circle ${iconBackground}`}
            color={iconColor}
            style={{
              width: '50px',
              height: '50px',
              padding: '10px',
              marginBottom: '9px',
            }}
          />
          <CardTitle tag="h5">{item.title}</CardTitle>
          <div
            className="body markdown"
            dangerouslySetInnerHTML={{
              __html: get(item, 'text.childMarkdownRemark.html'),
            }}
          />
        </CardBody>
      </Card>
    ))}
  </CardDeck>
);

export default CardList;
