import Cookies from 'js-cookie';
import { useMemo } from 'react';

/**
 * `leadId` retrieval logic
 *
 * Priority:
 *   1. Parameter `lkey`
 *   2. Parameter `leadid`
 *   3. Cookie `lkey`
 * parameter `lkey`, `leadid` or the cookie `lkey`.
 * @return {unknown}
 */
const useLeadId = (url) => {
  /**
   * Look on everything after '?' and split it into an array at every '&'.
   * @type array
   */
  const parameters = useMemo(() => url?.split('?')[1]?.split('&') || [], [url]);

  return useMemo(() => {
    // Cookies & parameter
    const keyCookie = Cookies.get('lkey');
    const caseInsensitiveParameters = {};

    /*
     * This iterates over every query parameter and lowercases the keys.
     * The reason for this is to make the `leadid` parameter case-insensitive.
     * By default, query params are case-sensitive.
     */
    parameters.forEach((keyValue) => {
      const [key, value] = keyValue.split('=');

      caseInsensitiveParameters[key.toLowerCase()] = value;
    });

    // Get's the leadId parameter, either as `lkey` or `leadid`.
    const { lkey, leadid } = caseInsensitiveParameters;

    return lkey || leadid || keyCookie;
  }, [parameters]);
};

export default useLeadId;
