import React from 'react';
import { Container, Row } from 'reactstrap';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import ContentfulTextWithImage from './ContentfulTextWithImage';
import ContentfulList from './ContentfulList';
import ContentfulListItem from './ContentfulListItem';
import ContentfulEntryFallback from './ContentfulEntryFallback';
import ContentfulDownloadItem from './ContentfulDownloadItem';
import ContentfulDownloadList from './ContentfulDownloadList';
import ContentfulText from './ContentfulText';
import ContentfulButton from './ContentfulButton';
import ContentfulFaqList from './ContentfulFaqList';
import ContentfulFormwizard from './ContentfulFormwizard';
import TableOfContents from './TableOfContents';
import ContentBreaker from './ContentfulContentBreaker';
import ContentfulMedia from './ContentfulMedia';

function createEntriesWithPositionalData(block) {
  const { tocPosition, heroImagePosition } = block;

  const allEntries = block.entries.map((entry) => ({
    key: 'entry',
    content: entry,
  }));

  const tocPositionIndexable = tocPosition - 1;
  const heroImagePositionIndexable =
    heroImagePosition === null ? 1 : heroImagePosition - 1; // If null then index will be 1 but actual location/position will be 2

  allEntries.splice(tocPositionIndexable, 0, {
    key: 'tableOfContent',
    content: block.entries,
  });

  allEntries.splice(heroImagePositionIndexable, 0, {
    key: 'heroImage',
    content: block?.heroImage || null,
  });

  return allEntries;
}

const ContentBlock = ({
  block,
  inverted,
  id,
  centerContent,
  narrow,
  tag,
  title,
  location,
  source = 'article',
}) => {
  if (!block.entries) {
    return null;
  }

  const contentMap = {
    ContentfulTextWithImage,
    ContentfulList,
    ContentfulListItem,
    ContentfulDownloadItem,
    ContentfulDownloadList,
    ContentfulText,
    ContentfulButton,
    ContentfulFaqList,
    ContentfulFormwizard,
    ContentfulMedia,
    default: ContentfulEntryFallback,
    ContentfulContentBreaker: ContentBreaker,
  };
  const Wrapper = tag || Container;
  const headline = <h2 className="w-100 text-center mb-6">{title}</h2>;

  const { tocPosition } = block;
  const additionalProps = {
    source,
  };

  return (
    <Wrapper
      id={id}
      className={cn({ 'container-narrow': narrow, 'text-white': inverted })}
    >
      <Row>
        {title && headline}
        {createEntriesWithPositionalData(block).map(({ key, content }) => {
          /**
           * This code block handles the rendering of the TableOfContents component
           * for the 'tableOfContent' key when the content array is not empty.
           */
          if (tocPosition && key === 'tableOfContent' && content.length > 0)
            return (
              <div key={Math.random()} className="col-12 mb-3">
                <TableOfContents rawContent={content} position={tocPosition} />
              </div>
            );

          /**
           * This code block handles the rendering of the GatsbyImage component for the
           * 'heroImage' key when content is not null or undefined.
           */
          if (key === 'heroImage' && !!content) {
            return (
              <div key={Math.random()} className="col-12 mb-3">
                <GatsbyImage
                  image={{
                    ...content.gatsbyImageData,
                    layout: 'fullWidth',
                  }}
                  alt={content.description}
                />
              </div>
            );
          }

          /**
           * This code block handles the rendering of the Component for the 'entry' key
           * using the contentMap object to dynamically determine the Component based on
           * the content.internal.type property.
           *
           * Note: If a ContentBlock has exactly 2 ContentfulList or 2 ContentfulText entries,
           * then it will be displayed in two columns.
           * If this is not desired, then less/more than 2 must be used or
           * it must be split to another ContentBlock.
           */
          if (key === 'entry') {
            const contentType = content.internal?.type || 'default';
            const Component = contentMap[contentType];

            const shouldBeTwoColumned =
              (contentType === 'ContentfulList' &&
                block.entries.length === 2) ||
              (contentType === 'ContentfulText' &&
                block.entries.filter(
                  (unfilteredEntry) =>
                    unfilteredEntry?.internal?.type === 'ContentfulText'
                ).length === 2);

            return (
              <div
                key={content.id}
                className={cn(`${contentType} col-12 mb-3`, {
                  'col-lg-6': shouldBeTwoColumned && !narrow,
                })}
              >
                <Component
                  entry={content}
                  inverted={inverted}
                  centerContent={centerContent}
                  location={location}
                  {...additionalProps}
                />
              </div>
            );
          }

          return null;
        })}
      </Row>
    </Wrapper>
  );
};

export default ContentBlock;
