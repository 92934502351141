import React, { useEffect } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import trustpilot from '../images/trustpilot.png';
import ContentfulButton from './ContentfulButton';

const ExitIntentModal = ({ show, setShowPopup, image, text, button }) => {
  useEffect(() => {
    if (show) {
      const expiryDate = new Date(Date.now() + 14 * (1000 * 60 * 60 * 24));
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      document.cookie = `modal_seen=true; path=/; expires='${expiryDate.toUTCString()}`;
      window?.analytics?.track('exit_intent_valuation', {
        category: 'content_engagement',
        label: 'pop_up_shown',
      });
    }

    setShowPopup(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const toggle = () => {
    setShowPopup(!show);
  };

  const closeBtn = (
    <button
      type="button"
      className="close position-absolute"
      style={{ top: '15px', right: '25px', zIndex: '9999' }}
      aria-label="Close"
      onClick={(e) => {
        e.preventDefault();
        toggle();
        window?.analytics?.track('exit_intent_valuation', {
          category: 'content_engagement',
          label: 'pop_up_close',
        });
      }}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  );

  return (
    <div>
      <Modal isOpen={show}>
        <ModalBody>
          {closeBtn}
          <Row>
            <Col sm={4}>
              {image && (
                <GatsbyImage
                  className="mt-5"
                  fadeIn={false}
                  loading="eager"
                  image={getImage(image)}
                  alt={image.title}
                />
              )}
            </Col>
            <Col sm={8}>
              <div
                className="body markdown mb-4"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
              <img
                src={trustpilot}
                alt="trustpilot"
                style={{ maxHeight: '65px' }}
              />
              <ContentfulButton
                entry={button}
                className="float-right mt-4 mb-4"
                eventCategory="content_engagement"
                eventAction="exit_intent_valuation"
                eventLabel="pop_up_click_to_valuation"
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ExitIntentModal;
