import { useState, useEffect } from 'react';

const useScrollHandler = () => {
  // Setting initial value to true
  const [scroll, setScroll] = useState(1);

  // Running on mount
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY < 10;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    // Setting the event handler from web API
    document.addEventListener('scroll', onScroll);

    // Call once to get initial scroll
    onScroll();

    // Cleaning up from the web API
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll]);

  return scroll;
};

export default useScrollHandler;
