/**
 * Extract param `dialogId` from URL.
 * @returns {{dialogId: (""|*)}}
 */
const useUrlParams = (url) => {
  /**
   * Default object
   */
  const params = {
    dialogId: null,
    variant: 'default',
  };

  /**
   * Split URL into parts
   */
  const parts = url.split('/');

  /**
   * Get "formular" from url cause this key can be used
   * as an anchor for variant and dialogId determination
   */
  const formIndex = parts.indexOf('formular');
  const isVariant = url.indexOf('/v/') >= 0;

  /**
   * Variant and default url are not equal so we have to
   * get our parameters differently
   */
  if (isVariant) {
    params.variant = parts[formIndex - 1];
  }

  params.dialogId = parts[formIndex + 1];

  /**
   * Return parameters
   */
  return params;
};

export default useUrlParams;
