import React from 'react';
import { Icon } from '@chakra-ui/react';

/**
 * HomeIcon
 * @param {import('@chakra-ui/react').IconProps} props
 */
export const HomeIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.12 37.8C40 36.42 40.5 34.76 40.5 33C40.5 28 36.5 24 31.5 24C26.5 24 22.5 28 22.5 33C22.5 38 26.5 42 31.5 42C33.24 42 34.88 41.5 36.26 40.64L42.5 46.78L45.28 44L39.12 37.8ZM31.5 38C28.74 38 26.5 35.76 26.5 33C26.5 30.24 28.74 28 31.5 28C34.26 28 36.5 30.24 36.5 33C36.5 35.76 34.26 38 31.5 38ZM10.5 40V24H4.5L24.5 6L44.5 24H40.86C39.16 22.22 36.96 20.94 34.5 20.36L24.5 11.38L14.5 20.38V36H18.86C19.2 37.44 19.78 38.78 20.56 40H10.5Z"
      fill="currentColor"
    />
  </Icon>
);

/**
 * HandshakeIcon
 * @param {import('@chakra-ui/react').IconProps} props
 */
export const HandshakeIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 11.9999H28.5L35.08 5.39995C35.2659 5.21249 35.4871 5.0637 35.7308 4.96216C35.9746 4.86063 36.236 4.80835 36.5 4.80835C36.764 4.80835 37.0254 4.86063 37.2691 4.96216C37.5129 5.0637 37.7341 5.21249 37.92 5.39995L43.08 10.5799C43.4525 10.9547 43.6616 11.4616 43.6616 11.9899C43.6616 12.5183 43.4525 13.0252 43.08 13.3999L38.5 17.9999H22.5V21.9999C22.5 22.5304 22.2893 23.0391 21.9142 23.4142C21.5391 23.7892 21.0304 23.9999 20.5 23.9999C19.9696 23.9999 19.4609 23.7892 19.0858 23.4142C18.7107 23.0391 18.5 22.5304 18.5 21.9999V15.9999C18.5 14.9391 18.9214 13.9217 19.6716 13.1715C20.4217 12.4214 21.4391 11.9999 22.5 11.9999ZM10.5 21.9999V29.9999L5.91999 34.5799C5.54749 34.9547 5.33841 35.4616 5.33841 35.9899C5.33841 36.5183 5.54749 37.0252 5.91999 37.3999L11.08 42.5799C11.2659 42.7674 11.4871 42.9162 11.7308 43.0177C11.9746 43.1193 12.236 43.1715 12.5 43.1715C12.764 43.1715 13.0254 43.1193 13.2691 43.0177C13.5129 42.9162 13.7341 42.7674 13.92 42.5799L22.5 33.9999H30.5C31.0304 33.9999 31.5391 33.7892 31.9142 33.4142C32.2893 33.0391 32.5 32.5304 32.5 31.9999V29.9999H34.5C35.0304 29.9999 35.5391 29.7892 35.9142 29.4142C36.2893 29.0391 36.5 28.5304 36.5 27.9999V25.9999H38.5C39.0304 25.9999 39.5391 25.7892 39.9142 25.4142C40.2893 25.0391 40.5 24.5304 40.5 23.9999V21.9999H26.5V23.9999C26.5 25.0608 26.0786 26.0782 25.3284 26.8284C24.5783 27.5785 23.5609 27.9999 22.5 27.9999H18.5C17.4391 27.9999 16.4217 27.5785 15.6716 26.8284C14.9214 26.0782 14.5 25.0608 14.5 23.9999V17.9999L10.5 21.9999Z"
      fill="currentColor"
    />
  </Icon>
);

/**
 * Starter Icon
 * @param {import('@chakra-ui/react').IconProps} props
 */
export const StarterIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6854 42L23.7788 35.1709C26.5784 34.1367 29.1996 32.7459 31.6247 31.1233L26.6854 42ZM13.3295 24.7221L6.5 21.8158L17.3773 16.8767C15.7546 19.3016 14.3637 21.9227 13.3295 24.7221ZM37.5448 9.56612C38.0441 9.56612 38.4899 9.56612 38.8644 9.65528C39.1675 12.1337 38.8287 17.2333 32.9799 23.0817C29.9486 26.1308 26.3288 28.4666 22.2631 30.0178L18.4293 26.2734C20.0699 22.1545 22.4058 18.5349 25.4193 15.5215C30.3409 10.6003 34.7274 9.56612 37.5448 9.56612ZM37.5448 6C34.0142 6 28.6647 7.23031 22.8873 13.0074C18.9821 16.9123 16.6462 21.2095 15.1305 24.9718C14.6312 26.3091 14.97 27.7712 15.9508 28.7697L19.7489 32.5498C20.4265 33.2273 21.3359 33.6374 22.281 33.6374C22.6911 33.6374 23.1191 33.5305 23.5292 33.37C27.3452 31.9079 31.5891 29.5186 35.4942 25.6137C45.5869 15.5215 41.8066 6.69539 41.8066 6.69539C41.8066 6.69539 40.1839 6 37.5448 6ZM29.1996 19.3016C27.8088 17.9108 27.8088 15.6464 29.1996 14.2556C30.5905 12.8648 32.8551 12.8648 34.246 14.2556C35.619 15.6464 35.6369 17.9108 34.246 19.3016C32.8551 20.6924 30.5905 20.6924 29.1996 19.3016ZM14.3994 41.6612L20.8901 35.1709C20.2838 35.0104 19.6954 34.7429 19.1604 34.3685L11.8851 41.6612H14.3994ZM6.8388 41.6612H9.35306L17.8587 33.1738L15.3266 30.6597L6.8388 39.1471V41.6612ZM6.8388 36.6152L14.1319 29.3403C13.7575 28.8053 13.49 28.2348 13.3295 27.6107L6.8388 34.101V36.6152Z"
      fill="currentColor"
    />
  </Icon>
);

/**
 * FaceAgentIcon
 * @param {import('@chakra-ui/react').IconProps} props
 */
export const FaceAgentIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.94 29.52C38.64 27.82 39.02 26 39.02 24C39.02 22.56 38.8 21.18 38.42 19.9C37.12 20.2 35.76 20.36 34.34 20.36C28.22 20.36 22.8 17.34 19.5 12.68C17.72 17 14.32 20.52 10.04 22.44C9.96 22.94 9.96 23.48 9.96 24C9.96 27.8563 11.4919 31.5546 14.2187 34.2814C16.9454 37.0081 20.6438 38.54 24.5 38.54C26.6 38.54 28.62 38.08 30.44 37.26C31.58 39.44 32.1 40.52 32.06 40.52C28.78 41.62 26.24 42.16 24.5 42.16C19.66 42.16 15.04 40.26 11.64 36.84C9.56 34.76 8.02 32.22 7.16 29.46H4.5V20.36H6.68C8.36 12.08 15.7 5.84003 24.5 5.84003C29.3 5.84003 33.92 7.74003 37.34 11.16C39.88 13.68 41.58 16.9 42.28 20.36H44.5V29.34V29.38V29.46H44.38L37.26 36L26.66 34.8V31.46H36.32L37.94 29.52ZM19.04 23.54C19.64 23.54 20.22 23.78 20.64 24.22C21.06 24.64 21.3 25.22 21.3 25.82C21.3 26.42 21.06 27 20.64 27.42C20.22 27.84 19.64 28.08 19.04 28.08C17.78 28.08 16.76 27.08 16.76 25.82C16.76 24.56 17.78 23.54 19.04 23.54ZM29.94 23.54C31.2 23.54 32.2 24.56 32.2 25.82C32.2 27.08 31.2 28.08 29.94 28.08C28.68 28.08 27.66 27.08 27.66 25.82C27.66 25.2153 27.9002 24.6354 28.3278 24.2078C28.7554 23.7802 29.3353 23.54 29.94 23.54Z"
      fill="currentColor"
    />
  </Icon>
);
