import React, { Fragment } from 'react';
import { Box } from '@hausgold/designsystem';
import Helmet from 'react-helmet';
import Formwizard from '../../components/Formwizard';
import Link from '../../components/Link';
import SEO from '../../components/SEO';

/**
 * Renders a text with image.
 * The image can be left or right sided on desktop and is on top on mobile.
 * @param title
 * @param text
 * @param position (left|right)
 * @param image
 * @returns {JSX.Element}
 */
const stepCard = ({ title, text, position = 'left', image }) => (
  <>
    <Box position="relative" mt={{ base: '32px', md: '64px' }}>
      <Box
        position={{ md: 'absolute' }}
        top="16px"
        left={position === 'left' ? '89px' : '512px'}
        width={{ base: '287px', md: '263px' }}
        mx={{ base: 'auto', md: '0px' }}
      >
        <img
          className="w-100"
          src={`/legacy/assets/immobilienwert-rechner/${image}.jpeg`}
        />
      </Box>
    </Box>
    <Box
      color="white"
      bg="rgba(111,111,111)"
      maxWidth={{ base: '287px', md: '513px' }}
      height={{ md: '174px' }}
      ml={{ base: 'auto', md: position === 'left' ? '264px' : '89px' }}
      mr={{ base: 'auto', md: '0px' }}
    >
      <Box
        pt="8px"
        pb={{ base: '24px', md: '0px' }}
        px={{ base: '16px', md: '0px' }}
        ml={{ md: position === 'left' ? '111px' : '40px' }}
        maxWidth="361px"
      >
        <Box fontWeight="bolder" fontSize="24px">
          {title}
        </Box>
        <Box fontSize="18px">{text}</Box>
      </Box>
    </Box>
  </>
);

/**
 * This landingpage is a rough port from https://ub.hausgold.de/die-deutsche-immobilienberatung/
 * @param location
 * @returns {JSX.Element}
 * @constructor
 */
const ImmobilienwertRechner = ({ location }) => (
  <Box
    // Bottonm has white space for nor reason
    mb="-25px"
    mx="-8px"
    mt="-61.44px"
  >
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="/legacy/newlegacylayout.css"
      />
    </Helmet>
    <SEO
      title="Die Deutsche Immobilienberatung"
      type="page"
      indexable={false}
    />
    <Box bg={{ md: 'rgba(111,111,111,1)' }} fontFamily="Arial, sans-serif">
      <Box height="60px" bg="rgba(0,0,0,0.5)" className="lp-ub__header">
        <Box mx="auto" maxWidth="864px" color="rgb(255, 255, 255)">
          <Box
            pt={{ base: '14px', md: '27px' }}
            pl={{ base: '0px', md: '35px' }}
            textAlign={{ base: 'center', md: 'inherit' }}
            fontWeight="bolder"
            fontSize={{ base: '16px', sm: '20px' }}
          >
            Die Deutsche Immobilienberatung
          </Box>
        </Box>
      </Box>
      <Box
        className="lp-ub__title"
        height={{ md: '74px' }}
        bg="white"
        display="flex"
        alignItems="center"
      >
        <Box mx="auto" maxWidth="864px" width="100%" color="rgb(255, 255, 255)">
          <Box
            fontWeight={{ base: 'normal', md: 'bolder' }}
            fontSize={{ base: '26px', md: '24px' }}
            ml={{ base: '27.5px', md: '0px' }}
            pl={{ base: '0px', md: '35px' }}
            mr={{ base: '27.5px', md: '0px' }}
            color="black"
            textAlign={{ base: 'center', md: 'inherit' }}
            fontFamily={{
              base: 'Arial, sans-serif',
              md: 'Verdana, Geneva, sans-serif',
            }}
          >
            Deutschland vertraut auf diesen Immobilienwert-Rechner
          </Box>
        </Box>
      </Box>
      <Box
        mx="auto"
        maxWidth="864px"
        color="rgb(0, 0, 0)"
        className="lp-ub__body"
      >
        <Box bg="white">
          <img
            className="px-6 px-md-0"
            width="100%"
            height="auto"
            src="/legacy/assets/immobilienwert-rechner/banner.png"
          />
          <Box mt="8px" px="32px">
            Sensation für Immobilienbesitzer!! Endlich gibt es den
            Immobilienwert-Rechner von HAUSGOLD: Jetzt kann jeder Eigentümer
            seine Immobilie kostenlos und professionell bewerten
          </Box>
          <Box
            mt={{ base: '16px', md: '32px' }}
            fontSize="24px"
            fontWeight="bolder"
            px="32px"
          >
            Welche 3 brillianten Vorteile hat der neue Immobilienwert-Rechner?
          </Box>

          <div className="lp_ub__cards">
            {stepCard({
              title: '1.',
              text: 'Sie erfahren den aktuellen Wert Ihrer Immobilie zu jeder Zeit kostenlos und unverbindlich.',
              postion: 'left,',
              image: 'stock_1',
            })}
            {stepCard({
              title: '2.',
              text: 'Mit dem HAUSGOLD-Rechner sind Sie auf der sicheren Seite: 3 professionelle Bewertungen von Experten zeigen Ihnen, wie wertvoll Ihre Immobilie wirklich ist.',
              position: 'right',
              image: 'stock_2',
            })}
            {stepCard({
              title: '3.',
              text: 'Durch die Experten von HAUSGOLD bleiben Sie immer auf dem aktuellen Stand und erfahren jederzeit, wie auch Sie vom Immobilienboom profitieren können.',
              position: 'left',
              image: 'stock_3',
            })}
          </div>

          <Box
            maxWidth={{ base: '287px', md: '778px' }}
            ml={{ base: 'auto', md: '51px' }}
            mr={{ base: 'auto', md: '0px' }}
            textAlign={{ base: 'center', md: 'inherit' }}
            mt="64px"
            mb="16px"
          >
            Der HAUSGOLD-Rechner ist der absolute Hammer –{' '}
            {new Date().getFullYear()} brechen goldene Zeiten für den
            Immobilienbesitzer an!
          </Box>
          <Box
            fontSize="22px"
            fontWeight="bolder"
            textAlign="center"
            mb="16px"
            mx={{ base: 'auto', md: '0px' }}
            maxWidth={{ base: '287px', md: 'inherit' }}
          >
            Jetzt kostenlos Wert ermitteln
          </Box>
          <Formwizard
            position="header"
            location={location}
            url="https://formwizard.hausgold.de/formular/icon-test"
          />
        </Box>
      </Box>

      <Box as="footer" height="53px" width="100%" bg="rgba(0,0,0,0.5)">
        <Box maxWidth="864px" mx="auto">
          <Box ml="16px" pt="16px">
            <Link to="/datenschutz">
              <Box as="span" color="white">
                Datenschutz
              </Box>
            </Link>
            <Box as="span" color="white">
              &nbsp;|&nbsp;
            </Box>
            <Link to="/impressum">
              <Box as="span" color="white">
                Impressum
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default ImmobilienwertRechner;
