import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionHeading,
  AccordionPanel,
  AccordionArrow,
  AccordionSquareIcon,
  List,
  ListItem,
  Link,
  Divider,
  Button,
  Box,
  useBreakpointValue,
} from '@hausgold/designsystem';

import { UnorderedList, ChevronUp } from '@hausgold/designsystem/icons';

/**
 * Create a toc out of all given (text) entries by filter h2 headlines.
 */
const TableOfContents = ({ rawContent, position = 0 }) => {
  // On Desktop start with TOC expanded; on mobile start with TOC collapsed
  const defaultIndex = useBreakpointValue(
    { base: null, md: 0 },
    { fallback: 'base', ssr: true }
  );

  const [index, setIndex] = useState(defaultIndex);

  /*
   * Update the default index
   *
   * This is necessary because the default index is not updated on the first
   * render.
   */
  useEffect(() => {
    setIndex(defaultIndex);
  }, [defaultIndex]);

  /*
   * Extract all related headlines and their href links from text data array.
   * Raw text looks like: <h2><a href="link"></a>text</h2>....
   */
  const headlines = rawContent
    /*
     * Ignore content which is "above" the toc.
     * If position is 0 (which is equal to position 1 here) we pass the whole contentblock object as we are on top of all.
     */
    .slice(position && position - 1)
    // Format content
    .map((entry) => ({
      // Get headline by filter text between </a> and </h2>.
      text: entry?.text?.childMarkdownRemark?.html
        .match(/<\/a>.*<\/h2>/)?.[0]
        .replace(/(^<\/a>)|(<\/h2>$)/g, ''),
      // Get link by filter text between <a href=" and ".
      link: entry?.text?.childMarkdownRemark?.html
        .match(/<a href="[^"]*/)?.[0]
        .replace(/^<a href="/, ''),
    }))
    // Remove entries which are not complete
    .filter(({ text, link }) => text && link);

  return (
    <Accordion
      colorScheme="gray"
      index={index}
      defaultIndex={defaultIndex}
      // Overwrite the default index with the user's choice.
      onChange={(_index) => setIndex(_index)}
      my={4}
    >
      <AccordionItem bg="gray.100">
        <AccordionButton border="0 none" outline="none" bg="gray.100">
          <AccordionSquareIcon bg="gray.100" color="gray.500">
            <UnorderedList />
          </AccordionSquareIcon>
          <AccordionHeading fontSize="lg" pt={0} mb={0}>
            Inhaltsverzeichnis
          </AccordionHeading>
          <AccordionArrow color="blue.400" />
        </AccordionButton>
        <AccordionPanel pb={3}>
          <List spacing={1} pl={0}>
            {headlines.map(({ text, link }, _index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={`toc_entry_${_index}`}>
                <Link href={link} fontSize="md">
                  {text}
                </Link>
              </ListItem>
            ))}
          </List>
          <Divider mt={4} mb={3} />
          <Box textAlign="center">
            <Button
              onClick={() => {
                setIndex(null);
              }}
              variant="ghost"
              p={0}
              fontSize="sm"
              fontWeight="semibold"
            >
              <ChevronUp mr={2} />
              Verbergen
            </Button>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default TableOfContents;
