import React from 'react';
import { Container } from 'reactstrap';
import { Image, AspectRatio, Text, SimpleGrid } from '@hausgold/designsystem';
import Section from '../../Section';
import JennaImage from './image.png';

const Jenna = () => (
  <Section textAlign="center">
    <Container>
      <SimpleGrid
        justifyItems="center"
        alignItems="center"
        columns={{ base: 1, md: 2 }}
      >
        <AspectRatio
          width="200px"
          height="200px"
          overflow="hidden"
          ratio={1 / 1}
          margin="0 auto"
          borderRadius="circle"
          background="radial-gradient(141.42% 141.42% at 0% 100%, #0B87DB 33.22%, #E5F0F8 100%)"
        >
          <Image src={JennaImage} maxW="100%" display="block" />
        </AspectRatio>

        <div>
          <Text fontSize="lg" mt={8}>
            “Unser oberstes Ziel ist es, täglich die passenden Kunden für unsere
            Makler in Ihrer Region zu finden. Durch gezieltes Online- und
            Offline-Marketing übernehmen wir die Generierung neuer
            Eigentümerkontakte, um den Maklern eine effiziente und erfolgreiche
            Arbeitsweise zu ermöglichen.”
          </Text>
          <Text>
            Jenna Overhageböck,
            <br /> Head of Marketing
          </Text>
        </div>
      </SimpleGrid>
    </Container>
  </Section>
);

export default Jenna;
