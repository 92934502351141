import { Icon } from '@chakra-ui/react';
import React from 'react';

const NewspaperIcon = (props) => (
  <Icon viewBox="0 0 16 15" {...props}>
    <path
      d="M14 2.20044V12.7004H2V2.20044H14ZM14 0.700439H2C1.1675 0.700439 0.5 1.36794 0.5 2.20044V12.7004C0.5 13.5329 1.1675 14.2004 2 14.2004H14C14.8325 14.2004 15.5 13.5329 15.5 12.7004V2.20044C15.5 1.36794 14.8325 0.700439 14 0.700439ZM12.5 9.70044H3.5V11.2004H12.5V9.70044ZM6.5 3.70044H3.5V8.20044H6.5V3.70044ZM8 5.20044H12.5V3.70044H8V5.20044ZM12.5 6.70044H8V8.20044H12.5V6.70044Z"
      fill="currentColor" // This allows the icon color to be controlled via the `color` prop
    />
  </Icon>
);

export default NewspaperIcon;
