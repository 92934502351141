import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Header from '../components/Header';
import ContentfulList from '../components/ContentfulList';
import ArticlePreview from '../components/ArticlePreview';
import SEO from '../components/SEO';

// This template shows the profile of an expert
const ExpertTemplate = ({ data, location }) => {
  const expert = get(data, 'contentfulPerson');
  const social = get(expert, 'socialMedia');
  const articles = get(data, 'allContentfulArticle.edges');

  // // TODO: Do not use this fuzzy fake stuff
  const listItems = [];
  if (social?.facebook) {
    listItems.push({
      id: 'f7ee3d02-d64d-5ebe-914e-31ff2e3ccbc0',
      icon: 'facebook',
      link: social.facebook,
      template: 'icon-link',
    });
  }

  if (social?.instagram) {
    listItems.push({
      id: '41722ebd-d51f-5372-b242-4c998cc69fcf',
      icon: 'instagram',
      link: social.instagram,
      template: 'icon-link',
    });
  }

  if (social?.twitter) {
    listItems.push({
      id: '81fc5500-6ce5-5717-be08-d4cba5a83494',
      icon: 'twitter',
      link: social.twitter,
      template: 'icon-link',
    });
  }

  if (social?.website) {
    listItems.push({
      id: '81fc5500-6ce5-5717-be08-d4cba5a83495',
      icon: 'web',
      link: social.website,
      template: 'icon-link',
    });
  }

  return (
    <Layout location={location} cookieConsent header={<Header />}>
      <Helmet>
        <meta name="content-entity" content="person" />
        <meta name="content-id" content={expert.contentful_id} />
      </Helmet>
      <SEO
        title={`${expert.firstName} ${expert.lastName}`}
        indexable={expert.indexable}
      />
      <Container className="mt-6 mb-5">
        <Row>
          <Col xs={12} md={6}>
            {expert.image && (
              <GatsbyImage
                style={{ height: '100%' }}
                image={getImage(expert.image)}
                alt={expert.image.title}
              />
            )}
          </Col>

          <Col xs={12} md={6}>
            <div
              className={cn({
                'px-5 pl-md-0 pr-md-6':
                  expert.template === 'on Background' &&
                  expert.imageAlignment === 'left',
                'px-5 pl-md-6 pr-md-0':
                  expert.template === 'on Background' &&
                  expert.imageAlignment === 'right',
              })}
            >
              <h2>
                {expert.firstName} {expert.lastName}
              </h2>
              <h4>
                {expert.department} {expert.company}
              </h4>
              {expert.bio && (
                <div
                  className="body pb-5"
                  dangerouslySetInnerHTML={{
                    __html: expert.bio.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>

            {listItems && (
              <ContentfulList
                entry={{ listItems }}
                listClassname="d-flex align-items-center"
              />
            )}
          </Col>

          {!!articles.length && (
            <>
              <Col xs={12} className="mt-6">
                <h2>Veröffentlichte Artikel</h2>
              </Col>
              {articles.map(({ node }) => (
                <Col key={node.id} xs={12} md={6} lg={4}>
                  <ArticlePreview key={node.slug} article={node} />
                </Col>
              ))}
            </>
          )}
        </Row>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ExpertBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allContentfulArticle(filter: { author: { slug: { eq: $slug } } }) {
      edges {
        node {
          slug
          title
          heroImage {
            gatsbyImageData(
              height: 270
              width: 350
              cropFocus: FACES
              resizingBehavior: FILL
            )
            title
          }
          category {
            slug
          }
          description {
            childMarkdownRemark {
              excerpt(format: PLAIN, pruneLength: 250)
            }
          }
        }
      }
    }
    contentfulPerson(slug: { eq: $slug }) {
      contentful_id
      firstName
      lastName
      indexable
      company
      department
      email
      title
      socialMedia {
        facebook
        instagram
        twitter
        website
      }
      bio {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH, width: 800)
        title
      }
    }
  }
`;

export default ExpertTemplate;
