import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
} from 'reactstrap';
import { Formik, Form as FormikForm, Field } from 'formik';
import axios from 'axios';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';

const Kontakt = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const site = get(data, 'site');

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container>
        <Row>
          <Col xs="12" md="5">
            <Formik
              initialValues={{ name: '', email: '', message: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Bitte geben Sie Ihren Namen an.';
                }

                if (!values.message) {
                  errors.message = 'Bitte geben Sie einen Text ein.';
                }

                if (!values.email) {
                  errors.email = 'Bitte geben Sie eine E-Mail-Adresse an.';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email =
                    'Bitte geben Sie eine gültige E-Mail-Adresse an.';
                }

                return errors;
              }}
              onSubmit={async (values, { resetForm, setStatus }) => {
                axios
                  .post(
                    'https://getform.io/f/3feeb8d0-38d1-41c0-9f6c-a550c2372066',
                    values,
                    { headers: { Accept: 'application/json' } }
                  )
                  .then(() => {
                    resetForm();
                    setStatus({ success: true });
                    // ResetForm()
                  })
                  .catch(() => {
                    setStatus({ success: false });
                  });
              }}
            >
              {({ errors, isSubmitting, status }) => (
                <Form tag={FormikForm}>
                  {status && status.success && (
                    <Alert color="success">Danke für Ihre Nachricht.</Alert>
                  )}
                  {status && !status.success && (
                    <Alert color="danger">
                      Leider ist ein Fehler aufgetreten. Bitte senden Sie für
                      Ihre Anfrage eine E-Mail an presse@hausgold.de .
                    </Alert>
                  )}
                  <FormGroup>
                    <Label for="name">Ihr Name:</Label>
                    <Input
                      invalid={!!errors.name}
                      tag={Field}
                      type="text"
                      name="name"
                      id="name"
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Ihre E-Mail-Adresse:</Label>
                    <Input
                      invalid={!!errors.email}
                      tag={Field}
                      type="email"
                      name="email"
                      id="email"
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="message">Ihre Nachricht:</Label>
                    <Input
                      invalid={!!errors.message}
                      tag={Field}
                      component="textarea"
                      type="textarea"
                      name="message"
                      id="message"
                    />
                    <FormFeedback>{errors.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup check row>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Senden
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </Col>
          <Col xs="12" md={{ size: 6, offset: 1 }}>
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Kontakt;

export const pageQuery = graphql`
  query KontaktQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "kontakt" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
