import React from 'react';
import PropTypes from 'prop-types';

const OutboundLink = (props) => {
  const {
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    onClick,
    ...rest
  } = props;
  // These vars are used on <a/> as well as inside onClick()
  const { target, href } = rest;

  return (
    <a
      {...rest}
      onClick={(e) => {
        if (typeof onClick === `function`) {
          onClick(e);
        }

        let redirect = true;
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false;
        }

        if (target && target.toLowerCase() !== `_self`) {
          redirect = false;
        }

        if (window.analytics) {
          window.analytics(eventAction || `click`, {
            category: eventCategory || `Outbound Link`,
            label: eventLabel || href,
            eventValue,
            transport: redirect ? `beacon` : ``,
            hitCallback() {
              if (redirect) {
                document.location = href;
              }
            },
          });
        } else if (redirect) {
          document.location = href;
        }

        return false;
      }}
    />
  );
};

OutboundLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  eventCategory: PropTypes.string,
  eventAction: PropTypes.string,
  eventLabel: PropTypes.string,
  eventValue: PropTypes.number,
  onClick: PropTypes.func,
};

OutboundLink.defaultProps = {
  href: null,
  target: null,
  eventCategory: null,
  eventAction: null,
  eventLabel: null,
  eventValue: null,
  onClick: null,
};

export default OutboundLink;
