import { graphql } from 'gatsby';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { getSrc } from 'gatsby-plugin-image';
import { Divider, Heading, Stack, Box } from '@hausgold/designsystem';
import ContentBlock from '../components/ContentBlock';
import ContentfulFaqList from '../components/ContentfulFaqList';
import ExitIntent from '../components/ExitIntent';
import ExitIntentModal from '../components/ExitIntentModal';
import Formwizard from '../components/Formwizard';
import Header from '../components/Header';
import Layout from '../components/Layout';
import Link from '../components/Link';
import RelatedContent from '../components/RelatedContent';
import SEO from '../components/SEO';
import { collectFaqs } from '../utilities';
import AuthorBox from '../components/AuthorBox';

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const article = get(data, 'contentfulArticle');
  const site = get(data, 'site');
  const category = get(data, 'contentfulArticle.category');
  const formwizard = get(article, 'formwizard');
  const showFormWizard = get(article, 'showFormWizard') !== false; // Assign 'true' to 'showFormWizard' if 'article.showFormWizard' is 'null' or 'true', 'false' otherwise.
  const exitIntent = get(article, 'exitIntent');
  const formwizardPosition = get(formwizard, 'position') || 'right';
  const formwizardUrl = get(formwizard, 'url');
  const heroImage = get(article, 'heroImage');
  const faqList = get(article, 'faqList');
  const relatedContent = get(article, 'relatedArticles');
  const contentBlocks = get(article, 'contentBlocks');
  const [showPopup, setShowPopup] = useState(false);
  const imageSrc = getSrc(heroImage);

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 10,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowPopup(true);
      },
    });
    return () => {
      removeExitIntent();
    };
  });

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: category.title,
      url: `${site.siteMetadata.siteUrl}/${category.slug}/`,
    },
    {
      title: article.title,
      url: `${site.siteMetadata.siteUrl}/${category.slug}/${article.slug}/`,
    },
  ];

  const faqItems = collectFaqs({
    faq: faqList,
    contentBlocks,
  });

  return (
    <Layout
      location={location}
      cookieConsent
      contentStyles={{
        marginX: 'auto',
        maxW: showFormWizard ? 'inherit' : '960px',
      }}
      header={
        <Header
          size={formwizardPosition === 'header' ? 'lg' : 'md'}
          breadcrumbStyles={{
            marginX: 'auto',
            maxW: showFormWizard ? 'inherit' : '960px',
            px: showFormWizard ? 0 : { base: 0, xl: 4 },
          }}
          breadcrumb={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/ratgeber">Ratgeber</Link>
              </BreadcrumbItem>
              {category && (
                <BreadcrumbItem>
                  <Link to={`/${category.slug}`}>{category.title}</Link>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem active>{article.title}</BreadcrumbItem>
            </Breadcrumb>
          }
        >
          {showFormWizard && formwizardPosition === 'header' && (
            <Formwizard
              position={formwizardPosition}
              location={location}
              url={formwizardUrl}
            />
          )}
        </Header>
      }
    >
      <Helmet>
        <meta name="content-entity" content="article" />
        <meta name="content-id" content={article.contentful_id} />
      </Helmet>

      <SEO
        title={article.metaTitle || article.headline}
        canonical={article.canonical}
        type="article"
        entity={{
          ...article,
          imageSrc,
          updatedAt: pageContext.lastmoddate,
          publishDate: article?.createdAt || '',
        }}
        description={article.metaDescription}
        indexable={article.indexable}
        breadcrumbs={breadcrumbs}
        faqItems={faqItems}
      />

      <Container>
        <Row className="justify-content-between my-3">
          <Col
            xs="12"
            lg={!!showFormWizard && formwizardPosition === 'right' ? 7 : 12}
            className={`order-2 ${
              !!showFormWizard && formwizardPosition === 'right'
                ? 'order-lg-1'
                : 'order-md-2'
            }`}
          >
            <Stack spacing={0}>
              <Heading
                as="h1"
                fontSize={{ base: '2rem', md: '2.5rem' }}
                px="0"
                style={{ hyphens: 'auto' }}
                fontWeight="bold"
              >
                {article.title}
                <br />
                <Box
                  fontWeight="semibold"
                  as="span"
                  fontSize={{ base: '1rem', md: '1.5rem' }}
                >
                  {article.subTitle}
                </Box>
              </Heading>
            </Stack>
            {article.author && (
              <>
                <AuthorBox
                  title={article.author?.title}
                  name={`${article.author?.firstName} ${article.author?.lastName}`}
                  imageUrl={getSrc(article.author?.image)}
                  lastUpdate={article?.updatedAt}
                />
                <Divider />
              </>
            )}

            {article.description && (
              <div
                className="lead description"
                dangerouslySetInnerHTML={{
                  __html: article.description.childMarkdownRemark.html,
                }}
              />
            )}

            {article.body && (
              <div
                className="body markdown"
                dangerouslySetInnerHTML={{
                  __html: article.body.childMarkdownRemark.html,
                }}
              />
            )}

            {contentBlocks &&
              contentBlocks.map((block) => (
                <ContentBlock
                  key={block.id}
                  block={{ ...block, heroImage: article.heroImage }}
                  narrow={formwizardPosition !== 'right'}
                  tag="div"
                  source="article"
                  location={location}
                />
              ))}

            {article?.author && (
              <Stack my="4">
                <h2>
                  {article.author?.isMale
                    ? 'Über den Autor'
                    : 'Über die Autorin'}
                </h2>
                <AuthorBox
                  style={{
                    p: '5',
                    shadow: 'sm',
                  }}
                  title={article.author?.title}
                  name={`${article.author?.firstName} ${article.author?.lastName}`}
                  imageUrl={getSrc(article.author?.image)}
                  bio={article.author?.bio?.childMarkdownRemark?.html}
                  profileLink={`/experten/${article.author?.slug}`}
                  socialMediaLinks={article.author?.socialMedia}
                />
              </Stack>
            )}

            {relatedContent && <RelatedContent content={relatedContent} />}

            {faqList && (
              <>
                <h2>Häufig gestellte Fragen:</h2>
                <ContentfulFaqList entry={faqList} />
              </>
            )}
          </Col>

          {showFormWizard &&
            (formwizardPosition === 'right' ||
              formwizardPosition === 'top') && (
              <Col
                xs="12"
                lg={formwizardPosition === 'right' ? 5 : 12}
                xl={formwizardPosition === 'right' ? 4 : 12}
                className={`order-1 ${
                  formwizardPosition === 'right' ? 'order-lg-2' : 'order-md-1'
                }`}
              >
                {formwizardPosition && (
                  <Box mb={6}>
                    <Formwizard
                      position={formwizardPosition}
                      location={location}
                      url={
                        formwizardUrl ||
                        'https://formwizard.hausgold.de/v/highlighted/formular/content'
                      }
                    />
                  </Box>
                )}
              </Col>
            )}
        </Row>
      </Container>

      {exitIntent && (
        <ExitIntentModal
          show={showPopup}
          image={exitIntent.image}
          text={exitIntent.text.childMarkdownRemark.html}
          button={exitIntent.button}
          setShowPopup={setShowPopup}
        />
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulArticle(slug: { eq: $slug }) {
      contentful_id
      title
      subTitle
      metaTitle
      slug
      indexable
      metaDescription
      showFormWizard
      formwizard {
        position
        url
      }
      exitIntent {
        image {
          gatsbyImageData(layout: FIXED, width: 170)
          title
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        button {
          title
          url
          color
          size
          shadow
        }
      }
      author {
        id
        isMale
        lastName
        firstName
        company
        email
        title
        slug
        tag
        image {
          gatsbyImageData(layout: FULL_WIDTH, width: 800)
          title
        }
        bio {
          childMarkdownRemark {
            html
          }
        }
        freeText {
          childMarkdownRemark {
            html
          }
        }
        socialMedia {
          facebook
          instagram
          website
          twitter
          linkedin
          xing
        }
      }
      faqList {
        faqItems {
          id
          answer {
            childMarkdownRemark {
              html
            }
          }
          question
        }
      }
      heroImage {
        gatsbyImageData(layout: CONSTRAINED, width: 635)
        title
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      category {
        title
        slug
      }
      updatedAt
      createdAt
      body {
        childMarkdownRemark {
          html
        }
      }
      relatedArticles {
        ... on ContentfulArticle {
          id
          slug
          title
          metaDescription
          relatedArticlesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              height: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          category {
            slug
          }
          description {
            description
            id
            childMarkdownRemark {
              id
              html
            }
          }
        }

        ... on ContentfulPage {
          id
          slug
          title
          metaDescription
          relatedPagesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              height: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          page {
            id
            slug
            title
          }
        }
      }
      contentBlocks {
        title
        id
        tocPosition
        heroImagePosition
        entries {
          ... on ContentfulContentBreaker {
            internal {
              type
            }
            id
            headline1
            description
            linkLabel
            link
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          ... on ContentfulDownloadItem {
            id
            title
            internal {
              type
            }
            file {
              file {
                url
              }
              title
            }
            link
          }
          ... on ContentfulDownloadList {
            id
            title
            internal {
              type
            }
            downloadItems {
              title
              file {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulList {
            id
            internal {
              type
            }
            listItems {
              internal {
                type
              }
              children {
                id
              }
              title
              id
              icon

              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            title
          }
          ... on ContentfulListItem {
            internal {
              type
            }
            children {
              id
            }
            image {
              gatsbyImageData(layout: FIXED, width: 170)
              title
            }
            title
            id
            icon
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulText {
            id
            internal {
              type
            }
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulTextWithImage {
            id
            internal {
              type
            }
            title
            template
            imageAlignment
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 800)
              title
            }
          }
          ... on ContentfulFaqList {
            id
            internal {
              type
            }
            faqItems {
              internal {
                type
              }
              id
              answer {
                childMarkdownRemark {
                  html
                }
              }
              question
            }
          }
          ... on ContentfulButton {
            ...ContentfulButtonFragment
          }
          ... on ContentfulFormwizard {
            internal {
              type
            }
            id
            position
            title
            url
            variant
          }
          ... on ContentfulMedia {
            internal {
              type
            }
            id
            media_caption
            attachment {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
