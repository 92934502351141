import React from 'react';
import { Link, List, ListItem, Box, Flex } from '@hausgold/designsystem';
import Welt from './Icon/PressLogos/Welt';
import FrankfurterAllgemeine from './Icon/PressLogos/FrankfurterAllgemeine';
import Trustpilot from './Trustpilot';
import ZeitOnline from './Icon/PressLogos/ZeitOnline';
import FocusOnline from './Icon/PressLogos/FocusOnline';

const PressLogos = ({ showReviews }) => (
  <Flex flexDir="column" alignItems={{ base: 'center', md: 'start' }}>
    <Box mb={{ base: 6, md: 2 }}>
      Bekannt aus{' '}
      <a href="#press-release-dates">
        <sup>1</sup>
      </a>
      :
    </Box>
    <List
      margin="0"
      padding="0"
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      justifyContent="center"
      gap={{ base: 10, md: 10, lg: 16, xl: 20 }}
      maxW={{ base: '220px', md: 'none' }}
      mx="auto"
      className="press-logos"
    >
      <ListItem>
        <Link
          aria-label="Frankfurter Allgemeine Artikel"
          href="https://www.faz.net/aktuell/wirtschaft/viele-kunden-koennten-zurueckkommen-17814530.html"
          target="_blank"
        >
          <FrankfurterAllgemeine />
        </Link>
      </ListItem>
      <ListItem px={{ base: 12, md: 0 }} flexBasis="19%" overflow="hidden">
        <Link
          aria-label="Focus Online Artikel"
          href="https://www.focus.de/immobilien/experten/sebastian_wagner/experte-erklaert-wohnimmobilien-und-corona-warum-immobilienbesitzer-aufatmen-koennen_id_11974522.html"
          target="_blank"
        >
          <FocusOnline />
        </Link>
      </ListItem>
      <ListItem px={{ base: 4, md: 0 }} flexBasis="28%">
        <Link
          aria-label="WELT Artikel"
          href="https://www.welt.de/wirtschaft/plus240668693/Immobilien-Die-Luxus-Wette-ist-verloren-jetzt-beginnt-das-Zittern-der-Spekulanten.html"
          target="_blank"
        >
          <Welt />
        </Link>
      </ListItem>
      <ListItem>
        <Link
          aria-label="Zeit Online Artikel"
          href="https://www.zeit.de/wirtschaft/2023-06/immobilienpreise-energetische-sanierung-waermewende-wertverlust"
          target="_blank"
          display="inline-block"
        >
          <ZeitOnline />
        </Link>
      </ListItem>
      {showReviews && (
        <ListItem mt={{ base: 4, md: 0 }}>
          <Trustpilot />
        </ListItem>
      )}
    </List>
  </Flex>
);

export default PressLogos;
