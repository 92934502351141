import React from 'react';
import { Heading } from '@hausgold/designsystem';

/**
 * A section heading component.
 * @param {import('@hausgold/designsystem').HeadingProps} props
 */
const SectionHeading = (props) => (
  <Heading as="h3" size="h1" mb={6} {...props} />
);

export default SectionHeading;
