import React from 'react';
import { Button } from 'reactstrap';
import get from 'lodash/get';
import Link from './Link';

const CategoryPreview = ({ category }) => (
  <Link to={`/${category.slug}/`} style={{ minHeight: '100%' }}>
    <div
      className="rounded-lg"
      style={{
        minHeight: '300px',
        backgroundImage: `linear-gradient( rgba(0,0,0,.2), rgba(0,0,0,.2) ), url(${get(
          category,
          'heroImage.fixed.src'
        )})`,
        backgroundSize: 'cover',
      }}
    />
    <div className="card-img-overlay d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h3 className="card-title text-white text-shadow">{category.title}</h3>
        <Button color="primary">zur Kategorie</Button>
      </div>
    </div>
  </Link>
);

export default CategoryPreview;
