import React from 'react';
import { Button as DSButton } from '@hausgold/designsystem';
import { DownloadFilled } from '@hausgold/designsystem/icons';
import { graphql } from 'gatsby';
import CopyToClipboard from './CopyToClipboardButton';
import Link from './Link';

// Mapping contentful values to design system values
const COLOR_MAP = {
  primary: 'blue',
  success: 'green',
  danger: 'red',
};

// Mapping contentful values to internal types.
const TYPE_MAP = {
  Default: 'default',
  Download: 'download',
};

// Defines the types props.
const TYPE_PROPS = {
  default: {},
  download: {
    leftIcon: <DownloadFilled />,
    /*
     * This causes the browser to download the file instead of opening it.
     * Only works for same-origin links, though.
     */
    download: true,
    target: '_blank',
  },
};

const Button = ({
  entry,
  eventCategory = 'Special Button',
  eventAction = 'Click',
  eventLabel = null,
}) => {
  /*
   * If CF Button text contains 'zwischenablage' we overwrite the normal button behaviour with
   * the copy-to-clipboard one
   */
  if (entry?.title?.toLowerCase()?.includes('zwischenablage')) {
    return <CopyToClipboard copyText={entry.url} />;
  }

  return (
    <DSButton
      colorScheme={COLOR_MAP[entry.color]}
      shadow={!!entry.shadow && 'lg'}
      size={entry.size === 'small' ? 'sm' : 'md'}
      as={Link}
      to={entry.url}
      eventCategory={eventCategory}
      eventAction={eventAction}
      eventLabel={eventLabel || entry.title}
      {...(TYPE_PROPS[TYPE_MAP[entry.type]] || {})}
    >
      {entry.title}
    </DSButton>
  );
};

export const query = graphql`
  fragment ContentfulButtonFragment on ContentfulButton {
    internal {
      type
    }
    id
    color
    shadow
    size
    title
    url
    type
  }
`;

export default Button;
