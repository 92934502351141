import React from 'react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { Link as DSLink } from '@hausgold/designsystem';
import OutboundLink from './OutboundLink';

const EXTERNAL_LINK_REL = ['nofollow', 'noopener', 'noreferrer'];

/**
 * General `Link`
 *
 * All internal links (starting with `/` or matching https://*.hausgold.de`)
 * will be handled by `GatsbyLink` while all others will be treated as external
 * and handled by Google Analytic's `OutboundLink`.
 */
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  eventLabel,
  eventAction,
  eventCategory,
  useNativeLink = false,
  ...other
}) => {
  // Get all "Follow links" from Contentful
  const {
    allContentfulFollowLink: { nodes: followLinks },
  } = useStaticQuery(graphql`
    {
      allContentfulFollowLink {
        nodes {
          url
        }
      }
    }
  `);

  /*
   * Tailor the following test to your environment.
   * This example assumes that any internal link (intended for Gatsby)
   * will start with exactly one slash, and that anything else is external.
   */
  const internal = /(^\/(?!\/))|(^(https*:\/\/(.*)\.hausgold.de))/.test(to);
  const replacePath = (_path) =>
    _path === `/` ? _path : _path.replace(/\/$|$/, `/`);

  // Use Gatsby Link (or Ds link) for internal links, and <a> with nofollow for others
  if (internal || to === undefined) {
    /**
     * For some voodoo cases the GatsbyLink does not work as it routes to selected page and immediately
     * routes back to the previous one the click was triggered from. For those cases we can use the
     * native link (styled by DS) but without prefetching logic etc. GatsbyLink offers.
     *
     * In special this problem occurs if following comes together:
     * - iPhone with Safari/Chrome or macOS with Safari is used
     * - you open the root page hausgold.de
     * - you accept all cookies or the second or third option at least
     * - you scroll down to the footer and click on e.g. "karriere"
     *
     * If you open another page like an article, the /karriere page directly or decline all cookies,
     * or the second and third at least, everything works fine just like on all other browsers / devices.
     *
     * Disable all tracking / scripts on tag manager does not fix the issue, so it does not seem to be
     * raised by a malformed third-party code.
     *
     * In theory the only downside of not using GatsbyLink is the missing state managing which we do not use
     * and that entering the linked page takes a bit longer as it was not prefetched.
     */
    if (useNativeLink) {
      return (
        <DSLink
          href={replacePath(
            to.replace(/^https*:\/\/(www.)*hausgold\.de\//, '/')
          )}
          activeClassName={activeClassName}
          {...other}
        >
          {children}
        </DSLink>
      );
    }

    return (
      <GatsbyLink
        to={replacePath(to.replace(/^https*:\/\/(www.)*hausgold\.de\//, '/'))}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }

  /*
   * If the `to` link is included in the Follow Links, `nofollow` should NOT
   * be used. Otherwise, `nofollow` is applied.
   */
  const isIncludedInFollowList = followLinks
    .map((link) => link.url)
    .includes(to);

  return (
    <OutboundLink
      rel={
        isIncludedInFollowList
          ? EXTERNAL_LINK_REL.slice(
              EXTERNAL_LINK_REL.indexOf('nofollow') + 1,
              EXTERNAL_LINK_REL.length
            )
          : EXTERNAL_LINK_REL
      }
      eventLabel={eventLabel}
      eventAction={eventAction}
      eventCategory={eventCategory}
      href={to}
      {...other}
    >
      {children}
    </OutboundLink>
  );
};

export default Link;
