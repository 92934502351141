import {
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from '@hausgold/designsystem';
import { Check, ClipboardTextOutline } from '@hausgold/designsystem/icons';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

/**
 * This button implementation overrides the normal `ContentfulButton`.
 * Instead of a button(-link) it renders a copy-to-clipboard functionality
 * specially designed for "/lp/kunden-werben-kunden-werber".
 * The given button link base (provided from Contentful) is a redirect
 * which is enriched by several query params (provided by e-mail).
 * At the moment we only allow `recruiterId` and drop the rest like `utm` tracking.
 * Besides the variable part we also add a hard-coded `lkey` as query param
 * for better tracking without conflicts from given e-mail tracking.
 *
 * @param copyText {URL}
 */
const CopyToClipboard = ({ copyText }) => {
  const [copied, setCopied] = useState(false);

  /**
   * Rebuild URL from `copyText` to ensure only wanted URL parts are copied.
   * Note: Search part always needs a '/' before '?' ('hausgold.de/?recruiterId')
   * because of S3/website-property.
   * @type {URL}
   */
  const urlObject = new URL(copyText);
  const recruiterId = urlObject.searchParams.get('recruiterId');

  urlObject.pathname = urlObject.pathname.endsWith('/')
    ? urlObject.pathname
    : `${urlObject.pathname}/`;
  urlObject.search = '';

  if (recruiterId) {
    urlObject.searchParams.set('recruiterId', recruiterId);
    urlObject.searchParams.set('lkey', 'KW');
  }

  const transformedURL = urlObject.toString();

  /**
   * Does a rough check if all needed parts of the constructed URL are in.
   * (valid-like URL + recruiterId)
   * @type {Boolean}
   */
  const isValidURL = useMemo(
    () =>
      transformedURL.includes('https') &&
      transformedURL.includes('hausgold') &&
      transformedURL.includes('recruiterId'),
    [transformedURL]
  );

  /**
   * Write the constructed URL into clipboard.
   */
  const copyLinkToClipboard = () => {
    // Navigator only works in modern browsers
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(transformedURL);
    } else if (window.clipboardData.setData) {
      window.clipboardData.setData(transformedURL, 'Hausgold referralLink');
    }

    setCopied(true);
  };

  /**
   * Show Link and copy-to-clipboard button.
   */
  if (isValidURL) {
    return (
      <InputGroup mb={4} key="copy-clipboard">
        <Input isReadOnly isDisabled defaultValue={transformedURL} />
        <InputRightAddon
          onClick={copyLinkToClipboard}
          cursor="pointer"
          background={copied ? 'green.400' : 'blue.400'}
          color="white"
          minWidth="150px"
          justifyContent="center"
        >
          {copied ? (
            <>
              <Check mr={2} boxSize={5} />
              <Text mb={0} color="white">
                Kopiert
              </Text>
            </>
          ) : (
            <>
              <ClipboardTextOutline mr={2} boxSize={6} />
              <Text mb={0} color="white">
                Kopieren
              </Text>
            </>
          )}
        </InputRightAddon>
      </InputGroup>
    );
  }

  /**
   * Return nothing if there is no valid url to copy.
   * Note: Fallback components like Banner raised visual conflicts on canary (not local)
   *   as they mixed up the styles and passed them to the InputGroup for whatever reason.
   *   Should be fixed and improves some day. But as the normal user reach this page with
   *   a valid url (by email link) this should be an edge case only.
   */
  return false;
};

CopyToClipboard.propTypes = {
  copyText: PropTypes.string.isRequired,
};

export default CopyToClipboard;
