import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
} from 'reactstrap';
import { Formik, Form as FormikForm, Field } from 'formik';
import axios from 'axios';
import Layout from '../components/Layout';
import ContentfulList from '../components/ContentfulList';
import Header from '../components/Header';
import SEO from '../components/SEO';
import pressekontakt from '../images/freya-matthiessen.jpg';

const Presse = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const site = get(data, 'site');

  const social = {
    listItems: [
      {
        id: 'f7ee3d02-d64d-5ebe-914e-31ff2e3ccbc0',
        icon: 'facebook',
        type: 'HGsocial',
        text: {
          childMarkdownRemark: {
            html: "<a target='_blank' href='https://www.facebook.com/hausgold.de/'>@Hausgold.de</a>",
          },
        },
      },
      {
        id: '41722ebd-d51f-5372-b242-4c998cc69fcf',
        icon: 'instagram',
        type: 'HGsocial',
        text: {
          childMarkdownRemark: {
            html: "<a target='_blank' href='https://www.instagram.com/hausgold.de/?hl=de'>@Hausgold.de</a>",
          },
        },
      },
      {
        id: '81fc5500-6ce5-5717-be08-d4cba5a83494',
        icon: 'twitter',
        type: 'HGsocial',
        text: {
          childMarkdownRemark: {
            html: "<a target='_blank' href='https://twitter.com/ImmoBeratung'>@ImmoBeratung</a>",
          },
        },
      },
    ],
  };

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container className="mb-5 mt-6">
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <a className="nav-link active" href="/presse/">
              Presse
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pressemitteilungen/">
              Pressemitteilungen
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pressedownloads/">
              Pressedownloads
            </a>
          </li>
        </ul>
      </Container>

      <Container>
        <Row>
          <Col xs="12" lg="6">
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
          </Col>
          <Col xs="12" md="6" className="text-center mt-4 mt-md-0">
            <img
              className="rounded-circle"
              src={pressekontakt}
              style={{ maxWidth: '200px' }}
            />
            <p className="text-center p-0 mt-3">
              Freya Matthiessen <br /> Public Relations <br />
              <span>
                <svg width="25px" height="25px" className="mr-2">
                  <path
                    fill="#1694f4"
                    d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                  />
                </svg>
                <a href="mailto:presse@hausgold.de">presse@hausgold.de</a>{' '}
                <br />
              </span>
              <span>
                <svg width="25px" height="25px" className="mr-2">
                  <path
                    fill="#1694f4"
                    d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
                  />
                </svg>
                <a href="tel:040226161425">040 22 61 61 4 25</a>
              </span>
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="my-md-7 my-5 ">
        <Row>
          <Col xs="12" md="6" className="pr-md-6 pr-3">
            <h2>Kontaktformular</h2>
            <Formik
              initialValues={{ name: '', email: '', message: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Bitte geben Sie Ihren Namen an.';
                }

                if (!values.message) {
                  errors.message = 'Bitte geben Sie einen Text ein.';
                }

                if (!values.email) {
                  errors.email = 'Bitte geben Sie eine E-Mail-Adresse an.';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email =
                    'Bitte geben Sie eine gültige E-Mail-Adresse an.';
                }

                return errors;
              }}
              onSubmit={async (values, { resetForm, setStatus }) => {
                axios
                  .post(
                    'https://getform.io/f/8e2a6ea6-5f4e-4210-b514-2d6c776ea6c8',
                    values,
                    { headers: { Accept: 'application/json' } }
                  )
                  .then(() => {
                    resetForm();
                    setStatus({ success: true });
                    // ResetForm()
                  })
                  .catch(() => {
                    setStatus({ success: false });
                  });
              }}
            >
              {({ errors, isSubmitting, status }) => (
                <Form tag={FormikForm}>
                  {status && status.success && (
                    <Alert color="success">Danke für Ihre Nachricht.</Alert>
                  )}
                  {status && !status.success && (
                    <Alert color="danger">
                      Leider ist ein Fehler aufgetreten. Bitte senden Sie für
                      Ihre Anfrage eine E-Mail an presse@hausgold.de .
                    </Alert>
                  )}
                  <FormGroup>
                    <Label for="name">Ihr Name:</Label>
                    <Input
                      invalid={!!errors.name}
                      tag={Field}
                      type="text"
                      name="name"
                      id="name"
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Ihre E-Mail-Adresse:</Label>
                    <Input
                      invalid={!!errors.email}
                      tag={Field}
                      type="email"
                      name="email"
                      id="email"
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="message">Ihre Nachricht:</Label>
                    <Input
                      invalid={!!errors.message}
                      tag={Field}
                      component="textarea"
                      type="textarea"
                      name="message"
                      id="message"
                    />
                    <FormFeedback>{errors.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup check row>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Senden
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </Col>

          <Col xs="12" md="6" className="border-left pl-md-7 pl-3 mt-5 mt-md-0">
            <h2 className="pb-5">Social Media</h2>
            <ContentfulList entry={social} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Presse;

export const pageQuery = graphql`
  query presseQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "presse" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
