import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  Box,
  Pagination,
  PaginationItem,
  PaginationNextArrow,
  PaginationPrevArrow,
} from '@hausgold/designsystem';
import Layout from '../components/Layout';
import ArticlePreview from '../components/ArticlePreview';
import Header from '../components/Header';
import SEO from '../components/SEO';
import Link from '../components/Link';

const BlogCategoryTemplate = ({ data, location, pageContext }) => {
  const category = get(data, 'contentfulCategory');
  const site = get(data, 'site');
  const articles = get(data, 'allContentfulArticle.edges');
  const breadcrumbs = [
    {
      title: 'Ratgeber',
      url: `${site.siteMetadata.siteUrl}/ratgeber/`,
    },
    {
      title: category.title,
      url: `${site.siteMetadata.siteUrl}/${category.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={<Header title={category.title} subTitle={category.subTitle} />}
    >
      <Helmet>
        <meta name="content-entity" content="article" />
        <meta name="content-id" content={category.contentful_id} />
      </Helmet>

      <SEO
        entity={category}
        title={category.metaTitle || category.title}
        canonical={category.canonical}
        type="category"
        description={category.metaDescription}
        indexable={category.indexable}
        breadcrumbs={breadcrumbs}
      />
      <Container className="mt-6">
        <Row>
          <Col>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/ratgeber">Ratgeber</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{category.title}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="justify-content-between">
          {articles.map(({ node }) => (
            <Col xs={12} md={6} lg={4} key={node.slug}>
              <ArticlePreview key={node.slug} article={node} />
            </Col>
          ))}

          <Pagination
            aria-label="Page navigation"
            size="md"
            justifyContent="center"
            py={4}
            w="full"
          >
            <Box>
              <PaginationPrevArrow
                h="full"
                isDisabled={pageContext.pageNumber === 0}
                as={Link}
                to={`${pageContext.previousPagePath}/`}
              />
            </Box>
            {[...Array(pageContext.numberOfPages)].fill().map((_, i) => {
              const pageIndex = i + 1;
              return (
                <PaginationItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`page-${i}`}
                  isActive={pageIndex === pageContext.humanPageNumber}
                  as={Link}
                  to={`/${category.slug}/${pageIndex > 1 ? pageIndex : ''}${
                    pageIndex > 1 ? '/' : ''
                  }`}
                >
                  {pageIndex}
                </PaginationItem>
              );
            })}
            <Box>
              <PaginationNextArrow
                h="full"
                isDisabled={
                  pageContext.humanPageNumber === pageContext.numberOfPages
                }
                as={Link}
                to={`${pageContext.nextPagePath}/`}
              />
            </Box>
          </Pagination>

          {!articles.length && 'Keine Artikel vorhanden.'}
        </Row>
      </Container>
    </Layout>
  );
};

export default BlogCategoryTemplate;

export const pageQuery = graphql`
  query CategoryBySlug($slug: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulCategory(slug: { eq: $slug }) {
      contentful_id
      slug
      subTitle
      metaTitle
      metaDescription
      indexable
      title
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
    allContentfulArticle(
      filter: { category: { slug: { eq: $slug } } }
      skip: $skip
      limit: $limit
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          subTitle
          indexable
          metaTitle
          metaDescription
          topArticle
          publishDate
          heroImage {
            gatsbyImageData(
              height: 270
              width: 350
              cropFocus: FACES
              resizingBehavior: FILL
            )
            title
          }
          description {
            childMarkdownRemark {
              excerpt(format: PLAIN, pruneLength: 250)
            }
          }
          category {
            slug
          }
        }
      }
    }
  }
`;
