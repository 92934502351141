import React from 'react';
import { SimpleGrid } from '@hausgold/designsystem';
import ContentfulDownloadItem from './ContentfulDownloadItem';

const ContentfulDownloadList = ({ entry }) => (
  <SimpleGrid columns={{ base: 1, lg: 3 }} gap={5}>
    {entry.downloadItems.map((item) => (
      <ContentfulDownloadItem entry={item} />
    ))}
  </SimpleGrid>
);

export default ContentfulDownloadList;
