import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Icon from './Icon';

const ContentfulListItem = ({ entry }) => {
  if (!entry.text) {
    return null;
  }

  return (
    <Card className="bg-info border-0 rounded-0">
      <CardBody className="d-md-flex flex-md-row justify-content-md-center">
        {entry.image ? (
          <GatsbyImage
            image={getImage(entry.image)}
            alt={entry.image.title}
            className="flex-shrink-0 "
          />
        ) : (
          <Icon
            name={entry.icon}
            size={50}
            color="white"
            className="d-block mx-auto mb-3 mb-md-0 rounded-circle bg-primary"
            style={{
              padding: '10px',
              flexShrink: '0',
            }}
          />
        )}

        <div
          className="body markdown pl-md-4"
          dangerouslySetInnerHTML={{
            __html: entry.text.childMarkdownRemark.html,
          }}
        />
      </CardBody>
    </Card>
  );
};

export default ContentfulListItem;
