import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { getSrc } from 'gatsby-plugin-image';

const isBrowser = typeof window !== 'undefined';

const base = ({ graphObjects = [], siteUrl } = {}) => ({
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': ['Organization', 'Brand'],
      '@id': 'https://www.hausgold.de/#organization',
      name: 'HAUSGOLD',
      url: 'https://www.hausgold.de/',
      legalName: 'HAUSGOLD | talocasa GmbH',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+494022616140',
        contactType: 'Customer Service',
        areaServed: 'DE',
        availableLanguage: ['English', 'German'],
        email: 'service@hausgold.de',
      },
      address: {
        '@type': 'PostalAddress',
        '@id': 'https://www.hausgold.de/#postalAddress',
        postalCode: '20099',
        addressLocality: 'Hamburg',
        addressRegion: 'Hamburg',
        streetAddress: 'An der Alster 45',
        addressCountry: 'DE',
      },
      logo: {
        '@type': 'ImageObject',
        inLanguage: 'de-DE',
        '@id': 'https://www.hausgold.de/#/schema/logo/image/',
        url: 'https://ctf-images.hausgold.de/5qea3umoe8gl/6nPEyy2xlmi1YjWTKq0v8Q/166e23e522830b293c6c9dc8d09a8583/Colored.png',
        width: 160,
        height: 160,
        caption: 'Hausgold',
      },
      image: {
        '@id': 'https://www.hausgold.de/#/schema/logo/image/',
      },
      sameAs: [
        'https://www.instagram.com/hausgold.de/',
        'https://www.facebook.com/hausgold.de',
        'https://www.xing.com/pages/hausgold-talocasagmbh',
        'https://www.linkedin.com/company/hausgold-talocasa-gmbh/',
      ],
    },
    {
      '@type': 'WebSite',
      '@id': `${siteUrl}#website`,
      url: 'https://www.hausgold.de',
      name: 'HAUSGOLD',
      publisher: {
        '@id': 'https://www.hausgold.de/#organization',
      },
      inLanguage: 'de-DE',
    },
    ...graphObjects,
  ],
});

const person = ({ name, imageUrl, imageCaption, bio, profile, slug }) => ({
  '@type': 'Person',
  '@id': `https://www.hausgold.de/#person/${slug}`,
  name,
  image: {
    '@type': 'ImageObject',
    inLanguage: 'de-DE',
    '@id': 'https://www.hausgold.de/#/person/image/',
    url: imageUrl,
    contentUrl: imageUrl,
    caption: imageCaption,
  },
  description: bio,
  sameAs: profile,
});

const faqPage = ({ items = [] } = {}) => ({
  '@type': 'FAQPage',
  mainEntity: items.map((item) => ({
    '@type': 'Question',
    name: item.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: get(item, 'answer.childMarkdownRemark.html'),
    },
  })),
});

const breadcrumbList = ({ items = [] } = {}, seo) => ({
  '@type': 'BreadcrumbList',
  '@id': `${seo.url}#breadcrumb`,
  itemListElement: items.map((item, idx) => ({
    '@type': 'ListItem',
    position: idx + 1,
    name: item.title,
    item: item.url,
  })),
});
const imageObject = ({ url, imageSrc }) => ({
  '@type': 'ImageObject',
  inLanguage: 'de-DE',
  '@id': `${url}#primaryimage`,
  url: imageSrc,
  contentUrl: imageSrc,
  width: 2000,
  height: 1228,
});

const article = ({
  headline,
  url,
  createdAt,
  updatedAt,
  ImageUrl,
  author,
}) => ({
  '@type': 'Article',
  '@id': `${url}#article`,
  isPartOf: {
    '@id': url,
  },
  author: author
    ? {
        name: `${author?.firstName} ${author?.lastName}`,
        '@id': `https://www.hausgold.de/#person/${author?.slug}`,
      }
    : { '@id': 'https://www.hausgold.de/#organization' },
  headline: headline || '',
  datePublished: createdAt,
  dateModified: updatedAt,
  mainEntityOfPage: {
    '@id': url,
  },
  publisher: {
    '@id': 'https://www.hausgold.de/#organization',
  },
  image: {
    '@id': `${url}#primaryimage`,
  },
  thumbnailUrl: ImageUrl,
  inLanguage: 'de-DE',
});

const webPage = ({
  url,
  title,
  createdAt,
  updatedAt,
  description,
  entityType = 'page',
  imageSrc,
}) => ({
  '@type': 'WebPage',
  '@id': `${url}`,
  url,
  inLanguage: 'de-DE',
  name: title,
  isPartOf: {
    '@id': 'https://www.hausgold.de/#website',
  },
  datePublished: createdAt,
  dateModified: updatedAt,
  description,
  breadcrumb: {
    '@id': `${url}#breadcrumb`,
  },
  ...(entityType === 'article'
    ? {
        primaryImageOfPage: {
          '@id': `${url}#primaryimage`,
        },
        image: {
          '@id': `${url}#primaryimage`,
        },
        thumbnailUrl: imageSrc,
      }
    : {}),
});

const contentfulArticle = ({ seo = {}, entity = {} } = {}) => [
  article({
    url: seo?.url,
    createdAt: entity?.publishDate,
    updatedAt: entity?.updatedAt,
    ImageUrl: entity?.imageSrc || entity?.heroImage?.url || seo?.image,
    author: entity?.author || null,
    headline: entity?.title,
  }),
  webPage({
    url: seo?.url,
    title: seo?.title,
    createdAt: entity?.publishDate,
    updatedAt: entity?.updatedAt,
    description: seo?.description,
    entityType: 'article',
    imageSrc: entity?.imageSrc,
  }),
  imageObject({
    url: seo?.url,
    imageSrc: entity?.imageSrc,
  }),
  ...(entity?.author
    ? [
        person({
          name: `${entity?.author?.firstName} ${entity?.author?.lastName}`,
          imageUrl: getSrc(entity?.author?.image),
          imageCaption: entity?.author?.image?.title || '',
          bio: entity?.author?.bio?.childMarkdownRemark?.html,
          slug: entity?.author?.slug,
          profile: Object.values(entity?.author?.socialMedia || {}).filter(
            (value) => value !== null
          ),
        }),
      ]
    : []),
];

const contentfulPage = ({ seo = {}, entity = {} } = {}) => {
  const schema = [
    webPage({
      url: seo?.url,
      title: seo?.title,
      createdAt: entity?.createdAt,
      updatedAt: entity?.updatedAt,
      description: seo?.description,
    }),
  ];

  if (entity.useArticleSchema)
    schema.push(
      article({
        title: seo?.title,
        url: seo?.url,
        createdAt: entity?.createdAt,
        updatedAt: entity?.updatedAt,
        ImageUrl: entity?.imageSrc || entity?.heroImage?.url || seo?.image,
        author: entity?.author || null,
      })
    );

  return schema;
};

const SchemaOrg = ({
  entityType = 'unknown',
  siteUrl,
  seo = {},
  entity = {},
  breadcrumbs = [],
  faqItems = [],
} = {}) => {
  // We just do nothing on runtime
  if (!isBrowser) {
    return null;
  }

  /*
   * Otherwise, when we are on the build stage,
   * generate the schema.org schema
   */
  const children = [];

  if (breadcrumbs.length > 0) {
    children.push(
      breadcrumbList(
        {
          items: breadcrumbs,
        },
        seo
      )
    );
  }

  if ((faqItems || []).length) {
    children.push(
      faqPage({
        items: faqItems,
      })
    );
  }

  if (entityType === 'article') {
    children.push(...contentfulArticle({ seo, entity }));
  }

  if (entityType === 'page') {
    children.push(...contentfulPage({ seo, entity }));
  }

  const schema = JSON.stringify(
    base({
      siteUrl,
      graphObjects: children,
    })
  );

  return (
    <Helmet>
      <script type="application/ld+json">{schema}</script>
    </Helmet>
  );
};

export default SchemaOrg;
