import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import cn from 'classnames';
import NavbarSimple from './NavbarSimple';

// HeaderText is used as a version of the header only for landingpages
const HeaderText = ({ title, subTitle, className }) => (
  <header>
    <div className={cn('header header-text', className)}>
      <NavbarSimple />
      <Container className="container-narrow mt-7">
        <Row>
          <Col>
            {title && (
              <h1 className="display-4 text-break mb-6">
                {title}
                {subTitle && (
                  <>
                    <br />
                    <small className="font-weight-semibold d-block mt-4">
                      {subTitle}
                    </small>
                  </>
                )}
              </h1>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  </header>
);

export default HeaderText;
