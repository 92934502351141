import { Box } from '@hausgold/designsystem';
import HausgoldFormwizard, { FormwizardConfig } from '@hausgold/formwizard';
import React, { useEffect, useState } from 'react';
import useAppsignal from '../hooks/useAppsignal';
import useLeadId from '../hooks/useLeadId';
import useUrlParams from '../hooks/useUrlParams';

const DEFAULT_URL = 'https://formwizard.hausgold.de/formular/content';
const DEFAULT_POSITION = 'right';

/**
 * Formwizard
 *
 * @param {Object} props
 * @param {string} props.url
 * @param {'header'|'right'|'top'} props.position
 * @param {import('@hausgold/designsystem').BoxProps} props.rest
 */
const Formwizard = ({
  url = DEFAULT_URL,
  location,
  position = DEFAULT_POSITION,
  ...rest
}) => {
  const [src, setSrc] = useState(url);

  useEffect(() => {
    setSrc(url + location.search);
  }, [url, location]);

  /**
   * Get dialodId, variant and leadId (lkey) from
   * loaded formwizard url (provided by Contentful).
   */
  const { variant, dialogId } = useUrlParams(src);
  const lkeyFromCF = useLeadId(src);
  const appsignal = useAppsignal();

  /**
   * Get the first match of recruiterId from the url.
   */
  const recruiterId = location?.search
    ?.split('&')
    ?.filter((param) => param.includes('recruiterId'))[0]
    ?.split('=')[1];

  /**
   * Get the first match of lkey from the url.
   */
  const lkeyFromURL = location?.search
    ?.split('&')
    ?.filter((param) => param.includes('lkey'))[0]
    ?.split('=')[1];

  // Create fw config
  const fwConfig = {
    ...FormwizardConfig,
    analytics: {
      // If not running production we use the canary key.
      segmentKey:
        __ENV_MODE__ === 'production'
          ? FormwizardConfig.analytics.segmentKey
          : 'gfRQEj3Qjx5LkHDg6P5U86JIrcfKn8HC',
    },
  };

  let styles = {
    borderRadius: 'lg',
    display: 'block',
    w: '100%',
    border: 'none',
  };

  if (position !== 'header') {
    styles = {
      ...styles,
      boxShadow: 'lg',
    };
  }

  if (position === 'right') {
    styles = {
      ...styles,
      mb: 5,
      // Adds header height + a little bit extra space
      top: '95px !important',

      /*
       * Prevents cut off of lower iframe end if the iframe is too big.
       * fw variant highlight can reach height of 804px, so
       * we can't use sticky on smaller heights.
       */
      style: {
        '@media(maxHeight: 805px)': {
          pos: 'relative',
          top: '0 !important',
        },
      },
    };
  }

  /**
   * Return Formwizard
   */
  return (
    <Box {...styles} {...rest}>
      <HausgoldFormwizard
        appsignal={appsignal}
        variant={variant}
        leadId={lkeyFromURL || lkeyFromCF}
        dialogId={dialogId}
        recruiterId={recruiterId}
        position={position}
        config={fwConfig}
        isLocal={__ENV_MODE__ !== 'production'}
      />
    </Box>
  );
};

export default Formwizard;
