// Collect all the frequently asked questions (items) from multiple sources.
export const collectFaqs = ({ faq = {}, contentBlocks = [] } = {}) => {
  const items = (contentBlocks || []).reduce((memo, block) => {
    (block.entries || []).forEach((entry) => {
      // Ignore non faq list entries
      if (entry?.internal?.type !== 'ContentfulFaqList') {
        return;
      }

      entry.faqItems.forEach((item) => {
        memo.push(item);
      });
    });

    return memo;
  }, []);

  (faq?.faqItems || []).forEach((item) => {
    items.push(item);
  });

  return items;
};

/**
 * Gets a script snippet and extract all relevant script parts from it into an object.
 * @param script
 */
export const getScriptParts = (script) => {
  /**
   * Abort if no script is provided.
   */
  if (!script) {
    return {};
  }

  /*
   * We support 'link' and 'script' and a couple of related attributes.
   */
  const scriptType = script.trim().startsWith('<link') ? 'link' : 'script';
  const id = script.match(/id="[^"]+/)?.[0].replace('id="', '');
  const type = script.match(/type="[^"]+/)?.[0].replace('type="', '');
  const src = script.match(/src="[^"]+/)?.[0].replace('src="', '');
  const href = script.match(/href="[^"]+/)?.[0].replace('href="', '');
  const rel = script.match(/rel="[^"]+/)?.[0].replace('rel="', '');
  const async = script.match(/async="[^"]+/)?.[0].replace('async="', '');
  const defer = script.match(/defer="[^"]+/)?.[0].replace('defer="', '');
  const integrity = script
    .match(/integrity="[^"]+/)?.[0]
    .replace('integrity="', '');
  const referrerpolicy = script
    .match(/referrerpolicy="[^"]+/)?.[0]
    .replace('referrerpolicy="', '');
  const crossorigin = script
    .match(/crossorigin="[^"]+/)?.[0]
    .replace('crossorigin="', '');
  const title = script.match(/title="[^"]+/)?.[0].replace('title="', '');
  const data = script.match(/data-[^=]+="[^"]+/gi)?.reduce((prev, current) => {
    const [key, value] = current.split('="');
    return { ...prev, [key]: value };
  }, {});
  const content = script
    .replace(/<(script|link)[^>]*>/, '')
    .trim()
    .replace(/(<\/script>|\/>)$/, '')
    .trim()
    .replace(/^\n/, '');

  /*
   * Ensure that only keys which contain data are passed.
   */
  let constructedParts = {};

  if (id) {
    constructedParts.id = id;
  }

  if (type) {
    constructedParts.type = type;
  }

  if (src) {
    constructedParts.src = src;
  }

  if (href) {
    constructedParts.href = href;
  }

  if (rel) {
    constructedParts.rel = rel;
  }

  if (crossorigin) {
    constructedParts.crossorigin = crossorigin;
  }

  if (title) {
    constructedParts.title = title;
  }

  if (async) {
    constructedParts.async = async;
  }

  if (defer) {
    constructedParts.defer = defer;
  }

  if (integrity) {
    constructedParts.integrity = integrity;
  }

  if (referrerpolicy) {
    constructedParts.referrerpolicy = referrerpolicy;
  }

  if (content) {
    constructedParts.content = content;
  }

  constructedParts = { ...constructedParts, ...data, scriptType };

  return constructedParts;
};
