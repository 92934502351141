import { extendTheme } from '@hausgold/designsystem';

// eslint-disable-next-line import/prefer-default-export
const theme = extendTheme({
  /* --- BOOTSTRAP FIXES START --- */
  styles: {
    /**
     * This is usually provided by the `resetCSS` option of the DesignsystemProvider,
     * but it's turned off becausee of Bootstrap.
     *
     * This does:
     *
     *  * Universal border-box
     *  * Transparent, outline-less buttons by default
     *  * `body` font size of `lg` (16px) instead of the original `md` (14px)
     *
     * Remove this when Bootstrap is removed.
     */
    global: {
      '*,*::before,*::after': {
        boxSizing: 'border-box',
        borderWidth: 0,
        borderStyle: 'solid',
      },
      body: {
        /*
         * We set that font size differently because Bootstrap will freak
         * Out otherwise. This can lead to minor deviations in certain
         * cases, but is okay-ish for a transition phase.
         */
        fontSize: 'lg',
      },
      button: {
        bg: 'transparent',
        appearance: 'none',
        '&:focus': {
          outline: 'none',
        },
      },

      /*
       * This fixes Bootstrap default-styling of `ul`s interacting with the
       * `Wrap` component.
       */
      '.chakra-wrap__list': {
        padding: 0,
        margin: 0,
        marginBottom: 0,
      },
    },
  },
  components: {
    Text: {
      sizes: {
        md: {
          /*
           * Because of Bootstrap's `16px` body font size, the default size of `md` is
           * Not `14px` like in the design system, but `16px`. This is a "fix"
           * for that.
           *
           * We don't use `md` because it would use rem. We'd therefore lose the
           * relative resizing that we rely upon in certain places.
           */
          fontSize: `0.875em`, // = md
        },
      },
    },
  },
  /* --- BOOOTSTRAP FIXES END --- */
});

export default theme;
