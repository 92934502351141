import { Box, Container } from '@hausgold/designsystem';
import React from 'react';
import PressLogos from './PressLogos';

const KnownFrom = () => (
  <Container px="2">
    <Box
      display={{ base: 'block', md: 'inline-block' }}
      my={{ base: 5, md: 0 }}
      mt={{ base: 4, md: 0 }}
      textAlign={{ base: 'center', md: 'left' }}
      textColor="gray.400"
    >
      Bekannt aus
      <sup>
        <a href="#press-release-dates">1</a>
      </sup>
      :
    </Box>
    <PressLogos showReviews />
  </Container>
);

export default KnownFrom;
