import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../components/Layout';
import ExpertPreview from '../components/ExpertPreview';
import Header from '../components/Header';
import Formwizard from '../components/Formwizard';
import SEO from '../components/SEO';

const Experten = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const site = get(data, 'site');
  const heroImage = get(page, 'heroImage');
  const experts = get(data, 'allContentfulPerson.edges');

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.title}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        entity={page}
        title={page.metaTitle || page.title}
        canonical={page.canonical}
        type="page"
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />
      <Container className="mt-6">
        <Row className="justify-content-between">
          <Col xs="12" md="12" lg="7">
            {experts.map(({ node }) => (
              <ExpertPreview key={node.slug} expert={node} />
            ))}
            {!experts.length && 'Keine Artikel vorhanden.'}
          </Col>

          <Col xs="12" lg="4">
            <Formwizard location={location} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Experten;

export const pageQuery = graphql`
  query ExpertenQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "experten" }) {
      id
      title
      slug
      headline
      metaTitle
      subTitle
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
    allContentfulPerson(filter: { tag: { in: "expert" } }) {
      edges {
        node {
          slug
          firstName
          lastName
          company
          department
          email
          title
          socialMedia {
            facebook
            instagram
            twitter
            website
          }
          bio {
            childMarkdownRemark {
              html
            }
          }
          freeText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(layout: FIXED, width: 800)
            title
          }
        }
      }
    }
  }
`;
