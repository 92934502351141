import { Box, Button, Heading, Text } from '@hausgold/designsystem';
import { Container } from 'reactstrap';
import React from 'react';
import { ChevronRight } from '@hausgold/designsystem/icons';
import Link from '../../../Link';
import teaserImage from './image.png';

/**
 * New, grey header
 *
 * Also prototype code, supposed to be made a Contentful model after MVP phase.
 */
const Teaser = () => (
  <>
    <Box bgColor="gray.100" pt="82px">
      <Box
        bgRepeat="no-repeat"
        bgPosition="bottom right"
        backgroundImage={{ xl: teaserImage }}
        py={12}
        as={Container}
      >
        <Box width={{ xl: '50%' }}>
          <Heading as="h1" fontSize="4xl">
            HAUSGOLD-Partner werden
          </Heading>
          <Heading as="h2" size="h1">
            Erfolgreiche Makler setzen auf HAUSGOLD
          </Heading>
          <Text>
            Profitieren Sie von unserer Expertise und gewinnen Sie Zugang zu
            passenden Verkaufsobjekten und qualifizierten Eigentümerkontakten.
            Unser Ziel ist es, Ihnen die Arbeit zu vereinfachen und Ihren Umsatz
            zu maximieren.Optimieren Sie Ihre Kundenakquise und steigern Sie
            Ihren Erfolg durch mehr Immobilienverkäufe!
          </Text>

          <Button
            as={Link}
            to="/koop-anfrage"
            mt={5}
            width={{ base: '100%', md: 'auto' }}
            leftIcon={<ChevronRight />}
            textTransform="uppercase"
          >
            Jetzt Anmelden
          </Button>
        </Box>
      </Box>
    </Box>
  </>
);

export default Teaser;
