import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';
import ContentBlock from '../components/ContentBlock';

const Pressemitteilungen = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const site = get(data, 'site');

  const pressemitteilungen = page.contentBlocks.filter(
    (block) => block.title === 'Aktuelle Pressemitteilungen'
  )[0];

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container className="mb-5 mt-6">
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <a className="nav-link" href="/presse/">
              Presse
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link active" href="/pressemitteilungen/">
              Pressemitteilungen
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pressedownloads/">
              Pressedownloads
            </a>
          </li>
        </ul>
      </Container>

      {page.body && (
        <Container>
          <Row>
            <Col xs="12" md="6">
              <div
                className="body"
                dangerouslySetInnerHTML={{
                  __html: page.body.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
        </Container>
      )}

      <Container>
        <ContentBlock block={pressemitteilungen} />
      </Container>
    </Layout>
  );
};

export default Pressemitteilungen;

export const pageQuery = graphql`
  query PressemitteilungenQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "pressemitteilungen" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      contentBlocks {
        title
        entries {
          ... on ContentfulDownloadList {
            id
            internal {
              type
            }
            downloadItems {
              date(formatString: "DD.MM.YYYY, HH:MM")
              title
              file {
                file {
                  url
                }
              }
            }
          }
        }
      }

      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
