import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Stack, Text } from '@hausgold/designsystem';

const ContentfulMedia = ({ entry }) => {
  if (!entry.attachment) return null;
  return (
    <Stack>
      {entry.attachment && (
        <GatsbyImage
          style={{ height: '100%' }}
          image={getImage(entry.attachment)}
          alt={entry.attachment.title}
        />
      )}
      {entry.media_caption && <Text size="sm">{entry.media_caption}</Text>}
    </Stack>
  );
};

export default ContentfulMedia;
