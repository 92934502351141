import React from 'react';
import { Container } from 'reactstrap';
import { Text } from '@hausgold/designsystem';
import Section from '../Section';

const Testimonial = () => (
  <Section
    my={0}
    py={12}
    bg="gray.100"
    sx={{ '.container': { maxWidth: '780px' } }}
  >
    <Container>
      <Text
        as="q"
        mb={10}
        textAlign="center"
        display="block"
        quotes={`"„" "“"`}
        fontStyle="italic"
        fontSize={{ base: '2xl', md: '4xl' }}
        sx={{
          '&::after, &::before': {
            color: 'blue.400',
          },
        }}
      >
        HAUSGOLD hat mit der Verkaufsquote aktuell die besten Kundenkontakte am
        Markt.
      </Text>
      <Text textAlign="right">Ein zufriedener HAUSGOLD-Partner</Text>
    </Container>
  </Section>
);

export default Testimonial;
