import React from 'react';
import { graphql } from 'gatsby';
import {
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Container,
  FormControl,
  Feedback,
  Input,
  Label,
  RadioGroup,
  Radio,
  Text,
  Flex,
  Button,
  Checkbox,
} from '@hausgold/designsystem';
import axios from 'axios';
import get from 'lodash/get';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import SEO from '../../components/SEO';
import Link from '../../components/Link';

const SolarBeratung = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container maxW="container.md" mt={10}>
        <Formik
          initialValues={{
            salutation: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address: '',
            zip: '',
            city: '',
            owner: '',
            ownerName: '',
            propertyType: '',
            propertyTypeOther: '',
            privateProperty: '',
            area: '',
            consent: false,
          }}
          validationSchema={Yup.object({
            salutation: Yup.string()
              .oneOf(['mr', 'mrs'])
              .required('Bitte wählen Sie eine Anrede aus.'),
            firstName: Yup.string().required(
              'Bitte geben Sie Ihren Vornamen an.'
            ),
            lastName: Yup.string().required(
              'Bitte geben Sie Ihren Nachnamen an.'
            ),
            phone: Yup.string()
              .matches(
                /^(?:\+)?(?:[0-9] ?){6,14}[0-9]$/,
                'Bitte geben Sie eine gültige Telefonnummer an.'
              )
              .required('Bitte geben Sie Ihre Telefonnummer an.'),
            email: Yup.string()
              .email('Bitte geben Sie eine gültige E-Mail an.')
              .required('Bitte geben Sie Ihre E-Mail an.'),
            address: Yup.string().required('Bitte geben Sie Ihre Adresse an.'),
            zip: Yup.string()
              .matches(
                /^\d{5}$/,
                'Bitte geben Sie eine gültige Postleitzahl an.'
              )
              .required('Bitte geben Sie Ihre Postleitzahl an.'),
            city: Yup.string().required('Bitte geben Sie Ihre Stadt an.'),
            owner: Yup.string()
              .oneOf(['yes', 'no'])
              .required('Bitte geben Sie an ob Sie der Eigentümer sind.'),
            ownerName: Yup.string().when('owner', {
              is: 'no',
              then: Yup.string().required(
                'Bitte geben Sie den Namen des Eigentümers an.'
              ),
            }),
            propertyType: Yup.string()
              .oneOf([
                'ein-oder-zweifamilienhaus',
                'mehrfamilienhaus',
                'gewerbe-buero',
                'freilandflaeche',
                'andere',
              ])
              .required('Bitte geben Sie die Immobilienart an.'),
            propertyTypeOther: Yup.string().when('propertyType', {
              is: 'andere',
              then: Yup.string().required(
                'Bitte geben Sie die Immobilienart an.'
              ),
            }),
            privateProperty: Yup.string()
              .oneOf(['yes', 'no'])
              .required(
                'Bitte geben Sie an ob Sie die Immobilie privat nutzen.'
              ),
            area: Yup.string()
              .oneOf([
                'bis-30-m2',
                '30-bis-100-m2',
                '101-bis-200-m2',
                'ueber-200-m2',
                'weiss-ich-nicht',
              ])
              .required('Bitte geben Sie die Fläche an.'),
            consent: Yup.boolean().isTrue(
              'Bitte bestätigen Sie, dass Sie ein Gespräch mit einem Solarberater wünschen.'
            ),
          })}
          onSubmit={async (values, { resetForm, setStatus }) => {
            const body = {
              salutation: values.salutation,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              email: values.email,
              address: values.address,
              zip: values.zip,
              city: values.city,
              owner: values.owner,
              ownerName: values.owner === 'no' ? values.ownerName : '',
              propertyType:
                values.propertyType !== 'andere'
                  ? values.propertyType
                  : values.propertyTypeOther,
              area: values.area,
              privateProperty: values.privateProperty,
              consent: values.consent,
            };
            try {
              setStatus({});
              await axios.post(
                'https://getform.io/f/67b7c168-4034-4076-933a-92061b0a232f',
                body,
                { headers: { Accept: 'application/json' } }
              );
              resetForm();
              setStatus({ success: true });
            } catch (err) {
              setStatus({ error: true });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            isSubmitting,
            handleSubmit,
          }) => (
            <>
              <Form>
                <FormControl
                  isRequired
                  isInvalid={errors.salutation && touched.salutation}
                  as="fieldset"
                >
                  <Label as="legend">Anrede</Label>
                  <RadioGroup value={values.salutation}>
                    <Flex flexDir="column">
                      <Field as={Radio} name="salutation" value="mr">
                        Herr
                      </Field>
                      <Field as={Radio} name="salutation" value="mrs">
                        Frau
                      </Field>
                    </Flex>
                  </RadioGroup>
                  <Feedback>{errors.salutation}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.firstName && touched.firstName}
                >
                  <Label>Vorname</Label>
                  <Field as={Input} name="firstName" placeholder="Vorname" />
                  <Feedback>{errors.firstName}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.lastName && touched.lastName}
                >
                  <Label>Nachname</Label>
                  <Field as={Input} name="lastName" placeholder="Nachname" />
                  <Feedback>{errors.lastName}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.phone && touched.phone}
                >
                  <Label>Telefonnummer</Label>
                  <Field as={Input} name="phone" placeholder="Telefonnummer" />
                  <Feedback>{errors.phone}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.email && touched.email}
                >
                  <Label>E-Mail</Label>
                  <Field as={Input} name="email" placeholder="E-Mail" />
                  <Feedback>{errors.email}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.address && touched.address}
                >
                  <Label>Adresse</Label>
                  <Field as={Input} name="address" placeholder="Adresse" />
                  <Feedback>{errors.address}</Feedback>
                </FormControl>
                <FormControl isRequired isInvalid={errors.zip && touched.zip}>
                  <Label>Postleitzahl</Label>
                  <Field as={Input} name="zip" placeholder="Postleitzahl" />
                  <Feedback>{errors.zip}</Feedback>
                </FormControl>
                <FormControl isRequired isInvalid={errors.city && touched.city}>
                  <Label>Stadt</Label>
                  <Field as={Input} name="city" placeholder="Stadt" />
                  <Feedback>{errors.city}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    (errors.owner && touched.owner) ||
                    (errors.ownerName && touched.ownerName)
                  }
                  as="fieldset"
                >
                  <Label as="legend">Eigentümer</Label>
                  <RadioGroup value={values.owner}>
                    <Flex flexDir="column">
                      <Field as={Radio} name="owner" value="yes">
                        Ja
                      </Field>
                      <Flex gap={2}>
                        <Field
                          as={Radio}
                          name="owner"
                          value="no"
                          aria-label="Nein"
                        />
                        <Field
                          as={Input}
                          name="ownerName"
                          placeholder="Name eingeben"
                          disabled={values.owner !== 'no'}
                        />
                      </Flex>
                    </Flex>
                  </RadioGroup>
                  <Feedback>{errors.owner || errors.ownerName}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.privateProperty && touched.privateProperty}
                  as="fieldset"
                >
                  <Label as="legend">Privateigentum</Label>
                  <RadioGroup value={values.privateProperty}>
                    <Flex flexDir="column">
                      <Field as={Radio} name="privateProperty" value="yes">
                        Ja
                      </Field>
                      <Field as={Radio} name="privateProperty" value="no">
                        Nein
                      </Field>
                    </Flex>
                  </RadioGroup>
                  <Feedback>{errors.privateProperty}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    (errors.propertyType && touched.propertyType) ||
                    (errors.propertyTypeOther && touched.propertyTypeOther)
                  }
                  as="fieldset"
                >
                  <Label as="legend">Immobilienart</Label>
                  <RadioGroup value={values.propertyType}>
                    <Flex flexDir="column">
                      <Field
                        as={Radio}
                        name="propertyType"
                        value="ein-oder-zweifamilienhaus"
                      >
                        Ein- oder Zweifamilienhaus
                      </Field>
                      <Field
                        as={Radio}
                        name="propertyType"
                        value="mehrfamilienhaus"
                      >
                        Mehrfamilienhaus
                      </Field>
                      <Field
                        as={Radio}
                        name="propertyType"
                        value="gewerbe-buero"
                      >
                        Gewerbe / Büro
                      </Field>
                      <Field
                        as={Radio}
                        name="propertyType"
                        value="freilandflaeche"
                      >
                        Freilandfläche
                      </Field>
                      <Flex gap={2}>
                        <Field as={Radio} name="propertyType" value="andere">
                          Andere
                        </Field>
                        <Field
                          as={Input}
                          name="propertyTypeOther"
                          placeholder="Immobilienart eingeben"
                          disabled={values.propertyType !== 'andere'}
                        />
                      </Flex>
                    </Flex>
                  </RadioGroup>
                  <Feedback>
                    {errors.propertyType || errors.propertyTypeOther}
                  </Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.area && touched.area}
                  as="fieldset"
                >
                  <Label as="legend">
                    Wie groß ist die Fläche bzw. die geplante Anlage?
                  </Label>
                  <RadioGroup value={values.area}>
                    <Flex flexDir="column">
                      <Field as={Radio} name="area" value="bis-30-m2">
                        Bis 30 m²
                      </Field>
                      <Field as={Radio} name="area" value="30-bis-100-m2">
                        30 bis 100 m²
                      </Field>
                      <Field as={Radio} name="area" value="101-bis-200-m2">
                        101 bis 200 m²
                      </Field>
                      <Field as={Radio} name="area" value="ueber-200-m2">
                        Über 200 m²
                      </Field>
                      <Field as={Radio} name="area" value="weiss-ich-nicht">
                        Weiß ich nicht
                      </Field>
                    </Flex>
                  </RadioGroup>
                  <Feedback>{errors.area}</Feedback>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.consent && touched.consent}
                  as="fieldset"
                >
                  <Label as="legend">
                    Bereitschaft zum Gespräch mit Solarberater
                  </Label>
                  <Flex flexDir="column">
                    <Field
                      as={Checkbox}
                      name="consent"
                      id="consent"
                      isChecked={values.consent}
                      value={values.consent}
                    >
                      Ja
                    </Field>
                    <Feedback>{errors.consent}</Feedback>
                  </Flex>
                </FormControl>
                <Button
                  type="submit"
                  onSubmit={handleSubmit}
                  isLoading={isSubmitting}
                >
                  Absenden
                </Button>
                <Text mt={4} fontStyle="italic">
                  Durch Absenden der Anfrage stimme ich dem Kontakt durch die
                  talocasa GmbH per Telefon und E-Mail zum Zweck der
                  Immobilienbewertung zu (Widerruf{' '}
                  <Link to="https://www.hausgold.de/kontakt/">hier</Link>{' '}
                  jederzeit möglich). HAUSGOLD finanziert diesen Service über
                  die Provision der Immobilienexperten. Informationen zur
                  Datenverarbeitung und Ihren Rechten finden Sie{' '}
                  <Link to="https://www.hausgold.de/datenschutz/">hier</Link>.
                </Text>
              </Form>
              <Box mt={6}>
                {status?.success && (
                  <Alert status="success">
                    <AlertIcon />
                    <AlertDescription>
                      <AlertTitle>Erfolgreich versendet</AlertTitle>
                      Danke für Ihre Nachricht. Wir melden uns in Kürze bei
                      Ihnen.
                    </AlertDescription>
                  </Alert>
                )}
                {status?.error && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>
                      <AlertTitle>Ein Fehler ist aufgetreten</AlertTitle>
                      Bitte versuchen Sie es später erneut oder senden Sie uns
                      eine Email an service@hausgold.de.
                    </AlertDescription>
                  </Alert>
                )}
              </Box>
            </>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default SolarBeratung;

export const pageQuery = graphql`
  query KontaktQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "solar-beratung" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
