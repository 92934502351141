import {
  Box,
  Button,
  HStack,
  Heading,
  Stack,
  Text,
} from '@hausgold/designsystem';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { TbCurrencyEuroOff } from 'react-icons/tb';

const ContentfulContentBreaker = ({ entry, source }) => {
  const { headline1, headline2, description, linkLabel, link, image } = entry;
  const btnColor = '#19DC9B';

  return (
    <Box shadow="sm" rounded="md" fontSize="lg" overflow="hidden">
      <Stack>
        <Box position="relative" h="auto">
          <GatsbyImage image={image.gatsbyImageData} alt={headline1} />
          <HStack
            cursor="default"
            px={4}
            py={2.5}
            rounded="full"
            left={2}
            top={2}
            position="absolute"
            bg="white"
            border="1px"
            borderColor={btnColor}
          >
            <TbCurrencyEuroOff color={btnColor} size={20} />
            <Text color={btnColor} m={0}>
              Kostenlos
            </Text>
          </HStack>
        </Box>
        <Stack align="center" spacing={0} p={{ base: 2, md: 3 }}>
          <Stack align="center" pb={4}>
            <Heading fontSize="2xl" p={0} textAlign="center">
              {headline1}
            </Heading>
            {headline2 && (
              <Heading fontSize="2xl" p={0} textAlign="center">
                {headline2}
              </Heading>
            )}
          </Stack>
          <Text fontSize="inherit" textAlign="center" mb={{ base: 2, md: 3 }}>
            {description}
          </Text>
          <Button
            size="sm"
            as="a"
            href={link || '#'}
            target="_blank"
            rel="noopener noreferrer"
            w="max"
            onClick={() => {
              if (source === 'article') {
                window?.analytics?.track('Contentbreaker_Click_Articles');
              }

              if (source === 'page') {
                window?.analytics?.track('Contentbreaker_Click_Pages');
              }
            }}
          >
            {linkLabel}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ContentfulContentBreaker;
