import {
  Box,
  Center,
  Heading,
  Image,
  useBreakpoint,
} from '@hausgold/designsystem';
import cn from 'classnames';
import React from 'react';
import { Container } from 'reactstrap';
import CoupleImage from '../images/apotheken-umschau-immobilienbesitzer.png';
import NavbarSimple from './NavbarSimple';

/*
 * HeaderFormwizardImage is used as a version of the header specifically for ApothekenUmschau - lp/verkauf
 * Customization: Heights, margins, position and a added CoupleImage right above heroImage.
 */
const HeaderFormwizardImage = ({
  heroImage,
  title,
  titleColor,
  hideGradientOverlay,
  subTitle,
  size,
  className,
  children,
  headerBrandingImage,
  phoneNumber,
}) => {
  const background = hideGradientOverlay
    ? `url(${heroImage})`
    : `linear-gradient( rgba(22,148,244,.5), rgba(22,148,244,.5) ), url(${heroImage})`;
  const isBiggerThanMD = useBreakpoint('md', '>');

  return (
    <header style={{ minHeight: '860px' }}>
      <div
        style={{
          backgroundImage: background,
          backgroundPosition: '50% 0px',
          height: '860px',
        }}
        className={cn(
          'header text-center d-flex justify-content-center align-items-center',
          className,
          { 'header-md': !children, 'header-lg': children }
        )}
      >
        <NavbarSimple
          phoneNumber={phoneNumber}
          headerBrandingImage={headerBrandingImage}
        />
      </div>

      {/* HeaderFormwizardImage always has children because of the FW left and Deutsche Bank Logo. */}
      {children && (
        <Container style={{ marginTop: isBiggerThanMD ? '-765px' : '-785px' }}>
          <Box height="100%">
            <Center>
              {title && (
                <Heading
                  as={size === 'lg' ? 'h1' : undefined}
                  color={titleColor === 'White' ? 'white' : 'gray.600'}
                  textAlign="center"
                  wordBreak="break-word"
                  // Styles of class .display-2 as those styles are overwritten by DS.
                  fontSize={{ base: 'calc(1.425rem + 2.1vw)', xl: '3rem' }}
                  fontWeight="bold"
                  lineHeight={1.2}
                  // Class .display-2 is still present as it influences styles of following <small/>.
                  className="text-shadow display-2"
                >
                  {title}
                  <br />
                  <small className="font-weight-semibold d-block mt-4">
                    {subTitle}
                  </small>
                </Heading>
              )}
            </Center>

            {/* CoupleImage is displayed to the right of the formwizard for desktop view */}
            <Box position="absolute" right="15%" top="260px">
              <Image
                display={{ base: 'none', lg: 'block' }}
                alt="Immobilienbesitzer"
                src={CoupleImage}
                height="600px"
                justifyContent="center"
                alignItems="center"
              />
            </Box>
          </Box>
          {children}
        </Container>
      )}
    </header>
  );
};

export default HeaderFormwizardImage;
