import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import SchemaOrg from './SchemaOrg';

const SEO = ({
  entity,
  title,
  type,
  description,
  image,
  canonical,
  indexable,
  breadcrumbs,
  faqItems,
}) => {
  const { pathname } = useLocation();
  // eslint-disable-next-line no-use-before-define
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
    globalIndexable,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    canonical: canonical || `${siteUrl}${pathname}`,
    index:
      !indexable || !globalIndexable
        ? 'noindex'
        : 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
  };

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <html lang="de" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="robots" content={seo.index} />
        {seo.index === 'all' && <link rel="canonical" href={seo.canonical} />}
        {seo.url && <meta property="og:url" content={seo.url} />}
        {type === 'article' && <meta property="og:type" content="article" />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>

      <SchemaOrg
        entityType={type}
        siteUrl={siteUrl}
        seo={seo}
        entity={entity}
        breadcrumbs={breadcrumbs}
        faqItems={faqItems}
      />
    </>
  );
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
        globalIndexable: indexable
      }
    }
  }
`;

export default SEO;
