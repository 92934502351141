module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/@pittica/gatsby-plugin-trustpilot-widget/gatsby-browser.js'),
      options: {"plugins":[],"username":"hausgold.de","template":"53aa8807dec7e10d38f59f32","business":"5d08a845e18cd100018a0b17","height":"83px","language":"en","culture":"US","theme":"light","width":"100%"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
