import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Link from '../components/Link';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';
import ContentBlock from '../components/ContentBlock';
import Person from '../components/Person';

const Team = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');
  const personen = get(data, 'allContentfulPerson.edges');
  const site = get(data, 'site');
  const intro = page.contentBlocks.filter(
    (block) => block.title === 'Intro'
  )[0];
  const years = new Date().getFullYear() - 2014;

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container className="mt-6">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/ueber-uns">Über Uns</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Team</BreadcrumbItem>
        </Breadcrumb>
      </Container>

      <ContentBlock block={intro} narrow />

      <Container>
        <h2 className="text-center">Unser Team</h2>
        <Row className="mt-6">
          {personen.map(({ node }) => (
            <Person
              key={node.id}
              image={node.image}
              firstName={node.firstName}
              lastName={node.lastName}
              title={node.title}
            />
          ))}
        </Row>
      </Container>
      <Container className="mb-5">
        <h3 className="text-center pt-6 pt-md-7">
          Wir sind sehr stolz auf das, was wir gemeinsam mit unseren Kunden
          erreicht haben.
        </h3>
        <Row>
          <Col xs="12" md="4" className="text-center">
            <h3>
              100 <span className="text-muted h4Z">%</span>
            </h3>
            <p>Regionale Abdeckung in Deutschland</p>
          </Col>
          <Col xs="12" md="4" className="text-center">
            <h3>
              31 <span className="text-muted h4">Mrd.</span>
            </h3>
            <p>Gesamtwert der Immobilien in unserem System</p>
          </Col>
          <Col xs="12" md="4" className="text-center">
            <h3>
              {years} <span className="text-muted h4">Jahre</span>
            </h3>
            <p>Erfolgreich am Markt</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Team;

export const pageQuery = graphql`
  query TeamQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: "team" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
      contentBlocks {
        title
        id
        entries {
          ... on ContentfulTextWithImage {
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 500)
              title
            }
            internal {
              type
            }
            imageAlignment
            template
            title
          }
        }
      }
    }

    allContentfulPerson(filter: { tag: { in: "employee" } }) {
      edges {
        node {
          image {
            gatsbyImageData(
              layout: FIXED
              width: 250
              height: 250
              cropFocus: FACES
              resizingBehavior: FILL
            )
            title
          }
          id
          firstName
          lastName
          department
          title
        }
      }
    }
  }
`;
