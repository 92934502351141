import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import Logo from './Logo';

const NavbarSimple = ({
  headerBrandingImage,
  phoneNumber = '040 - 22 61 61 40',
}) => (
  <Navbar light expand="lg" fixed="top" className="px-0 text-left scrolled">
    <Container fluid className="justify-content-between">
      <NavbarBrand
        href="/"
        className="d-none d-md-block mr-md-0 py-0 px-xl-3 px-md-6 active"
      >
        <Logo width="150" className="logo" />
      </NavbarBrand>
      <NavbarBrand
        href="/"
        className="d-block d-md-none mr-md-6 py-0 px-3 px-md-6 active"
      >
        <svg
          className="signet"
          width="30"
          height="29"
          viewBox="0 0 19 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="a"
            d="M13.701 13.622c-2.137 1.694-2.715 2.093-4.73 3.223h10.78s-.924-3.187-2.303-6.846c-1.2 1.312-1.881 2.146-3.747 3.623z"
            fill="#f46364"
          />
          <path
            d="M.98 17.404S5.53.01 10 .01c1.732 0 3.63 3.089 5.243 6.665-3.195 3.938-9.005 8.425-12.706 10.728H.981z"
            fill="#F46364"
          />
        </svg>
      </NavbarBrand>

      {headerBrandingImage && (
        <img alt="Logo" src={headerBrandingImage} height="25px" />
      )}

      <a
        className="text-dark mr-4 pr-md-0 font-weight-semibold"
        href={`tel:${phoneNumber}`}
      >
        <span className="mr-3 mr-md-2 d-lg-inline-block d-none">
          Experten Hotline
        </span>
        <svg
          className="mr-md-2"
          style={{ width: '24px', height: '24px' }}
          viewBox="0 0 24 24"
        >
          <path
            fill="#3f3e4b"
            d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
          />
        </svg>

        <span className="d-none d-md-inline-block pl-md-2">{phoneNumber}</span>
      </a>
    </Container>
  </Navbar>
);

export default NavbarSimple;
