import React from 'react';
import Formwizard from './Formwizard';

const ContentfulFormwizard = ({ entry, location }) => (
  <Formwizard
    position="header"
    location={location}
    url={entry.url || 'https://formwizard.hausgold.de/formular/content'}
  />
);

export default ContentfulFormwizard;
