import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import cn from 'classnames';
import { Heading } from '@hausgold/designsystem';
import { getSrc } from 'gatsby-plugin-image';
import NavbarSimple from './NavbarSimple';

// HeaderSimple is used as a version of the header only for landingpages
const HeaderSimple = ({
  heroImage,
  title,
  titleColor,
  subTitle,
  size,
  className,
  children,
}) => {
  const heroSrc = getSrc(heroImage);

  return (
    <header>
      <div
        style={{
          backgroundImage: `url(${heroSrc})`,
        }}
        className={cn(
          'header text-center d-flex justify-content-center align-items-center',
          className,
          { 'header-md': !children, 'header-lg': children }
        )}
      >
        <NavbarSimple />
        {!children && (
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs="11" lg="8">
                {title && (
                  <h1 className="display-2 text-break text-center text-white text-shadow">
                    {title}
                    <br />
                    <small className="font-weight-semibold d-block mt-4">
                      {subTitle}
                    </small>
                  </h1>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>

      {children && (
        <Container className="wizardheader-margin">
          <Row>
            <Col>
              {title && (
                <Heading
                  as={size === 'lg' ? 'h1' : undefined}
                  color={titleColor === 'White' ? 'white' : 'gray.600'}
                  textAlign="center"
                  wordBreak="break-word"
                  // Styles of class .display-2 as those styles are overwritten by DS.
                  fontSize={{ base: 'calc(1.425rem + 2.1vw)', xl: '3rem' }}
                  fontWeight="bold"
                  lineHeight={1.2}
                  // Class .display-2 is still present as it influences styles of following <small/>.
                  className="text-shadow display-2"
                >
                  {title}
                  <br />
                  <small className="font-weight-semibold d-block mt-4">
                    {subTitle}
                  </small>
                </Heading>
              )}
            </Col>
          </Row>
          {children}
        </Container>
      )}
    </header>
  );
};

export default HeaderSimple;
