import React from 'react';
import { graphql } from 'gatsby';
import * as Yup from 'yup';
import get from 'lodash/get';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
} from 'reactstrap';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import SEO from '../../components/SEO';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Link from '../../components/Link';

const AnmeldungBaufinanzierung = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const heroImage = get(page, 'heroImage');

  const validationSchema = Yup.object().shape({
    company: Yup.string().required('Bitte geben Sie Ihren Firmennamen an.'),
    vorname: Yup.string().required('Bitte geben Sie Ihren Vornamen an.'),
    name: Yup.string().required('Bitte geben Sie Ihren Namen an.'),
    strasse: Yup.string().required(
      'Bitte geben Sie Ihre Straße und Hausnummer an.'
    ),
    ort: Yup.string().required('Bitte geben Sie Ihre Postleitzahl mit Ort an.'),
    email: Yup.string()
      .email('Bitte geben Sie eine richtige E-Mail-Adresse an.')
      .required('Bitte geben Sie Ihre E-Mail-Adresse an.'),
    phone: Yup.string().required('Bitte geben Sie Ihre Telefonnumer an.'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Sie müssen die Vereinbarung akzeptieren.'
    ),
  });

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md={{ size: 8 }} className="mt-6 mb-5">
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
            <Formik
              initialValues={{
                company: '',
                vorname: '',
                name: '',
                strasse: '',
                ort: '',
                email: '',
                phone: '',
                acceptTerms: false,
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm, setStatus }) => {
                axios
                  .post(
                    'https://hooks.zapier.com/hooks/catch/2548295/b3dj96p/',
                    JSON.stringify(values),
                    {
                      transformRequest: (response, headers) => {
                        // eslint-disable-next-line no-param-reassign
                        delete headers.common['Content-Type'];
                        return response;
                      },
                    }
                  )
                  .then(() => {
                    resetForm();
                    setStatus({ success: true });
                    // ResetForm()
                  })
                  .catch(() => {
                    setStatus({ success: false });
                  });
              }}
            >
              {({ errors, touched, isSubmitting, status }) => (
                <Form tag={FormikForm}>
                  {status && status.success && (
                    <Alert color="success">Danke für Ihre Nachricht.</Alert>
                  )}
                  {status && !status.success && (
                    <Alert color="danger">
                      Leider ist ein Fehler aufgetreten. Bitte senden Sie für
                      Ihre Anfrage eine E-Mail an
                      <a href="mailto:accountmanagement@hausgold.de">
                        accountmanagement@hausgold.de
                      </a>{' '}
                      .
                    </Alert>
                  )}
                  <FormGroup>
                    <Label for="company">Unternehmen (Firmierung)*:</Label>
                    <Input
                      invalid={!!errors.company && touched.company}
                      tag={Field}
                      type="text"
                      name="company"
                      id="company"
                    />
                    <FormFeedback>{errors.company}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="vorname">Vorname*:</Label>
                    <Input
                      invalid={!!errors.vorname && touched.vorname}
                      tag={Field}
                      type="text"
                      name="vorname"
                      id="vorname"
                    />
                    <FormFeedback>{errors.vorname}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">Name*:</Label>
                    <Input
                      invalid={!!errors.name && touched.name}
                      tag={Field}
                      type="text"
                      name="name"
                      id="name"
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="strasse">Straße und Hausnummer*:</Label>
                    <Input
                      invalid={!!errors.strasse && touched.strasse}
                      tag={Field}
                      type="text"
                      name="strasse"
                      id="strasse"
                    />
                    <FormFeedback>{errors.strasse}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="ort">Postleitzahl und Ort*:</Label>
                    <Input
                      invalid={!!errors.ort && touched.ort}
                      tag={Field}
                      type="text"
                      name="ort"
                      id="ort"
                    />
                    <FormFeedback>{errors.ort}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">E-Mail-Adresse*:</Label>
                    <Input
                      invalid={!!errors.email && touched.email}
                      tag={Field}
                      type="email"
                      name="email"
                      id="email"
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">Telefonnummer*:</Label>
                    <Input
                      invalid={!!errors.phone && touched.phone}
                      tag={Field}
                      type="text"
                      name="phone"
                      id="phone"
                    />
                    <FormFeedback>{errors.phone}</FormFeedback>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      invalid={!!errors.acceptTerms && touched.acceptTerms}
                      tag={Field}
                      type="checkbox"
                      name="acceptTerms"
                      id="acceptTerms"
                    />
                    <Label check>
                      Ich habe die{' '}
                      <Link to="https://ctf-assets.hausgold.de/5qea3umoe8gl/2noZvxu2rougcnxPyVaZyh/026ef0e3b18c4ee0e19e321aeef60a9d/20210616_HAUSGOLD_Bonusprogramm_Rahmenvereinbarung_inkl_Anlage.pdf">
                        Vereinbarung
                      </Link>{' '}
                      gelesen und stimme dieser zu.
                    </Label>
                    <ErrorMessage
                      name="acceptTerms"
                      component="div"
                      className="invalid-feedback"
                    />
                  </FormGroup>

                  <FormGroup check row>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        className="mt-5"
                      >
                        Bestätigung
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              )}
            </Formik>
            <p className="mt-5">
              Es gelten unsere{' '}
              <Link to="/datenschutz/"> Datenschutzbestimmungen</Link>.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default AnmeldungBaufinanzierung;

export const pageQuery = graphql`
  query AnmeldungBaufinanzierungQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: "anmeldung-baufinanzierung" }) {
      id
      title
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
